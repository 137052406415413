import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FiltroCobrancaDTO } from '@model/filtro/filtro-cobranca-dto.model';
import { ListaCobrancaDTO } from '@model/lista-cobranca-dto.model';
import { ListaMensagensDTO } from '@model/lista-mensagens-dto.model';
import { PageResponse } from '@model/page-response.model';
import moment from 'moment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CobrancaEndpointService {
  constructor(private client: HttpClient) { }

  emitirCobranca(cobrancaData): Observable<any> {
    const url = '/cobranca/emitir';
    return this.client.post(url, cobrancaData);
  }

  reportarProblema(cobrancaData): Observable<any> {
    const url = '/cobranca/reportar-problema';
    return this.client.post(url, cobrancaData);
  }

  editarCobranca(cobrancaData): Observable<any> {
    const url = '/cobranca/atualizar';
    return this.client.post(url, cobrancaData);
  }

  // /cobranca/apagar/{idCobranca}?notificarCliente=true/false
  excluirCobranca(
    cobrancaId,
    notificarCliente: boolean,
    dms: boolean
  ): Observable<any> {
    const url =
      '/cobranca/apagar/' +
      cobrancaId +
      '?notificarCliente=' +
      notificarCliente +
      '&dms=' +
      dms; // colocar mais um parametro aqui
    return this.client.delete(url);
  }

  getMensagensByIdCobranca(arg?: {
    id?: number;
    order?: string;
    page?: number;
    q?: string;
    size?: number;
  }): Observable<PageResponse<ListaMensagensDTO>> {
    arg = arg || {};
    let params = new HttpParams();

    params = params.append('id', `${arg.id}`);
    params = params.append('page', `${arg.page}`);

    if (arg.order) {
      params = params.append('order', `${arg.order}`);
    }

    if (arg.q) {
      params = params.append('q', `${arg.q}`);
    }
    if (arg.size) {
      params = params.append('size', `${arg.size}`);
    }
    const url = '/cobranca/mensagens-enviadas';
    return this.client.get(url, { params });
  }

  getCobrancaById(idCobranca): Observable<any> {
    const url = '/cobranca/informacoes/' + idCobranca;
    return this.client.get(url);
  }

  getLastroById(idCobranca, page, size): Observable<any> {
    let params = new HttpParams();
    params = params.append('page', `${page}`);
    if (size) {
      params = params.append('size', `${size}`);
    }
    const url = `/cobranca/lastros/${idCobranca}`;
    return this.client.get(url, { params });
  }

  getExportar(filtro): Observable<any> {
    let params = new HttpParams();
    params = params.append('formato', `${filtro.formato}`);
    if (filtro.query) {
      params = params.append('q', `${filtro.query}`);
    }
    if (filtro.order) {
      params = params.append('order', `${filtro.order}`);
    }
    if (filtro.cartao && filtro.cartao.show) {
      params = params.append('is-cartao', `${filtro.cartao.choice}`);
    }
    if (filtro.pix && filtro.pix.show) {
      params = params.append('is-pix', `${filtro.pix.choice}`);
    }
    if (filtro.boleto && filtro.boleto.show) {
      params = params.append('is-boleto', `${filtro.boleto.choice}`);
    }
    if (filtro.excluidas && filtro.excluidas.show) {
      params = params.append('is-excluida', `${filtro.excluidas.choice}`);
    }
    if (filtro.vencidas && filtro.vencidas.show) {
      params = params.append('is-vencida', `${filtro.vencidas.choice}`);
    }
    if (filtro.pagas && filtro.pagas.show) {
      params = params.append('is-paga', `${filtro.pagas.choice}`);
    }
    if (filtro.repasse && filtro.repasse.show) {
      params = params.append('is-repasse', `${filtro.repasse.choice}`);
    }
    if (filtro.data) {
      if (filtro.data.emissao && filtro.data.emissao.show) {
        if (filtro.data.emissao.de) {
          params = params.append('data-emissao-maior-igual', `${moment(filtro.data.emissao.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.emissao.ate) {
          params = params.append('data-emissao-menor-igual', `${moment(filtro.data.emissao.ate).format('DDMMYYYY')}`);
        }
      }
      if (filtro.data.vencimento && filtro.data.vencimento.show) {
        if (filtro.data.vencimento.de) {
          params = params.append('data-vencimento-maior-igual', `${moment(filtro.data.vencimento.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.vencimento.ate) {
          params = params.append('data-vencimento-menor-igual', `${moment(filtro.data.vencimento.ate).format('DDMMYYYY')}`);
        }
      }
      if (filtro.data.pagamento && filtro.data.pagamento.show) {
        if (filtro.data.pagamento.de) {
          params = params.append('data-pagamento-maior-igual', `${moment(filtro.data.pagamento.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.pagamento.ate) {
          params = params.append('data-pagamento-menor-igual', `${moment(filtro.data.pagamento.ate).format('DDMMYYYY')}`);
        }
      }
    }
    if (filtro.tipo) {
      params = params.append('tipo', `${filtro.tipo}`);
    }

    if (filtro.importacaoCnab && filtro.importacaoCnab.show) {
      params = params.append('is-importacaoCnab', `${filtro.importacaoCnab.choice}`);
    }

    return this.client.get('/cobranca/exportar', { params });
  }

  getAllCobrancasAvulsas(): Observable<any> {
    const url = '/cobranca/lista-avulsas';
    return this.client.get(url);
  }

  getAllCobrancasAssinaturas(): Observable<any> {
    const url = '/cobranca/lista-assinaturas';
    return this.client.get(url);
  }

  getAllCobrancasParceladas(): Observable<any> {
    const url = '/cobranca/lista-parceladas';
    return this.client.get(url);
  }

  getAllCobrancas(filtro?: FiltroCobrancaDTO): Observable<PageResponse<any>> {
    filtro = filtro || {};
    let params = new HttpParams();
    if (filtro.query) {
      params = params.append('q', `${filtro.query}`);
    }
    if (filtro.cartao && filtro.cartao.show) {
      params = params.append('is-cartao', `${filtro.cartao.choice}`);
    }
    if (filtro.pix && filtro.pix.show) {
      params = params.append('is-pix', `${filtro.pix.choice}`);
    }
    if (filtro.boleto && filtro.boleto.show) {
      params = params.append('is-boleto', `${filtro.boleto.choice}`);
    }
    if (filtro.excluidas && filtro.excluidas.show) {
      params = params.append('is-excluida', `${filtro.excluidas.choice}`);
    }
    if (filtro.vencidas && filtro.vencidas.show) {
      params = params.append('is-vencida', `${filtro.vencidas.choice}`);
    }
    if (filtro.pagas && filtro.pagas.show) {
      params = params.append('is-paga', `${filtro.pagas.choice}`);
    }
    if (filtro.repasse && filtro.repasse.show) {
      params = params.append('is-repasse', `${filtro.repasse.choice}`);
    }
    if (filtro.data) {
      if (filtro.data.emissao && filtro.data.emissao.show) {
        if (filtro.data.emissao.de) {
          params = params.append('data-emissao-maior-igual', `${moment(filtro.data.emissao.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.emissao.ate) {
          params = params.append('data-emissao-menor-igual', `${moment(filtro.data.emissao.ate).format('DDMMYYYY')}`);
        }
      }
      if (filtro.data.vencimento && filtro.data.vencimento.show) {
        if (filtro.data.vencimento.de) {
          params = params.append('data-vencimento-maior-igual', `${moment(filtro.data.vencimento.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.vencimento.ate) {
          params = params.append('data-vencimento-menor-igual', `${moment(filtro.data.vencimento.ate).format('DDMMYYYY')}`);
        }
      }
      if (filtro.data.pagamento && filtro.data.pagamento.show) {
        if (filtro.data.pagamento.de) {
          params = params.append('data-pagamento-maior-igual', `${moment(filtro.data.pagamento.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.pagamento.ate) {
          params = params.append('data-pagamento-menor-igual', `${moment(filtro.data.pagamento.ate).format('DDMMYYYY')}`);
        }
      }
    }
    if (filtro.banco000 && filtro.banco000.show) {
      params = params.append('is-conta-digitals', `${filtro.banco000.choice}`);
    }
    if (filtro.tipo) {
      params = params.append('tipo', `${filtro.tipo}`);
    }
    if (filtro.order) {
      params = params.append('order', `${filtro.order}`);
    }
    if (filtro.page >= 0) {
      params = params.append('page', `${filtro.page}`);
    }
    if (filtro.size >= 0) {
      params = params.append('size', `${filtro.size}`);
    }

    if (filtro.importacaoCnab && filtro.importacaoCnab.show) {
      params = params.append('is-importacaoCnab', `${filtro.importacaoCnab.choice}`);
    }

    if (filtro.seuNumero && filtro.seuNumero.show) {
      params = params.append('seuNumero', `${filtro.seuNumero.choice}`);
    }
    if (filtro.nossoNumero && filtro.nossoNumero.show) {
      params = params.append('nossoNumero', `${filtro.nossoNumero.choice}`);
    }

    const url = '/cobranca/lista';
    return this.client.get(url, { params });
  }

  getAllCobrancasArquivadas(): Observable<any> {
    const url = '/cobranca/lista-arquivadas';
    return this.client.get(url);
  }

  filtroCobrancas(data): Observable<any> {
    const url = '/cobranca/filtrar';
    return this.client.post(url, data);
  }

  visualizarCobranca(cobrancaId, empresaId): Observable<any> {
    let params = new HttpParams();
    if (empresaId) {
      params = params.append('empresaId', `${empresaId}`);
    }
    const url = '/cobranca/' + cobrancaId;
    return this.client.get(url, { params });
  }

  visualizarComprovante(cobrancaId): Observable<any> {
    const url = '/cobranca/ver-comprovante/' + cobrancaId;
    return this.client.get(url);
  }

  consultarTitulo(cobrancaId): Observable<any> {
    const url = '/cobranca/consultar-titulo?id=' + cobrancaId;
    return this.client.get(url);
  }

  visualizarFatura(cobrancaId): Observable<any> {
    const url = '/cobranca/ver-fatura/' + cobrancaId;
    return this.client.get(url);
  }

  // /cobranca/notificar-fatura/{idPublico}
  notificarFatura(idPublico): Observable<any> {
    const url = '/cobranca/notificar-fatura/' + idPublico;
    return this.client.get(url);
  }

  baixarBoleto(cobrancaId): Observable<any> {
    return this.client.get('/cobranca/baixar-boleto', {
      params: new HttpParams().append('idCobranca', cobrancaId)
    });
  }

  baixarCarne(seuNumero): Observable<any> {
    const url = '/cobranca/gerar-carne?seuNumero=' + seuNumero;
    return this.client.get(url);
  }

  reenviarSmsEmail(cobrancaId): Observable<any> {
    const url = '/cobranca/reenviar-email-sms?id=' + cobrancaId;
    return this.client.get(url);
  }

  liquidarCobrancaManualmente(liquidarData): Observable<any> {
    const url = '/cobranca/liquidar-cobranca-manualmente';
    return this.client.post(url, liquidarData);
  }

  consultarProtestoCompleto(cobrancaId): Observable<any> {
    const url = '/protesto/consultar-protesto-completo/' + cobrancaId;
    return this.client.get(url);
  }

  protestarCobranca(protestarData): Observable<any> {
    const url = '/protesto/emitir';
    return this.client.post(url, protestarData);
  }

  operacaoProtesto(operacao, protestoData){
    if(operacao === 'remover'){
      return this.removerProtesto(protestoData);
    }else if(operacao === 'desistir'){
      return this.desistirProtesto(protestoData);
    }else if (operacao === 'cancelar'){
      return this.cancelarProtesto(protestoData);
    }
  }

  removerProtesto(protestoData): Observable<any> {
    const url = '/protesto/remover';
    return this.client.put(url, protestoData);
  }

  desistirProtesto(protestoData): Observable<any> {
    const url = '/protesto/desistir';
    return this.client.put(url, protestoData);
  }

  cancelarProtesto(protestoData): Observable<any> {
    const url = '/protesto/cancelar';
    return this.client.put(url, protestoData);
  }

  addNovasParcelas(cobrancaId, qtdParcelas, novaData?): Observable<any> {
    const url =
      '/cobranca/adicionar-novas-parcelas';
    let params = new HttpParams();

    params = params.append('id', `${cobrancaId}`);
    params = params.append('num', `${qtdParcelas}`);

    if (novaData) {
      params = params.append('novaData', `${moment(novaData).format('DDMMYYYY')}`);
    }

    return this.client.get(url, { params });
  }

  buscarDataBaixa(dataVencimento: Date, quantidadeDiasBaixa: number): Observable<any> {
    let params = new HttpParams();
    if (dataVencimento) {
      params = params.append('dataVencimento', `${moment(dataVencimento).format('DDMMYYYY')}`);
    }
    if (quantidadeDiasBaixa) {
      params = params.append('quantidadeDiasBaixa', `${quantidadeDiasBaixa}`);
    }
    const url = '/cobranca/busca-data-baixa';
    return this.client.get(url, { params });
  }

  listar(filtro: FiltroCobrancaDTO): Observable<PageResponse<ListaCobrancaDTO>> {
    filtro = filtro || {};
    let params = new HttpParams();
    if (filtro.query) {
      params = params.append('q', `${filtro.query}`);
    }
    if (filtro.cartao && filtro.cartao.show) {
      params = params.append('is-cartao', `${filtro.cartao.choice}`);
    }
    if (filtro.pix && filtro.pix.show) {
      params = params.append('is-pix', `${filtro.pix.choice}`);
    }
    if (filtro.boleto && filtro.boleto.show) {
      params = params.append('is-boleto', `${filtro.boleto.choice}`);
    }
    if (filtro.excluidas && filtro.excluidas.show) {
      params = params.append('is-excluida', `${filtro.excluidas.choice}`);
    }
    if (filtro.vencidas && filtro.vencidas.show) {
      params = params.append('is-vencida', `${filtro.vencidas.choice}`);
    }
    if (filtro.pagas && filtro.pagas.show) {
      params = params.append('is-paga', `${filtro.pagas.choice}`);
    }
    if (filtro.repasse && filtro.repasse.show) {
      params = params.append('is-repasse', `${filtro.repasse.choice}`);
    }
    if (filtro.data) {
      if (filtro.data.emissao && filtro.data.emissao.show) {
        if (filtro.data.emissao.de) {
          params = params.append('data-emissao-maior-igual', `${moment(filtro.data.emissao.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.emissao.ate) {
          params = params.append('data-emissao-menor-igual', `${moment(filtro.data.emissao.ate).format('DDMMYYYY')}`);
        }
      }
      if (filtro.data.vencimento && filtro.data.vencimento.show) {
        if (filtro.data.vencimento.de) {
          params = params.append('data-vencimento-maior-igual', `${moment(filtro.data.vencimento.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.vencimento.ate) {
          params = params.append('data-vencimento-menor-igual', `${moment(filtro.data.vencimento.ate).format('DDMMYYYY')}`);
        }
      }
      if (filtro.data.pagamento && filtro.data.pagamento.show) {
        if (filtro.data.pagamento.de) {
          params = params.append('data-pagamento-maior-igual', `${moment(filtro.data.pagamento.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.pagamento.ate) {
          params = params.append('data-pagamento-menor-igual', `${moment(filtro.data.pagamento.ate).format('DDMMYYYY')}`);
        }
      }
    }
    if (filtro.tipo) {
      params = params.append('tipo', `${filtro.tipo}`);
    }
    if (filtro.order) {
      params = params.append('order', `${filtro.order}`);
    }
    if (filtro.page >= 0) {
      params = params.append('page', `${filtro.page}`);
    }
    if (filtro.size >= 0) {
      params = params.append('size', `${filtro.size}`);
    }

    if (filtro.importacaoCnab && filtro.importacaoCnab.show) {
      params = params.append('is-importacaoCnab', `${filtro.importacaoCnab.choice}`);
    }

    if (filtro.seuNumero && filtro.seuNumero.show) {
      params = params.append('seuNumero', `${filtro.seuNumero.choice}`);
    }
    if (filtro.nossoNumero && filtro.nossoNumero.show) {
      params = params.append('nossoNumero', `${filtro.nossoNumero.choice}`);
    }

    return this.client.get('/cobranca/listar', { params });
  }

  calcucalculaValorSomaTotal(filtro: FiltroCobrancaDTO): any {
    filtro = filtro || {};
    let params = new HttpParams();
    if (filtro.query) {
      params = params.append('q', `${filtro.query}`);
    }
    if (filtro.cartao && filtro.cartao.show) {
      params = params.append('is-cartao', `${filtro.cartao.choice}`);
    }
    if (filtro.pix && filtro.pix.show) {
      params = params.append('is-pix', `${filtro.pix.choice}`);
    }
    if (filtro.boleto && filtro.boleto.show) {
      params = params.append('is-boleto', `${filtro.boleto.choice}`);
    }
    if (filtro.excluidas && filtro.excluidas.show) {
      params = params.append('is-excluida', `${filtro.excluidas.choice}`);
    }
    if (filtro.vencidas && filtro.vencidas.show) {
      params = params.append('is-vencida', `${filtro.vencidas.choice}`);
    }
    if (filtro.pagas && filtro.pagas.show) {
      params = params.append('is-paga', `${filtro.pagas.choice}`);
    }
    if (filtro.repasse && filtro.repasse.show) {
      params = params.append('is-repasse', `${filtro.repasse.choice}`);
    }
    if (filtro.data) {
      if (filtro.data.emissao && filtro.data.emissao.show) {
        if (filtro.data.emissao.de) {
          params = params.append('data-emissao-maior-igual', `${moment(filtro.data.emissao.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.emissao.ate) {
          params = params.append('data-emissao-menor-igual', `${moment(filtro.data.emissao.ate).format('DDMMYYYY')}`);
        }
      }
      if (filtro.data.vencimento && filtro.data.vencimento.show) {
        if (filtro.data.vencimento.de) {
          params = params.append('data-vencimento-maior-igual', `${moment(filtro.data.vencimento.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.vencimento.ate) {
          params = params.append('data-vencimento-menor-igual', `${moment(filtro.data.vencimento.ate).format('DDMMYYYY')}`);
        }
      }
      if (filtro.data.pagamento && filtro.data.pagamento.show) {
        if (filtro.data.pagamento.de) {
          params = params.append('data-pagamento-maior-igual', `${moment(filtro.data.pagamento.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.pagamento.ate) {
          params = params.append('data-pagamento-menor-igual', `${moment(filtro.data.pagamento.ate).format('DDMMYYYY')}`);
        }
      }
    }
    if (filtro.tipo) {
      params = params.append('tipo', `${filtro.tipo}`);
    }

    if (filtro.importacaoCnab && filtro.importacaoCnab.show) {
      params = params.append('is-importacaoCnab', `${filtro.importacaoCnab.choice}`);
    }

    return this.client.get('/cobranca/calcular-soma-total', { params });
  }

  listarTodas(filtro: FiltroCobrancaDTO): Observable<PageResponse<ListaCobrancaDTO>> {
    filtro = filtro || {};
    let params = new HttpParams();
    if (filtro.query) {
      params = params.append('q', `${filtro.query}`);
    }
    if (filtro.cartao && filtro.cartao.show) {
      params = params.append('is-cartao', `${filtro.cartao.choice}`);
    }
    if (filtro.pix && filtro.pix.show) {
      params = params.append('is-pix', `${filtro.pix.choice}`);
    }
    if (filtro.boleto && filtro.boleto.show) {
      params = params.append('is-boleto', `${filtro.boleto.choice}`);
    }
    if (filtro.excluidas && filtro.excluidas.show) {
      params = params.append('is-excluida', `${filtro.excluidas.choice}`);
    }
    if (filtro.vencidas && filtro.vencidas.show) {
      params = params.append('is-vencida', `${filtro.vencidas.choice}`);
    }
    if (filtro.pagas && filtro.pagas.show) {
      params = params.append('is-paga', `${filtro.pagas.choice}`);
    }
    if (filtro.registrada && filtro.registrada.show) {
      params = params.append('is-registrada', `${filtro.registrada.choice}`);
    }
    if (filtro.dinheiro && filtro.dinheiro.show) {
      params = params.append('is-dinheiro', `${filtro.dinheiro.choice}`);
    }
    if (filtro.data) {
      if (filtro.data.emissao && filtro.data.emissao.show) {
        if (filtro.data.emissao.de) {
          params = params.append('data-emissao-maior-igual', `${moment(filtro.data.emissao.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.emissao.ate) {
          params = params.append('data-emissao-menor-igual', `${moment(filtro.data.emissao.ate).format('DDMMYYYY')}`);
        }
      }
      if (filtro.data.vencimento && filtro.data.vencimento.show) {
        if (filtro.data.vencimento.de) {
          params = params.append('data-vencimento-maior-igual', `${moment(filtro.data.vencimento.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.vencimento.ate) {
          params = params.append('data-vencimento-menor-igual', `${moment(filtro.data.vencimento.ate).format('DDMMYYYY')}`);
        }
      }
      if (filtro.data.pagamento && filtro.data.pagamento.show) {
        if (filtro.data.pagamento.de) {
          params = params.append('data-pagamento-maior-igual', `${moment(filtro.data.pagamento.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.pagamento.ate) {
          params = params.append('data-pagamento-menor-igual', `${moment(filtro.data.pagamento.ate).format('DDMMYYYY')}`);
        }
      }
      if (filtro.data.desativacao && filtro.data.desativacao.show) {
        if (filtro.data.desativacao.de) {
          params = params.append('data-desativacao-maior-igual', `${moment(filtro.data.desativacao.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.desativacao.ate) {
          params = params.append('data-desativacao-menor-igual', `${moment(filtro.data.desativacao.ate).format('DDMMYYYY')}`);
        }
      }
    }
    if (filtro.tipo) {
      params = params.append('tipo', `${filtro.tipo}`);
    }
    if (filtro.order) {
      params = params.append('order', `${filtro.order}`);
    }
    if (filtro.page >= 0) {
      params = params.append('page', `${filtro.page}`);
    }
    if (filtro.size >= 0) {
      params = params.append('size', `${filtro.size}`);
    }

    if (filtro.importacaoCnab && filtro.importacaoCnab.show) {
      params = params.append('is-importacaoCnab', `${filtro.importacaoCnab.choice}`);
    }

    return this.client.get('/cobranca/listar-todas', { params });
  }

  consultarBoletoCip(cobrancaId): Observable<any> {
    const url = `/cobranca/consultar-boleto-cip/${cobrancaId}`
    return this.client.get(url);
  }

  listarPorEmpresa(filtro: FiltroCobrancaDTO): Observable<PageResponse<ListaCobrancaDTO>> {
    filtro = filtro || {};
    let params = new HttpParams();
    if (filtro.query) {
      params = params.append('q', `${filtro.query}`);
    }
    if (filtro.cartao && filtro.cartao.show) {
      params = params.append('is-cartao', `${filtro.cartao.choice}`);
    }
    if (filtro.pix && filtro.pix.show) {
      params = params.append('is-pix', `${filtro.pix.choice}`);
    }
    if (filtro.boleto && filtro.boleto.show) {
      params = params.append('is-boleto', `${filtro.boleto.choice}`);
    }
    if (filtro.excluidas && filtro.excluidas.show) {
      params = params.append('is-excluida', `${filtro.excluidas.choice}`);
    }
    if (filtro.vencidas && filtro.vencidas.show) {
      params = params.append('is-vencida', `${filtro.vencidas.choice}`);
    }
    if (filtro.pagas && filtro.pagas.show) {
      params = params.append('is-paga', `${filtro.pagas.choice}`);
    }
    if (filtro.repasse && filtro.repasse.show) {
      params = params.append('is-repasse', `${filtro.repasse.choice}`);
    }
    if (filtro.data) {
      if (filtro.data.emissao && filtro.data.emissao.show) {
        if (filtro.data.emissao.de) {
          params = params.append('data-emissao-maior-igual', `${moment(filtro.data.emissao.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.emissao.ate) {
          params = params.append('data-emissao-menor-igual', `${moment(filtro.data.emissao.ate).format('DDMMYYYY')}`);
        }
      }
      if (filtro.data.vencimento && filtro.data.vencimento.show) {
        if (filtro.data.vencimento.de) {
          params = params.append('data-vencimento-maior-igual', `${moment(filtro.data.vencimento.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.vencimento.ate) {
          params = params.append('data-vencimento-menor-igual', `${moment(filtro.data.vencimento.ate).format('DDMMYYYY')}`);
        }
      }
      if (filtro.data.pagamento && filtro.data.pagamento.show) {
        if (filtro.data.pagamento.de) {
          params = params.append('data-pagamento-maior-igual', `${moment(filtro.data.pagamento.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.pagamento.ate) {
          params = params.append('data-pagamento-menor-igual', `${moment(filtro.data.pagamento.ate).format('DDMMYYYY')}`);
        }
      }
    }
    if (filtro.tipo) {
      params = params.append('tipo', `${filtro.tipo}`);
    }
    if (filtro.order) {
      params = params.append('order', `${filtro.order}`);
    }
    if (filtro.page >= 0) {
      params = params.append('page', `${filtro.page}`);
    }
    if (filtro.size >= 0) {
      params = params.append('size', `${filtro.size}`);
    }
    if (filtro.idEmpresa) {
      params = params.append('idEmpresa', `${filtro.idEmpresa}`);
    }

    if (filtro.importacaoCnab && filtro.importacaoCnab.show) {
      params = params.append('is-importacaoCnab', `${filtro.importacaoCnab.choice}`);
    }

    return this.client.get('/cobranca/listar-por-empresa', { params });
  }

  contarCobrancas(empresaId): Observable<any> {
    const url = `/cobranca/contar-cobrancas-aguardando/${empresaId}`
    return this.client.get(url);
  }

  contatoPossuiCobrancas(contatoId): Observable<any> {
    const url = `/cobranca/contato-possui-cobrancas?contatoId=${contatoId}`
    return this.client.get(url);
  }

  darBaixaTodasCobranca(idEmpresa): Observable<any> {
    const url = `/cobranca/baixa-todas-cobrancas?idEmpresa=${idEmpresa}`
    return this.client.post(url, { idEmpresa });
  }
}