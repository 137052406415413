import {
  Environment,
  environment as defaultEnvironment,
} from './environment.default';

export const environment: Environment = {
  ...defaultEnvironment,
  production: false,
  url: {
    ...defaultEnvironment.url,
    backend: 'https://api.hml.sosbolsobank.com.br'
  }
};
