<div class="container card-plano">

  <div class="col-12 col-md-12">
    <h3><strong>Poxa, quer encerrar sua Conta?</strong></h3>


  </div>
  <div class="container card-body">
    <div class="col-12 col-md-12">
      <p>
        Veja tudo o que podemos fazer por você:
      </p>
    </div>

    <div class="col-12 col-md-12">
      <i class="fas fa-wallet alinhar"></i>
      Conta digital.
    </div>
    <div class="col-12 col-md-12">
      <i class="fas fa-right-left alinhar"></i>
      Transferências ilimitadas.
    </div>
    <div class="col-12 col-md-12">
      <i class="fas fa-regular fa-qrcode alinhar"></i>
      Boletos com QR Code ilimitados.
    </div>

    <div class="col-12 col-md-12">
      E muito mais!
    </div>

    <div class="col-12 col-md-12">
      <strong>Essa ação encerra sua conta de forma irreversível.</strong><br />
      Sendo assim, você vai perder o acesso e não será
      possível gerar cobranças ou usar outras funcionalidades.
    </div>

    <form [formGroup]="form">

      <div class="col-12  input-size">
        <mat-slide-toggle formControlName="ciente" style="margin-bottom: 13px; ">

          <span style="font-size: 11px;">Estou ciente das condições <br> e concordo com os termos de contrato</span>

        </mat-slide-toggle>
      </div>

      <div class="row">
        <div class="col-12 col-md-6" style="padding-left: 8%">

          <button type="submit" class="btn btn-block float-right"
            style="background-color: #fff; color: #000; border: 1px solid black; font-size: 15px" mat-raised-button
            [disabled]="form.invalid" (click)="solicitaEncerramentoConta()">
            Encerrar
          </button>


        </div>

        <div class="col-12 col-md-1" style="margin-left: -5%">

          <button class="float-right" style="background-color: #fff; border: none; width: 1%;" (click)="showInfo()">
            <i class="fas fa-circle-info"></i>
          </button>


        </div>

      </div>



      <div class="col-12 col-md-12">
        <p style="font-size: 12px;"><strong>Dúvidas?</strong> Entre em contato
          com <u><strong><a (click)="gerenteModal()" href="javascript:void(0);">
                seu gerente de contas</a></strong></u>.</p>
      </div>


    </form>




  </div>


</div>