<div>
  <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
    EDITAR PUBLICIDADE
    <i (click)="fecha()" class="material-icons btn-fechar" id="btn-close">close</i>
    <span id="fechar" (click)="fecha()">FECHAR</span>
  </h2>
  <div mat-dialog-content class="mat-dialog-content-responsive">
    <div class="mudaCor input-size row" id="modal-total">
      <div class="card-body">
        <div class="col-24 col-md-24" style=" display: flex;flex-direction: column;align-items: center;">
          <div style="display:flex; justify-content: center;">
            <a href="{{ data?.href }}" target="_blank">
              <img style="max-width: 100%; width: 744px;" src="data:image/png;base64,{{ data?.base64 }}">
            </a>
          </div>
          <mat-form-field appearance="outline" style="width: 20vw; padding-top: 2vh;">
            <mat-label>Link</mat-label>
            <input matInput type="text" placeholder="Link" value="{{ data?.href }}" (change)="atualizaHref($event)">
          </mat-form-field>
        </div>
        <div class="buttons-ajusta">
          <button class="btn btn-default next" id="color-remove" mat-raised-button (click)="deletarPubli()"
            style="width: 10vw">
            Remover
          </button>
          <button class="btn btn-default next" id="color-btn" mat-raised-button (click)="atualizarPubli()"
            style="width: 10vw">
            Atualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>