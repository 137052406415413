import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DocumentoDTO } from '@project/src/app/model/sistema/documento-dto.model';
import { NovoDocumentoComponent } from '../novo-documento/novo-documento.component';

@Component({
  selector: 'app-listar-documento',
  templateUrl: './listar-documento.component.html',
  styleUrls: ['./listar-documento.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListarDocumentoComponent implements OnInit, OnDestroy {

  @Input()
  public form: FormArray;

  @Input()
  public data: DocumentoDTO[]
  filtro: any;

  @Input()
  public modelo: string;

  @Output()
  public update: EventEmitter<any> = new EventEmitter();

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _fb: FormBuilder,
    private _dialog: MatDialog
  ) { }

  compara(a, b) {
    if (a.documento < b.documento) {
      return -1;
    }
    if (a.documento > b.documento) {
      return 1;
    }
    return 0;
  }

  ngOnInit() {
    this.form.clear();

    let data = this.data;
    data.sort(this.compara);
    for (let documento of data) {
      this.addDocumentos(documento);
    }
  }

  ngOnDestroy() { }

  atualizar(element) {
    if (element.query && element.query != '') {
      let data = this.data.filter(documento => documento.documento.toLowerCase().includes(element.query.toLowerCase()))
      this.form.clear();
      for (let documento of data) {
        this.addDocumentos(documento);
      }
    } else if (!element.query || element.query == '') {
      this.form.clear();
      for (let documento of this.data) {
        this.addDocumentos(documento);
      }
    }
    if (element.atualizar) {
      this.update.emit();
    }
    this._changeDetectorRef.detectChanges();
  }

  addDocumentos(documento: DocumentoDTO) {
    this.form.push(
      this._fb.group({
        documento: this._fb.control(documento.documento || '', [Validators.required]),
        id: this._fb.control(documento.id || '', [Validators.required]),
        ativo: this._fb.control(documento.ativo || false, [Validators.required]),
        tipo: this._fb.control(documento.tipo),
        moderacao: this._fb.control(documento.moderacao, [Validators.required]),
        formato: this._fb.control(documento.formato),
        obrigatorio: this._fb.control(documento.obrigatorio || false, [Validators.required]),
        fundoId: this._fb.control(documento.fundoId || null)
      })
    );
  }

  isAtivo(documento: FormGroup) {
    return documento.get('ativo').value;
  }

  onEdit(documento?: DocumentoDTO) {
    const dialog = this._dialog.open(NovoDocumentoComponent, {
      width: "90vw",
      height: "90vh",
      panelClass: "modal-config",
      data: documento
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        if (result.atualizar === true) {
          this.update.emit();
        }
      }
    });
  }
}
