import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { UsuarioEndpointService } from '@service/usuario-endpoint.service';
import { AutenticacaoEndpointService } from '@service/autenticacao-endpoint.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { WebsocketNotificationsService } from '@project/src/app/service/websocket-notifications.service';

@Component({
  selector: 'app-trocar-senha',
  templateUrl: './trocar-senha.component.html',
  styleUrls: ['./trocar-senha.component.less'],
})
export class TrocarSenhaComponent implements OnInit, OnDestroy {
  form: FormGroup;
  processando: boolean = false;
  public ehVisivelAtual: string = 'password';
  public ehVisivelNova: string = 'password';
  public ehVisivelConfirma: string = 'password';
  public novaSenhaValida: boolean = false;
  public confirmaSenhaValida: boolean = false;
  public login: boolean = false;

  public verificadorSenha = {
    letraMaiscula: 0,
    letraMinuscula: 0,
    numero: 0,
    quantidade: 0,
  };

  constructor(
    private usuario: UsuarioEndpointService,
    private autenticacao: AutenticacaoEndpointService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TrocarSenhaComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private webSocketNotificationsService: WebsocketNotificationsService
  ) {}

  ngOnInit() {
    this.form = this.fb.group(
      {
        senhaAntiga: new FormControl('', [Validators.required]),
        novaSenha: new FormControl('', [Validators.required]),
        confirmaSenha: new FormControl('', [Validators.required]),
      },
      {
        validator: this.checkIfMatchingPasswords('novaSenha', 'confirmaSenha'),
      }
    );
    this.login = this.data?.login;
  }

  ngOnDestroy() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  checkIfMatchingPasswords(senha: string, confirmaSenha: string) {
    return (group: FormGroup) => {
      const senhaInput = group.controls[senha],
        confirmaSenhaInput = group.controls[confirmaSenha];
      if (
        senhaInput.value !== confirmaSenhaInput.value &&
        confirmaSenhaInput.value !== '' &&
        confirmaSenhaInput.value !== null
      ) {
        return confirmaSenhaInput.setErrors({ notEquivalent: true });
      } else {
        return confirmaSenhaInput.setErrors(null);
      }
    };
  }

  changeVisibilityAtual() {
    if (this.ehVisivelAtual === 'password') {
      this.ehVisivelAtual = 'text';
    } else {
      this.ehVisivelAtual = 'password';
    }
  }

  changeVisibilityConfirma() {
    if (this.ehVisivelConfirma === 'password') {
      this.ehVisivelConfirma = 'text';
    } else {
      this.ehVisivelConfirma = 'password';
    }
  }

  changeVisibilityNova() {
    if (this.ehVisivelNova === 'password') {
      this.ehVisivelNova = 'text';
    } else {
      this.ehVisivelNova = 'password';
    }
  }

  getErrorMessage(field) {
    return this.form.get(field).hasError('required')
      ? 'Campo requerido'
      : this.form.get(field).hasError('minlength')
      ? 'Campo inválido'
      : this.form.get(field).hasError('notEquivalent')
      ? 'Senhas não coincidem'
      : '';
  }

  isValid(field) {
    let tam = this.hasSize(field);
    let num = this.hasNumber(field);
    let min = this.hasLowerCase(field);
    let caps = this.hasUpperCase(field);
    this.changeDetectorRef.detectChanges();

    if (tam === false || num === false || min === false || caps === false) {
      if (field === 'novaSenha' && this.novaSenhaValida) {
        this.novaSenhaValida = false;
      }
      if (field === 'confirmaSenha' && this.confirmaSenhaValida) {
        this.confirmaSenhaValida = false;
      }
      return false;
    } else {
      if (field === 'novaSenha' && !this.novaSenhaValida) {
        this.novaSenhaValida = true;
      }
      if (field === 'confirmaSenha' && !this.confirmaSenhaValida) {
        this.confirmaSenhaValida = true;
      }
      return this.form.get(field).valid;
    }
  }

  hasSize(field) {
    if (field == 'novaSenha') {
      if (this.form.get(field).value.length >= 8) {
        this.verificadorSenha.quantidade = 1;
      } else {
        this.verificadorSenha.quantidade = 0;
      }
    }
    return this.form.get(field).value.length >= 8;
  }

  hasNumber(field) {
    if (field == 'novaSenha') {
      if (/\d/.test(this.form.get(field).value) == true) {
        this.verificadorSenha.numero = 1;
      } else {
        this.verificadorSenha.numero = 0;
      }
      return /\d/.test(this.form.get(field).value);
    }
  }

  hasLowerCase(field) {
    if (field == 'novaSenha') {
      if (/[a-z]/.test(this.form.get(field).value) == true) {
        this.verificadorSenha.letraMinuscula = 1;
      } else {
        this.verificadorSenha.letraMinuscula = 0;
      }
    }
    return /[a-z]/.test(this.form.get(field).value);
  }

  hasUpperCase(field) {
    if (field == 'novaSenha') {
      if (/[A-Z]/.test(this.form.get(field).value) == true) {
        this.verificadorSenha.letraMaiscula = 1;
      } else {
        this.verificadorSenha.letraMaiscula = 0;
      }
    }
    return /[A-Z]/.test(this.form.get(field).value);
  }

  enviar() {
    this.processando = true;
    this.usuario
      .alterarSenha(
        this.form.get('senhaAntiga').value,
        this.form.get('novaSenha').value
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.processando = false;
          swal
            .fire({
              title: 'Senha alterada com sucesso',
              text: 'Faça o login novamente com sua nova senha.',
              icon: 'success',
              confirmButtonText: 'OK',
            })
            .then((result) => {
              this.dialog.closeAll();
              this.logout();
            });
        },
        (error) => {
          this.processando = false;

          swal.fire({
            title: 'Atenção',
            text: error.error,
            icon: 'warning',
            confirmButtonText: 'OK',
          });
        }
      );
  }

  logout() {
    this.webSocketNotificationsService.disconnectWebSocketConnection();

    this.autenticacao
      .sair()
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.router.navigateByUrl('/acesso');
        },
        (error) => {
          console.error(error);
        }
      );
  }
}
