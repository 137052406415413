import { Component, OnInit, ViewChild } from '@angular/core';
import { PublicidadeEndpointService } from '../../service/publicidade-endpoint.service';
import { AdicionaImagemComponent } from './adiciona-imagem/adiciona-imagem.component';
import { EditarImagemComponent } from './editar-imagem/editar-imagem.component';
import { NgbCarousel, NgbCarouselConfig, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-publicidade',
  templateUrl: './publicidade.component.html',
  styleUrls: ['./publicidade.component.less'],
})
export class PublicidadeComponent implements OnInit {

  publicidade = null;
  listaPublicidade = [];
  processando: boolean = false;
  selectedPub: any;
  activeSlideId: any;

  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;


  constructor(
    public dialogRef: MatDialogRef<PublicidadeComponent>,
    public publicidadeService: PublicidadeEndpointService,
    private dialog: MatDialog,
    private config: NgbCarouselConfig
  ) {
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
    config.interval = 10000;
  }

  ngOnInit() {
    this.listarPublicidade();
  }

  listarPublicidade() {
    this.processando = true;
    this.publicidadeService.listarPublicidade().subscribe((res) => {
      this.listaPublicidade = res;
      this.processando = false;
      this.selectedPub = this.listaPublicidade[0];
    }, error => {
      console.error(error);
    });
  }

  abreCropper() {
    this.dialog.open(AdicionaImagemComponent, {
      backdropClass: "modal-medio",
    })
      .afterClosed()
      .subscribe((res) => {
        setTimeout(() => {
          this.listarPublicidade();
        }, 2000); // Ajuste o valor para o tempo que você considerar adequado.
      })
  }

  editaImagem() {
    this.dialog.open(EditarImagemComponent, {
      backdropClass: "modal-medio",
      data: this.selectedPub
    }).afterClosed()
      .subscribe((res) => {
        this.listarPublicidade();
      }, error => {
        console.error(error);
      })
  }

  slideChanged(event: NgbSlideEvent) {
    const currentSlideId = event.current;
    const activeSlideIndex = this.listaPublicidade.findIndex(item => item.id === currentSlideId);

    if (activeSlideIndex !== -1) {
      this.selectedPub = this.listaPublicidade[activeSlideIndex];
    }
  }

  fecha() {
    this.dialogRef.close();
  }

}
