import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PublicidadeEndpointService } from '@project/src/app/service/publicidade-endpoint.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.less']
})
export class ImageCropperComponent implements OnInit {
  name = 'Angular';

  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropperMinWidth = 744;
  cropperMinHeight = 200;

  @Output() base64 = new EventEmitter<string>();
  @Output() nameFile = new EventEmitter<string>();
  @Output() type = new EventEmitter<string>();

  constructor(
    public dialogRef: MatDialogRef<ImageCropperComponent>,
    public formBuilder: FormBuilder,
    public publicidadeService: PublicidadeEndpointService,
  ) { }

  ngOnInit() { }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.nameFile.emit(event.target.files[0].name);
    this.type.emit(event.target.files[0].type);
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.base64.emit(this.croppedImage);
  }

}
