import { StatusProtesto } from '../../../../model/cobranca/status-protesto.model';
import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog
} from '@angular/material/dialog';
import {
  FormControl,
  FormBuilder,
  FormGroup
} from '@angular/forms';
import swal from 'sweetalert2';
import { CobrancaEndpointService } from '@service/cobranca-endpoint.service';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-protestar-cobranca',
  templateUrl: './protestar-cobranca.component.html',
  styleUrls: ['./protestar-cobranca.component.less']
})
export class ProtestarCobrancaComponent{
  processando = false;
  form: FormGroup;
  env = environment;
  cobranca: any = null;

  constructor(
    public dialogRef: MatDialogRef<ProtestarCobrancaComponent>,
    private cobrancaService: CobrancaEndpointService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.cobranca = this.data.cobranca;
    this.form = this.formBuilder.group({
      idCobranca: new FormControl(this.data.cobranca.idCobranca),
      notificarCliente: new FormControl(true),
      justificativa: new FormControl('')
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  fecha(): void {
    this.dialogRef.close();
  }

  enviarProtesto() {
    this.processando = true;

    const data = {
      cobrancaId: this.form.get('idCobranca').value,
      notificarCliente: this.form.get('notificarCliente').value
    };

    this.cobrancaService.protestarCobranca(data).subscribe(
      response => {
        this.processando = false;
        swal
          .fire({
            title: 'Sucesso',
            text: response,
            icon: 'success',
            confirmButtonText: 'OK'
          })
          .then(() => {
            if (this.data) {
              this.dialogRef.close({ protestouCobranca: true });
            } else {
              this.dialogRef.close();
            }
          });
      },
      error => {
        this.processando = false;
        swal.fire({
          title: 'Erro',
          text: error.error,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    );
  }

  operacaoProtesto() {
    this.processando = true;

    const data = {
      cobrancaId: this.form.get('idCobranca').value,
      notificarCliente: this.form.get('notificarCliente').value,
      justificativa:  this.form.get('justificativa').value,
    };

    let operacao = undefined;
    if(this.cobranca.statusProtesto === StatusProtesto.COLETADO || this.cobranca.statusProtesto == StatusProtesto.GERADO){
      operacao = 'remover';
    }else if(this.cobranca.statusProtesto === StatusProtesto.CONFIRMADO){
      operacao = 'desistir';
    }else if (this.cobranca.statusProtesto === StatusProtesto.PROTESTADO){
      operacao = 'cancelar';
    }

    if(operacao){
      this.cobrancaService.operacaoProtesto(operacao, data).subscribe(
        response => {
          this.processando = false;
          swal
            .fire({
              title: 'Sucesso',
              text: response,
              icon: 'success',
              confirmButtonText: 'OK'
            })
            .then(() => {
              if (this.data) {
                this.dialogRef.close({ protestouCobranca: true });
              } else {
                this.dialogRef.close();
              }
            });
        },
        error => {
          this.processando = false;
          swal.fire({
            title: 'Erro',
            text: error.error,
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      );
    }
  }
}
