export interface Environment {
  production: boolean;
  nomeApp: string;
  plugaBank: boolean;
  codAgencia: string;
  codBanco: string;
  empresa: {
    descricao: string;
    jargao: string;
    nomeFantasia: string;
    razaoSocial: string;
    ispb: number;
    nomeAbrevidado: string;
    cnpj: string;
    nomeIF: string;
    cnpjIF: string;
    endereco: {
      tipoLogradouro: string;
      logradouro: string;
      numero: string;
      complemento: string;
      bairro: string;
      cidade: string;
      estado: string;
      pais: string;
      cep: string;
    };
    enderecoDTVM: {
      tipoLogradouro: string;
      logradouro: string;
      numero: string;
      complemento: string;
      bairro: string;
      cidade: string;
      estado: string;
      pais: string;
      cep: string;
    };
    email: {
      contato: string;
      suporte: string;
      comercial: string;
      ouvidoria: string;
    };
    telefone: {
      contato: string;
      suporte: string;
      comercial: string;
      ouvidoria: string;
    }
    gerente: {
      nome: string;
      cargo: string;
      telefone: string;
      email: string;
    };
    suporteHorarioAtendimento: string;
  };
  url: {
    appSite: string;
    lojaAppAndroid: string;
    lojaAppApple: string;
    webSite: string;
    backend: string;
    termosContratos: string,
    termoUso: string
    openBanking: string,
    planosPrecos: string,
    centralAjuda: string,
    blog: string,
    readme: string,
    envioOperacoes: string,
    ajudaAntecipacao: string,
    ajudaCobranca: string,
    ajudaContatos: string,
    ajudaPagamento: string,
    ajudaTransferencia: string
  };
  google: {
    captcha: string;
  };
  configCobranca: {
    cip: boolean,
    santander: boolean,
    cipBoletoPix: boolean,
    cipBoletoBancario: boolean,
    cipCartaoCredito: boolean,
  };
  modulos: {
    multiagencias: boolean;
    empresa: {
      multiEmpresas: boolean;
    };
    cobranca: {
      sidebar: boolean;
      avulsa: boolean;
      assinatura: boolean;
      parcelada: boolean;
      importacaoCnab: boolean;
      protesto: boolean;
    };
    notificacao: {
      sms: boolean;
      email: boolean;
    };
    pagamento: {
      boletoPix: boolean;
      boletoBancario: boolean;
      cartaoCredito: boolean;
      importacaoCnab: boolean;
    };
    desconto: {
      opcoesDesconto: boolean;
    };
    arquivo: {
      anexarArquivo: boolean;
    };
    fiscal: {
      notaFiscal: boolean;
    };
    pagamentoContas: {
      sidebar: boolean;
      tributos: boolean;
      concessionarias: boolean;
      boleto: boolean;
    };
    extrato: {
      sidebar: boolean;
      ofx: boolean;
      xls: boolean;
      pdf: boolean;
      csv: boolean;
      informeRendimentos: boolean;
      cartaCircularizacao: boolean;
    };
    transferencia: {
      sidebar: boolean;
      ted: boolean;
      interna: boolean;
      p2p: boolean;
      agendamento: boolean;
      sensibilizacao: boolean;
      sensibilizacaoPassado: boolean;
      lote: boolean;
      alcada: boolean;
    };
    cartaoCredito: {
      sidebar: boolean;
      prePago: boolean;
      saldoUnificado: boolean;
    };
    credito: {
      sidebar: boolean,
    };
    investidor: {
      sidebar: boolean;
      extratoInvestidor: boolean;
      listaFundos: boolean;
      investimentos: boolean;
    };
    antecipacao: {
      sidebar: boolean;
      boleto: boolean;
      faturaCartao: boolean;
      xmlRecebiveis: boolean;
      consignado: boolean;
    };
    convite: {
      indicarAmigos: boolean;
    };
    suporteContato: {
      termosContratos: boolean;
      planosPrecos: boolean;
      centralAjuda: boolean;
      relacionamento: boolean;
      blog: boolean;
      envioOperacoes: boolean;
    };
    contabilidade: {
      tesouraria: boolean;
    };
    tipoConta: {
      digitals: boolean;
      correnteDigital: boolean;
      vinculadaDigital: boolean;
      escrowDigital: boolean;
      bloqueioJudicial: boolean;
      salarioDigital: boolean;
      servicoDigital: boolean;
      investidorDigital: boolean;
      digital: boolean;
    };
    onboard: {
      pessoaFisica: boolean;
      pessoaJuridica: boolean;
    };
    operador: {
      operadores: boolean;
    };
    fraude: {
      antiFraude: boolean;
    };
    api: {
      api: boolean;
    };
    auditoria: {
      logs: boolean;
    };
    aumentoLimites: {
      cobranca: boolean;
      transferencia: boolean;
      antecipacao: boolean;
    };
    token: {
      email: boolean;
      sms: boolean
    };
    espacoPublicitario: {
      publicidadeCarrossel: boolean;
    };
    autenticacaoSecundaria: boolean;
    contato: {
      importacao: boolean;
    };
    usuarios: {
      assessor: boolean;
      assistente: boolean;
      dtvm: boolean;
      gerenteDeContas: boolean;
      gestora: boolean;
    };
    tesouraria: {
      movimentacoesPorConta: boolean;
    };
    pix: {
      sidebar: boolean;
      transferenciaPix: boolean;
      gerarQrCode: boolean;
    }
  };
}

export const environment: Environment = {
  production: false,
  nomeApp: 'SOSBOLSO',
  plugaBank: true,
  codAgencia: '0020',
  codBanco: '528',
  empresa: {
    nomeIF: 'Banco Reag',
    cnpjIF: '34.829.992/0001-86',
    cnpj: '42.335.769/0001-00',
    ispb: 34829992,
    descricao: 'Nossa especialidade hoje é encontrar o equilíbrio entre inovação, tecnologia e viabilidade econômica',
    email: {
      comercial: 'comercial@sosbolsobank.com.br',
      contato: 'contato@sosbolsobank.com.br',
      suporte: 'suporte@sosbolsobank.com.br',
      ouvidoria: 'ouvidoria@sosbolsobank.com.br',
    },
    telefone: {
      comercial: '+55 (11) 3504-6800',
      contato: '+55 (11) 3504-6800',
      suporte: '+55 (11) 3504-6800',
      ouvidoria: '+55 (11) 3504-6800',
    },
    endereco: {
      cep: '01.452-000',
      tipoLogradouro: 'Av.',
      logradouro: 'BRIG FARIA LIMA',
      numero: '2277',
      complemento: 'CONJ 1702 SALA 2',
      bairro: 'JARDIM PAULISTANO',
      cidade: 'São Paulo',
      estado: 'São Paulo',
      pais: 'Brasil'
    },
    enderecoDTVM: {
      cep: '01452-000',
      tipoLogradouro: 'Av.',
      logradouro: 'Brig. Faria Lima',
      numero: '2277',
      complemento: '17º andar cj.1702',
      bairro: 'Jardim Paulistano',
      cidade: 'São Paulo',
      estado: 'São Paulo',
      pais: 'Brasil'
    },
    gerente: {
      nome: 'Roberto Mac Lennan ',
      cargo: 'Gerente Contas',
      telefone: '(11) 3504-6800',
      email: 'contato@sosbolso.com.br',
    },
    suporteHorarioAtendimento: 'Segunda à Sexta - 9h às 18h (Exceto feriados)',
    jargao: 'SOSBOLSO - Agilidade no Recebimento',
    nomeFantasia: 'SOSBOLSO Gestão Financeira LTDA',
    razaoSocial: 'SOSBOLSO Gestão Financeira LTDA',
    nomeAbrevidado: 'SOSBOLSO',
  },
  url: {
    appSite: 'https://app.sosbolsobank.com.br',
    lojaAppAndroid: '' ,
    lojaAppApple: '' ,
    webSite: 'https://sosbolsobank.com.br',
    backend: 'http://localhost:8080',
    termosContratos: 'https://sosbolsobank.com.br/termos-contratos-e-politicas/',
    termoUso: 'https://sosbolsobank.com.br/termos-de-uso/',
    openBanking: '',
    planosPrecos: 'https://sosbolsobank.com.br/planos-e-precos/',
    centralAjuda: 'https://sosbolsobank.tomticket.com/kb',
    blog: 'https://sosbolsobank.com.br/blog/',
    readme: 'https://sosbolsobank.readme.io/',
    envioOperacoes: '',
    ajudaAntecipacao: 'https://sosbolsobank.tomticket.com/kb/antecipacao',
    ajudaCobranca: 'https://sosbolsobank.tomticket.com/kb/cobranca',
    ajudaContatos: 'https://sosbolsobank.tomticket.com/kb/-clientes/adicionar-editar-e-remover-clientes',
    ajudaPagamento: 'https://sosbolsobank.tomticket.com/kb/pagar-contas',
    ajudaTransferencia: 'https://sosbolsobank.tomticket.com/kb/-transferencias'
  },
  google: {
    captcha: '6LdLXfsnAAAAAJVr6xyPnYomZjBaz_GCrIMBZmjf'
  },
  configCobranca: {
    cip: true,
    santander: true,
    cipBoletoPix: false,
    cipBoletoBancario: false,
    cipCartaoCredito: false,
  },
  modulos: {
    multiagencias: false,
    empresa: {
      multiEmpresas: true
    },
    cobranca: {
      sidebar: true,
      avulsa: true,
      assinatura: true,
      parcelada: true,
      importacaoCnab: true,
      protesto: false,
    },
    notificacao: {
      sms: true,
      email: true
    },
    pagamento: {
      boletoPix: true,
      boletoBancario: true,
      cartaoCredito: true,
      importacaoCnab: true,
    },
    desconto: {
      opcoesDesconto: true
    },
    arquivo: {
      anexarArquivo: true,
    },
    fiscal: {
      notaFiscal: true,
    },
    pagamentoContas: {
      sidebar: true,
      tributos: true,
      concessionarias: true,
      boleto: true
    },
    extrato: {
      sidebar: true,
      ofx: true,
      xls: true,
      pdf: true,
      csv: true,
      informeRendimentos: true,
      cartaCircularizacao: true,
    },
    transferencia: {
      sidebar: true,
      ted: true,
      interna: true,
      p2p: true,
      agendamento: true,
      sensibilizacao: true,
      sensibilizacaoPassado: false,
      lote: true,
      alcada: true
    },
    cartaoCredito: {
      sidebar: false,
      prePago: true,
      saldoUnificado: true
    },
    credito: {
      sidebar: true,
    },
    investidor: {
      sidebar: false,
      extratoInvestidor: true,
      listaFundos: true,
      investimentos: true
    },
    antecipacao: {
      sidebar: true,
      boleto: true,
      faturaCartao: true,
      xmlRecebiveis: true,
      consignado: true
    },
    convite: {
      indicarAmigos: true,
    },
    suporteContato: {
      termosContratos: true,
      planosPrecos: true,
      centralAjuda: true,
      relacionamento: true,
      blog: true,
      envioOperacoes: false
    },
    contabilidade: {
      tesouraria: true,
    },
    tipoConta: {
      digitals: false,
      correnteDigital: true,
      vinculadaDigital: false,
      escrowDigital: true,
      bloqueioJudicial: false,
      salarioDigital: false,
      servicoDigital: false,
      investidorDigital: false,
      digital: true
    },
    onboard: {
      pessoaFisica: true,
      pessoaJuridica: true
    },
    operador: {
      operadores: true,
    },
    fraude: {
      antiFraude: true,
    },
    api: {
      api: true,
    },
    auditoria: {
      logs: true
    },
    aumentoLimites: {
      cobranca: true,
      transferencia: true,
      antecipacao: true
    },
    token: {
      email: true,
      sms: true
    },
    espacoPublicitario: {
      publicidadeCarrossel: true
    },
    autenticacaoSecundaria: false,
    contato: {
      importacao: false,
    },
    usuarios: {
      assessor: false,
      assistente: false,
      dtvm: false,
      gerenteDeContas: false,
      gestora: false,
    },
    tesouraria: {
      movimentacoesPorConta: false
    },
    pix: {
      sidebar: true,
      transferenciaPix: false,
      gerarQrCode: false
    }
  }
};
