<!-- Cabeçalho padrão para modais -->
<h2 mat-dialog-title class="titulo-modal m-0">
  <div class="row m-0 p-0">
    <div class="col-4 m-0 p-0"></div>
    <div class="d-flex justify-content-center col-4 m-0 p-0">
      <a [attr.href]="this.urlLogo" target="_blank">
        <img src="assets/img/icons/pluga-bank/logos/logo-colorido.png" class="logo" />
      </a>
    </div>
    <div class="col-4 m-0 p-0">
      <span id="fechar" (click)="fechar()">
        <i (click)="fechar()" class="material-icons btn-fechar">close</i>
        <span id="fechar" (click)="fechar()">FECHAR</span>
      </span>
    </div>
  </div>
</h2>

<ngx-invisible-recaptcha #captchaElem [siteKey]="captchaKey" badge="bottomleft" (ready)="setCaptchaPronto()"
  (success)="onSuccess($event)" (error)="onError($event)" [ngModel]="recaptcha" [ngModelOptions]="{ standalone: true }"
  [useGlobalDomain]="false">
</ngx-invisible-recaptcha>

<div mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor"
  *ngIf="!ehNewAuthenticator; else habilitarMFA">
  <div class="row" style="border-radius: 15px; border: 2px solid #fcba03; margin: 10px;">
    <div style="margin-top: 10px; margin-bottom: 10px; margin-left: 10px">
      <i class="fas fa-exclamation-circle" style="color: #fcba03; font-size: 40px"></i>
    </div>
    <div class="col-11" style="margin-top: 10px; margin-bottom: 10px">
      <h6>Seja bem vindo ao Internet Banking</h6>
      <span>
        Usuário e senha validados com sucesso, para prosseguir efetuar validação abaixo.
      </span>
    </div>
  </div>

  <div class="row" style="
      border-radius: 15px;
      border: solid 2px;
      border-color: gray;
      margin: 10px;
      margin-top: 15px;
    ">
    <form [formGroup]="form" (ngSubmit)="executarCaptcha()" class="w-100">
      <div style="padding: 10px 0 20px 0">
        <div class="row" style="margin: 10px">

          <div class="col-12 mb-3 d-flex flex-row" *ngIf="this.ehColaborador || this.ehOperador">
            <div class="col-6 input-size">
              <mat-form-field appearance="outline">
                <mat-label>Número do {{ this.ehColaborador ? "colaborador" : "operador" }}</mat-label>
                <input type="text" matInput formControlName="codigoAutenticacao" mask="000000" />
                <mat-icon matSuffix>lock</mat-icon>
                <mat-error>Campo inválido</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="col-12 mb-3" *ngIf="ehAgenciaConta">
            <div class="col-12 d-flex flex-row input-size">
              <mat-form-field appearance="outline" class="mr-3">
                <mat-label>Agência</mat-label>
                <input type="text" matInput formControlName="agencia" />
                <mat-icon matSuffix>lock</mat-icon>
                <mat-error>Campo inválido</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Número da Conta</mat-label>
                <input type="text" matInput formControlName="conta" />
                <mat-icon matSuffix>lock</mat-icon>
                <mat-error>Campo inválido</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="col-12" *ngIf="ehMultifator">
            <p class="texto-mfa">Digite o código gerado no seu aplicativo de autenticação:</p>
            <div class="col-6 input-size">
              <mat-form-field appearance="outline">
                <mat-label>Código de verificação</mat-label>
                <input type="text" matInput formControlName="codigoAuthenticator" />
                <mat-icon matSuffix>lock</mat-icon>
                <mat-error>Campo inválido</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-12 d-flex justify-content-center">
          <button type="submit" class="col-3 btn btn-success btn-block" [disabled]="!botaoAtivo" mat-raised-button>
            Acessar
          </button>
        </div>

        <div class="col-12">
          <mat-progress-bar [hidden]="!processando" mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #habilitarMFA>
  <div class="d-flex flex-column justify-content-center align-items-center input-size" [formGroup]="form">
    <h3 class="mt-0 titulo-principal">Habilitar autenticação de dois fatores</h3>
    <p class="texto-mfa center">
      Faça a leitura do QR Code abaixo com um aplicativo de autenticação, como o
      <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share"
        target="_blank">Google Authenticator</a>
      no seu telefone
    </p>
    <img *ngIf="qrcode" [src]="qrcode">
    <p class="texto-mfa center">Digite o código criado pelo aplicativo de autenticação:</p>
    <mat-form-field class="col-4" appearance="outline">
      <mat-label>Código de verificação</mat-label>
      <input matInput formControlName="codigoAuthenticator" mask="000000">
    </mat-form-field>
    <button [disabled]="form.get('codigoAuthenticator').value.length < 6" class="btn btn-default next"
      (click)="habilitarMfa()">Habilitar</button>
  </div>
</ng-template>