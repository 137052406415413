import { PermissionamentoEndpointService } from '@project/src/app/service/permissionamento.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate, Router, UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class PermissaoGuard implements CanActivate {
  constructor(private _roleService: PermissionamentoEndpointService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this._roleService.permite(route.data.role)) {
          return true;
    } else {
      this._router.navigate(['/principal']);
    }
  }
}
