<app-localizacao [usuarioLogado]="usuarioLogado" [tipo]="tipoLocalizacao"
  *ngIf="tipoLocalizacaoLiberado?.ativo"></app-localizacao>

<button class="btn-close mt-2 mr-1" (click)="fecha()">
  <i class="material-icons">close</i>
</button>

<h2 mat-dialog-title class="corPrimaria" style="font-weight: 600; text-align: center">Informações de usuário</h2>

<div mat-dialog-content class="mat-dialog-content-responsive .btn-menor">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Nome</mat-label>
          <input matInput formControlName="nome" required />
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Sobrenome</mat-label>
          <input matInput formControlName="sobrenome" required />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>{{form.get('numero').value.length>10?'Celular':'Telefone'}}</mat-label>
          <input matInput formControlName="numero" placeholder="{{form.get('numero').value.length>13?Celular:Telefone}}"
            (ngModelChange)="valueCel()" type="tel" [value]="numeroTelefone" required />
          <mat-error>{{ getErrorMessage(form, "numero") }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <button style="background-color:#1400c8" [disabled]="telefoneValido||!tamanhoValido"
          (click)="confirmaTelefone()" class="btn btn-default btn-block" mat-raised-button>
          Confirmar número de celular
        </button>
      </div>

    </div>
    <!-- coloquei aqui  -->
    <!-- pai politicamente exposta endereco complemento bairro cidade estado cep -->

    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Data de Nascimento</mat-label>
          <input matInput [matDatepicker]="picker1" formControlName="dataNascimento" placeholder="Data de Nascimento"
            [min]='dataMinima' [max]="dataDezoitoAnos" required />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <mat-error>{{ getErrorMessage(form, "dataNascimento") }}</mat-error>
        </mat-form-field>


      </div>
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Estado civil</mat-label>
          <mat-select formControlName="estadoCivil">
            <mat-option value="solteiro">Solteiro</mat-option>
            <mat-option value="casado">Casado</mat-option>
            <mat-option value="viuvo">Viúvo</mat-option>
            <mat-option value="separadoJudicialmente">Separado judicialmente</mat-option>
            <mat-option value="divorciado">Divorciado</mat-option>
          </mat-select>
          <mat-error>{{ getErrorMessage(form, "estadoCivil") }}</mat-error>
        </mat-form-field>

      </div>

      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>CPF</mat-label>
          <input matInput type="text" formControlName="cpf" mask="000.000.000-00" />
          <mat-error>{{ getErrorMessage(form, "cpf") }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>RG</mat-label>
          <input matInput type="text" formControlName="rg" />
          <mat-error>{{ getErrorMessage(form, "rg") }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Orgão emissor</mat-label>
          <input matInput type="text" formControlName="orgaoEmissor" />
          <mat-error>{{ getErrorMessage(form, "orgaoEmissor") }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Nome da mãe</mat-label>
          <input matInput type="text" formControlName="nomeMae" />
          <mat-error>{{ getErrorMessage(form, "nomeMae") }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Nome do pai</mat-label>
          <input matInput type="text" formControlName="nomePai" />
          <mat-error>{{ getErrorMessage(form, "nomePai") }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline">
          <input matInput type="text" hidden formControlName="pessoaPoliticamenteExposta" />
          <mat-slide-toggle formControlName="pessoaPoliticamenteExposta">Politicamente Exposta</mat-slide-toggle>
          <mat-error>{{ getErrorMessage(form, "pessoaPoliticamenteExposta") }}</mat-error>
          <i class="fas fa-info-circle info-icon"
            matTooltip="É uma pesssoa que desempenha ou desempenhou, no Brasil ou no exterior nos últimos 5 anos, funções públicas relevantes, ou teve familiares, representantes ou colaboradores próximos que desempenham ou desempenharam esse tipo de função. Para saber mais, veja a Circular BCB n.3.978/20."></i>
        </mat-form-field>
      </div>
      <div class="col-12" *ngIf="data?.endereco">
        <app-endereco [(form)]="endereco" [data]="data" [multiplosEnderecos]="false"></app-endereco>
      </div>
    </div>
    <div *ngIf="!perguntasCadastradas">
      <p class="col-12">Defina abaixo perguntas de segurança para proteger sua conta.</p>
      <div *ngFor="let perguntaSeguranca of formPerguntasSeguranca.controls, let i=index" [formGroup]="perguntaSeguranca" class="col-12 mt-4 d-flex flex-column" style="gap:0.8rem">
        <mat-form-field style="width: 98%;" appearance="outline">
          <mat-label>Pergunta de segurança {{i+1}}</mat-label>
          <mat-select formControlName="pergunta">
            <mat-option *ngFor="let pergunta of perguntasSeguranca; let i=index" [value]="i">{{pergunta}}</mat-option>
          </mat-select>
          <mat-error>{{ getErrorMessage(perguntaSeguranca, "pergunta") }}</mat-error>
        </mat-form-field>
        <mat-form-field style="width: 98%;" appearance="outline">
          <mat-label>Resposta {{i+1}}</mat-label>
          <input matInput type="text" formControlName="resposta">
          <mat-error>{{ getErrorMessage(perguntaSeguranca, "resposta") }}</mat-error>
        </mat-form-field>
        <div *ngIf="i > 0" style="position: absolute; right: 0.3rem; top: 0.5rem">
          <i class="fas fa-trash icones"
            title="Excluir"
            (click)="removePergunta(i)"
            style="color:red;"></i>
        </div>
      </div>
      <div *ngIf="formPerguntasSeguranca.length < 3" class="add d-flex justify-content-around">
        <span (click)="addPergunta()" style="padding-left: 20px;">+ adicionar nova pergunta</span>
        <span class="col-8"></span>
      </div>
      <div class="col-12 d-flex justify-content-center">
        <button [disabled]="formPerguntasSeguranca.invalid" (click)="cadastrarPerguntas()" style="background-color:#4FA9A9;" class="col-12 col-md-4 btn btn-default btn-block" mat-raised-button>
          Cadastrar perguntas
        </button>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions class="row espacamento">
  <div class="col-12 col-md-4">
    <button (click)="fecha()" type="button" class="btn btn-default btn-block" style="color: #fff;" mat-raised-button>
      Cancelar
    </button>
  </div>

  <div class="col-12 col-md-4">
    <button (click)="openDialog()" style="background-color:#1400c8" class="btn btn-default btn-block" mat-raised-button>
      Alterar Senha
    </button>
  </div>

  <div class="col-12 col-md-4">
    <button [disabled]="!form.dirty || form.invalid" (click)="salvar()" class="btn btn-block"
      [ngClass]="form.dirty === false ||  form.invalid ? 'botaoDesabilitado' : 'botaoHabilitado'" mat-raised-button>
      Atualizar
    </button>
  </div>
</div>