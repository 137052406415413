import { RepresentanteEmpresaDTO } from './../../../../../model/pessoa/representante-empresa-dto.model';
import { ArquivoEmpresaDTO } from './../../../../../model/pessoa/arquivo-empresa-dto.model';
import { Component, Inject, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { untilDestroyed } from "ngx-take-until-destroy";
import { ModalConfirmacaoComponent } from "src/app/components/modal/modal-confirmacao/modal-confirmacao.component";
import { IPessoaService, PessoaServiceBuilder } from "src/app/service/pessoa-endpoint.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { DomSanitizer } from '@angular/platform-browser';
import { ExtratoEndpointService } from '@project/src/app/service/extrato-endpoint.service';
import { RootEndpointService } from '@project/src/app/service/root-endpoint.service';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';
import { HomeEndpointService } from '@project/src/app/service/home-endpoint.service';
import { TemporizadorService } from '@project/src/app/service/temporizador.service';
import { Subscription } from 'rxjs';
import { ModeracaoArquivo } from '@project/src/app/model/enum.model';
declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: "app-arquivos",
  templateUrl: "./arquivos.component.html",
  styleUrls: ["./arquivos.component.less"],
})
export class ArquivosComponent implements OnInit, OnDestroy {
  processando: boolean = false;
  carregando: boolean = false;
  public form: FormGroup;
  tipoConta: string = 'pf';
  env = environment;
  private pessoaService: IPessoaService;
  arquivoEmpresa: any = [];
  lastPage: any;
  scrollBottom: boolean;
  arquivoRetorno: ArquivoEmpresaDTO[];
  representanteRetorno: RepresentanteEmpresaDTO[];
  assinaturaEletronica: boolean = false;
  assinaturaSemiEletronica: boolean = false;
  pdf;
  empresaLogada: any;
  signers: {
    id: number;
    nome: string;
    dataAssinatura: Date;
    status: string;
    tipo: string;
    email: string;
    telefone: string;
  }[] = [];
  arquivoId: number;
  urlTermos = null;
  notificacoesEnviadas: boolean = false;
  clicou: boolean = false;

  public bloqueiaEnviarAte: Date;
  public tempoReenvio: number = 2 //minutos

  private EnviadoEm: Date;
  public subscription: Subscription;

  public podeReenviar: boolean = true;

  lidoTermo: boolean = false;
  naoImpedidoMercado: boolean = false
  pessoaVinculadaInstituicao: boolean = false;
  emprestimo: boolean = false;

  linkArquivo: any;
  @ViewChild('elementstepper1', { static: false }) elementstepper1;
  @ViewChild('elementstepper2', { static: false }) elementstepper2;

  constructor(
    public dialogRef: MatDialogRef<ArquivosComponent>,
    private injector: Injector,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    private fb: FormBuilder,
    private extratoService: ExtratoEndpointService,
    private homeService: HomeEndpointService,
    private autenticacao: AutenticacaoEndpointService,
    private temporizadorService: TemporizadorService,
    private _rootService: RootEndpointService
  ) {
    this.empresaLogada = this.autenticacao.regraEmpresa.empresa;
    // dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.getURL();
    this.form = this.fb.group({
      arquivos: this.fb.array([]),
      representantes: this.fb.array([]),
    });
    this.form.addControl('tipoDocumento', new FormControl('', null));
    this.form.addControl('tipoEmpresa', new FormControl('', this.tipoConta === 'pj' ? [Validators.required] : null));
    let tipoCadastro = 'empresa';
    this.pessoaService = PessoaServiceBuilder.getInstance(
      this.injector,
      tipoCadastro
    );
    if (this.dataDialog.documento.formato === 'assEletronica') {
      this.assinaturaEletronica = true;
      this.deleteFile(this.dataDialog.documento);

      this.form.addControl('formStepper1.nomeCompleto', new FormControl('', null));
      this.form.addControl('formStepper1.documento', new FormControl('', null));
      this.form.addControl('formStepper1.dataNascimento', new FormControl('', null));

      this.form.addControl('formStepper2.token', new FormControl('', null));
    } else if (this.dataDialog.documento.formato === 'assSemiEletronica') {
      this.assinaturaSemiEletronica = true;
      this.showBoleto();
    }

    if (this.empresaLogada.representantes.length > 0) {
      if (this.dataDialog.item.pessoa.pj)
        this.addRepresentantes();
    }

    if (this.dataDialog.documento.formato === 'assEletronica') {
      this.homeService.getArquivo(this.dataDialog.documento.tipo === 'representantes' ? 'representante' : 'empresa', this.dataDialog.item ? this.dataDialog.item.id : this.empresaLogada.id, this.dataDialog.documento.documento)
        .toPromise().then(data => {
          if (data.length > 0) {
            data.forEach(item => {
              this.homeService.getSignatarioArquivo(this.dataDialog.documento.tipo, item.id).toPromise().then(data => {
                this.arquivoId = item.id;
                var contadorAssinaturas: number = 0;
                for (let item of data) {
                  if (data && item && item.signatarioDTO) {
                    const signers = {
                      id: item.signatarioDTO.id,
                      nome: item.signatarioDTO.nome,
                      dataAssinatura: item.dataAssinatura,
                      status: item.status === 'aguardandoAssinatura' ? 'Aguardando assinatura'
                        : item.status === 'assinaturaRealizada' ? 'Assinatura realizada'
                          : item.status === 'assinaturaRecusada' ? 'Assinatura recusada'
                            : item.status === 'assinaturaNaoEnviada' ? 'Clique em Assinar' : '',
                      email: item.signatarioDTO.email,
                      tipo: item.signatarioDTO.tipo,
                      telefone: item.signatarioDTO.telefone,
                    }
                    this.signers.push(signers);
                  }
                  if (item.listKey) {
                    contadorAssinaturas++;
                  }
                }
                if (contadorAssinaturas === this.signers.length) {
                  this.notificacoesEnviadas = true;
                }
              }, error => {
                Swal.fire("Erro", "Não foi possível encontrar signatários", "error");
              })
            })
          }
        }, error => {
          Swal.fire("Erro", "Não foi possível encontrar arquivo", "error");
        })
    }

    if (this.dataDialog?.ehEmprestimo) {
      this.emprestimo = true;
    }
  }

  ngOnDestroy() { }

  getURL() {
    this._rootService.getUrl().pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (response[1] != '') this.urlTermos = response[1];

        },
        error => {
          this.processando = false;
          Swal.fire({
            title: 'Atenção',
            text: error.error,
            icon: 'warning',
            confirmButtonText: 'OK'
          });
        }
      );
  }

  fecha() {
    this.dialogRef.close();
  }

  salvar() {
    if (this.dataDialog.documento.tipo == 'empresa' || this.dataDialog.documento.tipo == 'pessoa')
      this.atualizarDocumentosEmpresa();
    else
      this.atualizarDocumentosRepresentates();
  }

  atualizarDocumentosEmpresa() {
    const form = this.form.value;
    let arquivos = form.arquivos as ArquivoEmpresaDTO[];
    for (let arquivo of arquivos) {
      arquivo.moderacao = 'aguardandoModeracao';
      arquivo.documento = this.dataDialog.documento.documento;
      arquivo.empresaId = this.dataDialog.item.id;
      arquivo.documentoRoot = this.dataDialog.documento;
    }
    if ((this.dataDialog.documento.modelo === 'investidor' || this.dataDialog.documento.modelo === 'emprestimo') && this.assinaturaSemiEletronica) {
      let arquivo = {
        moderacao: this.dataDialog.documento.fundoId ? 'aprovado' : 'aguardandoModeracao',
        documento: this.dataDialog.documento.documento,
        empresaId: this.dataDialog.item.id,
        documentoRoot: this.dataDialog.documento,
        nome: this.dataDialog.documento.documento,
      }
      this.form.value.arquivos.push(arquivo);
    }
    this.atualizarEmpresa(form.arquivos);
  }

  atualizarDocumentosRepresentates() {
    const form = this.form.value;
    let representantes = form.representantes as RepresentanteEmpresaDTO[];
    for (let representante of representantes) {
      for (let arquivo of representante.arquivoRepresentanteEmpresa) {
        arquivo.empresaId = this.dataDialog.item.id;
        arquivo.documentoRoot = this.dataDialog.documento;
      }
    }
    if (this.dataDialog.documento.modelo === 'investidor') {
      for (let representante of representantes) {
        let moderacao: ModeracaoArquivo = 'aguardandoModeracao'
        let arquivo = {
          moderacao: moderacao,
          documento: this.dataDialog.documento.documento,
          documentoRoot: this.dataDialog.documento,
          nome: this.dataDialog.documento.documento,
        }
        representante.arquivoRepresentanteEmpresa.push(arquivo);
      }
    }

    if (this.dataDialog.documento.modelo === 'emprestimo') {
      for (let representante of representantes) {
        let moderacao: ModeracaoArquivo = 'aguardandoModeracao'
        let arquivo = {
          moderacao: moderacao,
          documento: this.dataDialog.documento.documento,
          documentoRoot: this.dataDialog.documento,
          nome: this.dataDialog.documento.documento,
        }
        representante.arquivoRepresentanteEmpresa.push(arquivo);
      }
    }
    this.atualizarRepresentantes(representantes);
  }

  ehPessoaJuridica(documento) {
    if (documento) {
      const documentoInput = documento.replace(/[^0-9]/g, "");
      if (documentoInput.length <= 11) {
        return false;
      } else {
        return true;
      }
    }
  }

  upload(tipo: string, arquivos: ArquivoEmpresaDTO[]) {
    const form = (this.form.get('arquivos') as FormArray);
    for (let arquivo of arquivos) {
      form.push(new FormControl(arquivo))
    }
  }

  atualizarRepresentantes(pessoaData: RepresentanteEmpresaDTO[]) {
    this.representanteRetorno = pessoaData;
    this.processando = true;
    if (pessoaData) {
      this.pessoaService
        .atualizarRepresentantes(pessoaData)
        .pipe(untilDestroyed(this))
        .subscribe(
          (response) => {
            this.processando = false;
            this.modalFinal('Documentos atualizados com sucesso!', response, "representante");
          },
          (error) => {
            this.processando = false;
            console.error(error);
            Swal.fire('Erro!', error.error, 'error');
          }
        );
    } else {
      this.processando = false;
    }
  }

  atualizarEmpresa(pessoaData: ArquivoEmpresaDTO[]) {
    this.arquivoRetorno = pessoaData;
    this.processando = true;
    Swal.showLoading();
    if (pessoaData) {
      this.pessoaService
        .atualizarDocumentacao(pessoaData)
        .pipe(untilDestroyed(this))
        .subscribe(
          (response) => {
            this.processando = false;
            Swal.close();
            this.modalFinal('Documentos atualizados com sucesso!', response, "empresa");
          },
          (error) => {
            this.processando = false;
            console.error(error);
            Swal.fire('Erro!', error.error, 'error');
          }
        );
    } else {
      this.processando = false;
    }
  }

  pageChange(event) {
    if (event === this.lastPage) {
      this.scrollBottom = true;
    }
  }

  pageLoad(event) {
    this.lastPage = event;
  }

  modalFinal(titulo, response, tipo) {
    const dialog = this.dialog.open(ModalConfirmacaoComponent, {
      data: {
        titulo: titulo,
        texto: response,
        subtexto: `Por favor aguarde, seu documento foi enviado para moderação`,
        btnTexto: 'Enviar novo documento',
        whatsapp: false,
        checkout: false
      },
      width: '600px',
      backdropClass: 'modal-alerta'
    });
    dialog.afterClosed().subscribe(result => {
      if (result) {
        if (result.finalizar === true) {
          this.dialogRef.close({
            salvou: true,
            tipoArquivo: tipo,
            response: tipo === 'empresa' ? this.arquivoRetorno : this.representanteRetorno,
          });
        }
      }
    });
  }

  addRepresentantes() {
    let formRepresentantes = this.form.get('representantes') as FormArray;
    if (this.dataDialog.item.representantes && this.dataDialog.item.representantes.length > 0) {
      this.dataDialog.item.representantes.forEach(element => this.add(element));
    } else if (formRepresentantes.length === 0) {
      this.add();
    }
  }

  public add(representante?: RepresentanteEmpresaDTO) {
    let formRepresentantes = this.form.get('representantes') as FormArray;
    formRepresentantes.push(
      this.fb.group({
        arquivoRepresentanteEmpresa: this.fb.array(representante.arquivoRepresentanteEmpresa.sort((a, b) => (a.id > b.id) ? -1 : 1) || []),
        id: this.fb.control(representante.id || 1),
        nome: this.fb.control(representante.nome || '', [Validators.required]),
        documento: this.fb.control(representante.documento || '', [Validators.required]),
        dataNascimento: this.fb.control(representante.dataNascimento || '', [Validators.required]),
        email: this.fb.control(representante.email || '', [Validators.required]),
        telefone: this.fb.control(representante.telefone || '', [Validators.required]),
        tipoRepresentante: this.fb.control('socioDiretor'),
        pessoaPoliticamenteExposta: this.fb.control(false, [Validators.required]),
      })
    );
  }

  async setarTemporizador() {
    this.EnviadoEm = this.temporizadorService.setConfirmacaoEnviadoEm();
    this.bloqueiaEnviarAte = this.temporizadorService.setBloqueiaEnviarAte(this.bloqueiaEnviarAte, this.EnviadoEm, this.tempoReenvio, 'EnviadoEm');
    this.temporizadorService.setIntervaloNovoCodigo(this.bloqueiaEnviarAte, 'EnviadoEm');

    this.subscription = this.temporizadorService.podeReenviarSms.pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.podeReenviar = response;
      }, error => {
        console.error(error);
      }
      );
  }

  vincular(data) {
    let arquivo;
    if (this.dataDialog.ehInvestimento) {
      arquivo = 0;
    } else {
      arquivo = 0;
    }
    this.carregando = true;
    this.clicou = true;
    Swal.showLoading();
    this.homeService.vincularSignatarioArquivo(arquivo, data.documento).toPromise().then(async (response) => {
      await this.setarTemporizador();
      Swal.fire('Sucesso', 'E-mails enviados com sucesso para os assinantes.', 'success').then(() => { this.carregando = false }).
        then(() => {
          this.dialogRef.close();
        });

    }, error => {
      Swal.fire('Erro', error.error || 'Erro ao enviar e-mails aos assinantes', 'error').then(() => this.dialogRef.close())
      this.carregando = false;
      this.clicou = false;
    })
  }

  vincularEmprestimo(data) {

  }


  showBoleto() {
    this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(
      'data:application/pdf;base64,' + this.dataDialog.arquivos.base64
    );
    // this.showPdf(response);
  }

  next() {
    if (this.elementstepper1.selectedIndex == 0) {
      //enviar email para usuario
      this.elementstepper1.next()
    }
  }

  public back() {
    this.elementstepper1.previous();
  }

  public onStepChange(event: any): void { }

  deleteFile(documento) { }

  finalizarDocumento() {
    this._rootService.finalizarDocumento(this.dataDialog)
  }

  mascaraTelefone(tel) {
    if (tel && tel.length === 11) {
      return '(' + tel.substring(0, 2) + ') ' + tel.substring(2, 3) + ' ' + tel.substring(3, 7) + '-' + tel.substring(7, 11);
    } else {
      return '(' + tel.substring(0, 2) + ') ' + tel.substring(2, 6) + '-' + tel.substring(6, 10);
    }
  }

  reenviarEmail(signerId) {
    this.EnviadoEm = this.temporizadorService.setConfirmacaoEnviadoEm();
    this.bloqueiaEnviarAte = this.temporizadorService.setBloqueiaEnviarAte(this.bloqueiaEnviarAte, this.EnviadoEm, this.tempoReenvio, 'EnviadoEm');
    this.temporizadorService.setIntervaloNovoCodigo(this.bloqueiaEnviarAte, 'EnviadoEm');

    this.subscription = this.temporizadorService.podeReenviarSms.pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.podeReenviar = response;
      }, error => {
        console.error(error);
      }
      )

    this.homeService.reenvioEmail(this.arquivoId, signerId).toPromise().then(() => {
      Swal.fire('Sucesso', 'E-mail reenviado com sucesso para o assinante.', 'success');
    }, error => {
      console.error(error);
      //Maneira de tratar momentaneamente
      Swal.fire('Erro', error.error, 'success');
    })
  }

  downloadDocumento() {
    // const downloadLink = document.createElement('a');
    const link = this.dataDialog.documento.arquivoRoot[0].linkDownload;
    // const nome = this.dataDialog.documento.documento;

    // downloadLink.href = link;
    // downloadLink.download = nome;
    // downloadLink.click();
    window.open(link, '_blank');

    // FileSaver.saveAs(pdfUrl,pdfName);
  }
}
