import { environment } from '@project/src/environments/environment';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArquivoEmpresaDTO } from '@project/src/app/model/pessoa/arquivo-empresa-dto.model';
import { DocumentoDTO } from '@project/src/app/model/sistema/documento-dto.model';
import { RootEndpointService } from '@project/src/app/service/root-endpoint.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import Swal from 'sweetalert2';
import { StringFormatService } from '@project/src/app/service/util/stringFormater.service';
import { InvestimentoEndpointService } from '@project/src/app/service/investimento-endpoint.service';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';

@Component({
  selector: 'app-novo-documento',
  templateUrl: './novo-documento.component.html',
  styleUrls: ['./novo-documento.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NovoDocumentoComponent implements OnInit, OnDestroy {

  form: FormGroup;
  loading: boolean = false;

  env = environment

  pessoa: boolean = false;
  empresa: boolean = false;
  representantes: boolean = false;

  uploadDeArquivo: boolean = false;
  assEletronica: boolean = false;
  assPreencher: boolean = false;
  assSemiEletronica: boolean = false;
  processando: boolean = false;

  assinaturas: any[] = [
    { value: 'email', viewValue: 'Assinatura por email' },
    { value: 'facial', viewValue: 'Assinatura facial' },
    { value: 'certificado', viewValue: 'Assinatura por certificado' },
  ];

  mainAuthList: Array<{
    label: string,
    data: boolean,
    disable: boolean
  }> = [];

  otherAuthList: Array<{
    label: string,
    data: boolean,
    disable: boolean
  }> = [];

  fundos: any[] = []
  fundoSelecionado: number = 0;

  selected = "";
  empresaLogada: any;
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _fb: FormBuilder,
    private _rootService: RootEndpointService,
    public dialogRef: MatDialogRef<NovoDocumentoComponent>,
    public stringFormat: StringFormatService,
    private investimentoService: InvestimentoEndpointService,
    private autenticacao: AutenticacaoEndpointService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.empresaLogada = this.autenticacao.regraEmpresa.empresa;
   }

  ngOnInit() {
    if (this.data) {
      this.mainAuthList = [{
        label: "E-mail",
        data: this.data.authEmail ? true : false,
        disable: false
      }, {
        label: "Certificado digital",
        data: this.data.authCertificado ? true : false,
        disable: true
      }, {
        label: "Pix",
        data: this.data.authPix ? true : false,
        disable: false
      }, {
        label: "SMS",
        data: this.data.authSms ? true : false,
        disable: false
      }, {
        label: "Whatsapp",
        data: this.data.authWhatsapp ? true : false,
        disable: false
      }];
      this.otherAuthList = [{
        label: "Selfie",
        data: this.data.selfieEnabled ? true : false,
        disable: false
      }, {
        label: "Assinatura manuscrita",
        data: this.data.handwrittenEnabled ? true : false,
        disable: false
      }, {
        label: "Documento oficial",
        data: this.data.officialDocumentEnabled ? true : false,
        disable: false
      }, {
        label: "Autenticação liveness",
        data: this.data.livenessEnabled ? true : false,
        disable: false
      }, {
        label: "Biometria facial",
        data: this.data.facialBiomeetricsEnabled ? true : false,
        disable: false
      }];
    } else {
      this.mainAuthList = [{
        label: "E-mail",
        data: false,
        disable: false
      }, {
        label: "Certificado digital",
        data: false,
        disable: true
      }, {
        label: "Pix",
        data: false,
        disable: false
      }, {
        label: "SMS",
        data: false,
        disable: false
      }, {
        label: "Whatsapp",
        data: false,
        disable: false
      }];
      this.otherAuthList = [{
        label: "Selfie",
        data: false,
        disable: false
      }, {
        label: "Assinatura manuscrita",
        data: false,
        disable: false
      }, {
        label: "Documento oficial",
        data: false,
        disable: false
      }, {
        label: "Autenticação liveness",
        data: false,
        disable: false
      }, {
        label: "Biometria facial",
        data: false,
        disable: false
      }];
    }
    this.mainAuthList.forEach((auth) => {
      if (auth.data) this.selected = auth.label;
    })

    this.form = this._fb.group({
      documento: this._fb.control('', [Validators.required]),
      id: this._fb.control(0),
      ativo: this._fb.control(true, [Validators.required]),
      tipo: this._fb.control('', [Validators.required]),
      formato: this._fb.control('', [Validators.required]),
      modelo: this._fb.control(this.data.modelo),
      obrigatorio: this._fb.control(null, [Validators.required]),
      arquivoRoot: this._fb.array([]),
      descricao: this._fb.control('', null),
      tipoAssinatura: this._fb.control('', null)
    });

    if (this.data) {
      this.form.patchValue(this.data);
      this.onChangeToggle(this.data.tipo);
      this.onChangeToggleCampo(this.data.formato);
      if(this.data.modelo === 'investidor') this.getFundos()
      if(this.data.fundoId) this.fundoSelecionado = this.data.fundoId
    }
  }

  getFundos(){
    this.investimentoService.listarFundos(this.empresaLogada.id, null, 0, 20, null, true).subscribe(
      (res)=>{
        this.fundos = res.content;
      }
    )
  }

  ngOnDestroy() { }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {
    let value = this.form.value as DocumentoDTO;
    if(this.fundoSelecionado > 0){ 
      value.fundoId = this.fundoSelecionado
    }else value.fundoId = null
    this.processando = true;
    if (this.data.formato) {
      this.onUpdate(value);
    } else {
      this.onSave(value);
    }
  }

  onSave(documento: DocumentoDTO) {
    this.loading = true;
    this._rootService.criarDocumentoSistema(documento).pipe(untilDestroyed(this)).subscribe(
      (response) => {
        this.loading = false;
        this.processando = false;
        Swal.fire('Sucesso', "Documento criado com sucesso.", 'success').then(() => this.dialogRef.close({ atualizar: true }));
        if (documento.formato === 'assEletronica') {
          this.salvarAuths(response);
        }
      }, (error) => {
        this.loading = false;
        Swal.fire({
          title: 'Erro',
          text: error.error,
          icon: 'error',
        })
        console.error(error);

        Swal.fire('Erro', error.error, 'error');
      });
  }

  onUpdate(documento: DocumentoDTO) {
    this._rootService.atualizarDocumentoSistema(documento).pipe(untilDestroyed(this)).subscribe(
      (response) => {
        this.processando = false;
        Swal.fire('Sucesso', response, 'success').then(() => this.dialogRef.close({ atualizar: true }));
        if (documento.formato === 'assEletronica') {
          this.salvarAuths(documento.id);
        }
      }, (error) => {
        console.error(error);
        Swal.fire('Erro', error.error, 'error');
      });
  }

  onChangeToggle(tipo: string, event?: any) {
    this.form.get('tipo').setValue(tipo);
    switch (tipo) {
      case 'pessoa':
        this.pessoa = true;
        this.empresa = false;
        this.representantes = false;
        break;
      case 'empresa':
        this.empresa = true;
        this.pessoa = false;
        this.representantes = false;
        break;
      case 'representantes':
        this.representantes = true;
        this.pessoa = false;
        this.empresa = false;
        break;
    }
    if (event && !event.checked) {
      this.form.setErrors({ 'invalid': true });
    }
  }

  onChangeToggleCampo(event: string) {
    this.form.get('formato').setValue(event);
    switch (event) {
      case 'uploadDeArquivo':
        this.uploadDeArquivo = true;
        this.assEletronica = false;
        this.assPreencher = false;
        this.assSemiEletronica = false;
        break;
      case 'assEletronica':
        this.uploadDeArquivo = false;
        this.assEletronica = true;
        this.assPreencher = false;
        this.assSemiEletronica = false;
        break;
      case 'assPreencher':
        this.uploadDeArquivo = false;
        this.assEletronica = false;
        this.assPreencher = true;
        this.assSemiEletronica = false;
        break;
      case 'assSemiEletronica':
        this.uploadDeArquivo = false;
        this.assEletronica = false;
        this.assPreencher = false;
        this.assSemiEletronica = true;
        break;
    }
  }

  upload(tipo: string, arquivos: ArquivoEmpresaDTO[]) {
    const form = (this.form.get('arquivoRoot') as FormArray);
    for (let arquivo of arquivos) {
      form.push(new FormControl(arquivo))
    }
  }

  visualizarPDF() {
    this._rootService.converteDocumentoHtmlToPdf(this.data.id).subscribe(data => {
      const linkSource = 'data:application/pdf;base64,' + data;
      const pdfWindow = window.open("");
      pdfWindow.document.write("<iframe width='100%' height='100%' src='" + linkSource + "'></iframe>");
    })
  }

  onChange(conta: any) {
    conta.data = !conta.data;
    if ((conta.label === "Documento oficial" || conta.label === "Autenticação liveness") && this.otherAuthList[4].data) {
      this.otherAuthList[4].data = false;
    }
    if (conta.label === "Biometria facial") {
      if (this.otherAuthList[2].data) this.otherAuthList[2].data = false;
      if (this.otherAuthList[3].data) this.otherAuthList[3].data = false
    }
  }

  salvarAuths(docId) {
    if (docId) {
      var auths = {
        email: false,
        certificado: false,
        pix: false,
        sms: false,
        selfie: false,
        whatsapp: false,
        assinatura: false,
        documento: false,
        autenticacao: false,
        biometria: false
      };

      switch (this.selected) {
        case 'E-mail':
          auths.email = true;
          break;
        case 'Certificado digital':
          auths.certificado = true;
          break;
        case 'Pix':
          auths.pix = true
          break;
        case 'SMS':
          auths.sms = true
          break;
      }

      this.otherAuthList.forEach(data => {
        switch (data.label) {
          case 'Whatsapp':
            auths.whatsapp = data.data;
            break;
          case 'Selfie':
            auths.selfie = data.data;
            break;
          case 'Assinatura manuscrita':
            auths.assinatura = data.data;
            break;
          case 'Documento oficial':
            auths.documento = data.data;
            break;
          case 'Autenticação liveness':
            auths.autenticacao = data.data;
            break;
          case 'Biometria facial':
            auths.biometria = data.data;
            break;
        }
      });

      this._rootService.salvarAuths(auths, docId).toPromise().then(() => { });
    }
  }

  get nomeFundoSelecionado(){
    if(this.fundos.length > 0 && this.fundoSelecionado)
      return this.fundos.filter(f => f.id === this.fundoSelecionado)[0].name
  }
}
