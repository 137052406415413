import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';
import { environment } from '@project/src/environments/environment.default';
import { untilDestroyed } from 'ngx-take-until-destroy';
@Component({
  selector: 'app-precos-e-taxas',
  templateUrl: './precos-e-taxas.component.html',
  styleUrls: ['./precos-e-taxas.component.less']
})
export class PrecosETaxasComponent implements OnInit {
  ehAdmin = false;

  @Input()
  public form: FormGroup;

  @Input()
  public data: any;

  @Input()
  public tipoConta: string;

  env = environment;
  regraEmpresaLogada: any = {};

  constructor(private autenticacao: AutenticacaoEndpointService){
    this.autenticacao._regraEmpresa.pipe().subscribe(response=>{
      this.regraEmpresaLogada = response;
    }) 
  }
  ngOnInit() {
    this.data = this.data || {};
    
    this.form.addControl('limiteAntecipacao', new FormControl(this.data.limiteAntecipacao || 0));
    this.form.addControl('porcentagemMesFaturaAntecipada', new FormControl(this.data.porcentagemMesFaturaAntecipada + "%"  || 0));
    this.form.addControl('tipoTaxaAntecipacao', new FormControl(this.data.tipoTaxaAntecipacao || 'simples'));
    this.form.addControl('descontarTituloAposVencimento', new FormControl(this.data.descontarTituloAposVencimento));
    this.form.addControl('precoBaixaBoleto', new FormControl(this.data.precoBaixaBoleto || 0));
    this.form.addControl('precoPadraoEdicaoBoleto', new FormControl(this.data.precoPadraoEdicaoBoleto || 0));
    this.form.addControl('precoEmissaoBoleto', new FormControl(this.data.precoEmissaoBoleto || 0));
    this.form.addControl('precoLiquidacaoBoleto', new FormControl(this.data.precoLiquidacaoBoleto || 0));
    this.form.addControl('diasAposVencimentoAplicacaoPrecoManutencao', new FormControl(this.data.diasAposVencimentoAplicacaoPrecoManutencao || 0));
    // this.form.addControl('porcentagemMesFaturaAntecipada', new FormControl(this.data.porcentagemMesFaturaAntecipada  + "%"  || 0));
    this.form.addControl('valorMaximoEmissaoBoleto', new FormControl(this.data.valorMaximoEmissaoBoleto || 1000));
    this.form.addControl('porcentagemMesPagamentoCartao', new FormControl(this.data.porcentagemMesPagamentoCartao  + "%"  || 0));
    this.form.addControl('precoPagamentoCartao', new FormControl(this.data.precoPagamentoCartao || 0));
    this.form.addControl('limiteTransferenciaDiaria', new FormControl(this.data.limiteTransferenciaDiaria || 1000));
    this.form.addControl('precoMaximoTransferencia', new FormControl(this.data.precoMaximoTransferencia || 0));
    this.form.addControl('precoMinimoTransferencia', new FormControl(this.data.precoMinimoTransferencia || 0)); 
    this.form.addControl('diaFechamentoMensalidade', new FormControl(this.data.diaFechamentoMensalidade || 0)); 
    this.form.addControl('precoMensalidade', new FormControl(this.data.precoMensalidade || 0)); 
    this.form.addControl('precoPagamentoConta', new FormControl(this.data.precoPagamentoConta || 0)); 
    this.form.addControl('precoManutencaoTituloVencido', new FormControl(this.data.precoManutencaoTituloVencido || 0)); 
    this.form.addControl('quantidadeTransferenciasGratuitasNoMes', new FormControl(this.data.quantidadeTransferenciasGratuitasNoMes || 0));
    
    this.form.addControl('atualizacaoAutomaticaCadastro', new FormControl(this.data.atualizacaoAutomaticaCadastro || 0)); 
    this.form.addControl('taxaAberturaConta', new FormControl(this.data.taxaAberturaConta || 0)); 
    this.form.addControl('mensalidadeContaInativa', new FormControl(this.data.mensalidadeContaInativa || 0)); 
    this.form.addControl('validacaoCadastralContaPessoaFisicaSimples', new FormControl(this.data.validacaoCadastralContaPessoaFisicaSimples || 0)); 
    this.form.addControl('validacaoCadastralContaPessoaFisicaCompleta', new FormControl(this.data.validacaoCadastralContaPessoaFisicaCompleta || 0)); 
    this.form.addControl('validacaoCadastralContaPessoaJuridica', new FormControl(this.data.validacaoCadastralContaPessoaJuridica || 0)); 
    this.form.addControl('pesquisaSerasa', new FormControl(this.data.pesquisaSerasa || 0));
    this.form.addControl('negativacaoSerasa', new FormControl(this.data.negativacaoSerasa || 0));  
    this.form.addControl('precoPagamentoConta', new FormControl(this.data.precoPagamentoConta || 0));
    this.form.addControl('pagamentoTributosImpostosBoletos', new FormControl(this.data.pagamentoTributosImpostosBoletos || 0));
    this.form.addControl('pagamentoBoletosDiversos', new FormControl(this.data.pagamentoBoletosDiversos || 0));
    this.form.addControl('pagamentoContasConsumo', new FormControl(this.data.pagamentoContasConsumo || 0));
    this.form.addControl('precoTransferenciaLote', new FormControl(this.data.precoTransferenciaLote || 0));
    this.form.addControl('transferenciasEntreContasInternas', new FormControl(this.data.transferenciasEntreContasInternas || 0));
    this.form.addControl('taxaAvisoEmissaoFaturas', new FormControl(this.data.taxaAvisoEmissaoFaturas || 0));
    this.form.addControl('taxaLembreteAntesDepoisVencimento', new FormControl(this.data.taxaLembreteAntesDepoisVencimento || 0));
    this.form.addControl('taxaMensagemConfirmacaoPagamento', new FormControl(this.data.taxaMensagemConfirmacaoPagamento || 0));
    this.form.addControl('taxaReducaoInadimplencia', new FormControl(this.data.taxaReducaoInadimplencia || 0));
    this.form.addControl('taxaEnvioCompromissoAgendamentoCalendario', new FormControl(this.data.taxaEnvioCompromissoAgendamentoCalendario || 0));
    this.form.addControl('taxaEnvioToken', new FormControl(this.data.taxaEnvioToken || 0));
    this.form.addControl('taxaSolicitacaoExtrato', new FormControl(this.data.taxaSolicitacaoExtrato || 0));
    this.form.addControl('ambiente', new FormControl(this.data.ambiente || 'teste'));
    this.form.addControl('valorMinimoTransferencia', new FormControl(this.data.valorMinimoTransferencia || 15.0, [Validators.required, Validators.min(0.01)])); 
    this.form.addControl('valorTransferenciaAlcada', new FormControl(this.data.valorTransferenciaAlcada || 0))
    this.form.addControl('taxaTransferenciaEntreAgencias', new FormControl(this.data.taxaTransferenciaEntreAgencias || 0))
    
    this.form.get('atualizacaoAutomaticaCadastro').disable();
    this.form.get('taxaAberturaConta').disable(); 
    this.form.get('mensalidadeContaInativa').disable(); 
    this.form.get('validacaoCadastralContaPessoaFisicaSimples').disable(); 
    this.form.get('validacaoCadastralContaPessoaFisicaCompleta').disable(); 
    this.form.get('validacaoCadastralContaPessoaJuridica').disable(); 
    this.form.get('pesquisaSerasa').disable();
    this.form.get('negativacaoSerasa').disable();  
    this.form.get('precoPagamentoConta').disable();
    this.form.get('pagamentoTributosImpostosBoletos').disable();
    this.form.get('pagamentoContasConsumo').disable();
    this.form.get('taxaLembreteAntesDepoisVencimento').disable();
    this.form.get('taxaMensagemConfirmacaoPagamento').disable();
    this.form.get('taxaReducaoInadimplencia').disable();
    this.form.get('taxaEnvioCompromissoAgendamentoCalendario').disable();
    this.form.get('taxaEnvioToken').disable();

  }
  troca(e){
    if(e.key == "Backspace" || e.key== " " || e.key =="ArrowLeft"|| e.key =="ArrowRight"){
      if(e.target.value == ""){
        e.target.value = e.target.value + '%';
      }
      return
    }
    e.target.value = e.target.value.replace(/%/i,"")
    e.target.value = e.target.value + '%';
  }


  defineTipoConta(tipo){
    switch (tipo) {
      case `contaDigitalS`:
        return "Conta Digital S";
      case `contaCorrenteDigital`: 
        return "Conta Corrente";
      case `contaVinculadaDigital`: 
        return "Conta Vinculada";
      case `contaEscrowDigital`: 
        return "Conta Escrow";
      case `contaBloqueioJudicial`: 
        return "Conta Bloqueio";
      case `contaSalarioDigital`: 
        return "Conta Salario";
      case `contaServicoDigital`: 
        return "Conta Servico";
      case `contaInvestidorDigital`: 
        return "Conta Investidor";
      case `contaDigital`:
        return "Conta Digital";
    }
  }
}
