import { ParseError } from '@angular/compiler';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidacoesService {
  constructor() { }

  validaCNPJ(valor: string) {
    valor = valor.replace(/[^0-9]/g, '');
    if (!valor) {
      return false;
    }
    const todosIguais = valor.split('').filter(a => a === valor[0]).length === valor.length;
    if (todosIguais) {
      return false;
    }
    let tamanho = valor.length - 2;
    let numeros = valor.substring(0, tamanho);
    const digitos = valor.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i), 10) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (`${resultado}` !== digitos.charAt(0)) {
      return false;
    }
    tamanho = tamanho + 1;
    numeros = valor.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i), 10) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (`${resultado}` !== digitos.charAt(1)) {
      return false;
    }

    return true;
  }

  validaCPF(valor) {
    if (!valor) {
      return false;
    }
    valor = valor.replace(/[^0-9]/g, '');
    const todosIguais = valor.split('').filter(a => a === valor[0]).length === valor.length;
    if (todosIguais) {
      return false;
    }
    let soma = 0;
    for (let i = 1; i <= 9; i++) {
      soma = soma + parseInt(valor.substring(i - 1, i), 10) * (11 - i);
    }
    let resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) {
      resto = 0;
    }
    if (resto !== parseInt(valor.substring(9, 10), 10)) {
      return false;
    }
    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma = soma + parseInt(valor.substring(i - 1, i), 10) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) {
      resto = 0;
    }
    if (resto !== parseInt(valor.substring(10, 11), 10)) {
      return false;
    }

    return true;
  }

  verificaBandeiraCartao(cardNumber: string) {
    if ((cardNumber.substring(0, 6) === '636368')
      || (cardNumber.substring(0, 6) === '438935')
      || (cardNumber.substring(0, 6) === '504175')
      || (cardNumber.substring(0, 6) === '451416')
      || (cardNumber.substring(0, 6) === '509048')
      || (cardNumber.substring(0, 6) === '509067')
      || (cardNumber.substring(0, 6) === '509049')
      || (cardNumber.substring(0, 6) === '509069')
      || (cardNumber.substring(0, 6) === '509050')
      || (cardNumber.substring(0, 6) === '509074')
      || (cardNumber.substring(0, 6) === '509068')
      || (cardNumber.substring(0, 6) === '509040')
      || (cardNumber.substring(0, 6) === '509045')
      || (cardNumber.substring(0, 6) === '509051')
      || (cardNumber.substring(0, 6) === '509046')
      || (cardNumber.substring(0, 6) === '509066')
      || (cardNumber.substring(0, 6) === '509047')
      || (cardNumber.substring(0, 6) === '509042')
      || (cardNumber.substring(0, 6) === '509052')
      || (cardNumber.substring(0, 6) === '509043')
      || (cardNumber.substring(0, 6) === '509064')
      || (cardNumber.substring(0, 6) === '509040')
      || (cardNumber.substring(0, 5) === '36297')
      || (cardNumber.substring(0, 4) === '5067')
      || (cardNumber.substring(0, 4) === '4576')
      || (cardNumber.substring(0, 4) === '4011')) {
      return 'Elo';
    } else if (cardNumber.substring(0, 1) === '4') {
      return 'Visa';
    } else if (cardNumber.substring(0, 1) === '5') {
      return 'Mastercard';
    } else if ((cardNumber.substring(0, 2) === '34')
      || (cardNumber.substring(0, 2) === '37')) {
      return 'Amex';
    } else if ((cardNumber.substring(0, 2) === '38')
      || (cardNumber.substring(0, 2) === '60')) {
      return 'Hipercard';
    } else {
      return 'invalido';
      // throw new Error('Só aceitamos as seguintes bandeiras: Mastercard, Amex, Visa, Elo e Hipercard');
    }
  }

}
