import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FiltroCobrancaDTO } from '@model/filtro/filtro-cobranca-dto.model';
import { CobrancaEndpointService } from '@service/cobranca-endpoint.service';
import { environment } from '@src/environments/environment';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';
import { EmitirCobrancaComponent } from '../emitir-cobranca/emitir-cobranca.component';
import { ExcluirCobrancaComponent } from '../excluir-cobranca/excluir-cobranca.component';
import { LiquidarCobrancaComponent } from '../visualizar-cobranca/liquidar-cobranca/liquidar-cobranca.component';
import { VisualizarCobrancaComponent } from '../visualizar-cobranca/visualizar-cobranca.component';
import { ProtestarCobrancaComponent } from '../protestar-cobranca/protestar-cobranca.component ';
import { StatusProtesto } from '@project/src/app/model/cobranca/status-protesto.model';

@Injectable({
  providedIn: 'root'
})
export class ListaCobrancaService {
  private _excluiuItem = new BehaviorSubject<boolean>(false);
  excluiuItem = this._excluiuItem.asObservable();

  private _liquidouItem = new BehaviorSubject<boolean>(false);
  liquidouItem = this._liquidouItem.asObservable();

  private _protestouItem = new BehaviorSubject<boolean>(false);
  protestouItem = this._protestouItem.asObservable();

  private _atualizouItem = new BehaviorSubject<boolean>(false);
  atualizouItem = this._atualizouItem.asObservable();
  env = environment;

  filtro: FiltroCobrancaDTO = {};

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private cobrancaService: CobrancaEndpointService
  ) { }

  venceu(dataVencimento) {
    return (
      isBefore(new Date(dataVencimento), new Date()) &&
      !isSameDay(new Date(dataVencimento), new Date())
    );
  }

  envioProtestoPermitido(dataProtestoCalculada) {
    return (isBefore(new Date(dataProtestoCalculada), new Date()));
  }

  cancelamentoProtestoPermitido(statusProtesto) {
    if(statusProtesto === StatusProtesto.AGUARDANDORETORNO || 
      statusProtesto === StatusProtesto.COLETADO || 
      statusProtesto === StatusProtesto.GERADO ||
      statusProtesto === StatusProtesto.CONFIRMADO ||
      statusProtesto === StatusProtesto.PROTESTADO 
    ){
      return true;
    }else{
      return false;
    }
  }
   
  alteracaoProtestoPermitida(statusProtesto) {
    if(statusProtesto === StatusProtesto.DEVOLVIDO){
      return true;
    }else{
      return false;
    }
  }
   
  aguardandoPagamento(dataVencimento) {
    return (
      isAfter(new Date(dataVencimento), new Date()) ||
      isSameDay(new Date(dataVencimento), new Date())
    );
  }

  ehMesmoDia(dataVencimento, dataRecalculada) {
    return isSameDay(new Date(dataVencimento), new Date(dataRecalculada));
  }

  novaCobranca(tipo, idCobranca?, numeroParcelas?) {
    // tipo: 1 - avulsa, 2 - assinatura, 3 - parcelada
    const dialog = this.dialog.open(EmitirCobrancaComponent, {
      data: {
        tipoCobranca: tipo,
        idCobranca: idCobranca || null,
        numeroParcelas: numeroParcelas || null
      },
      backdropClass: 'modal-maior'
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        if (result.nova === true) {
          this.novaCobranca('1')
        }
      }
    });
  }

  visualizar(itemSelecionado) {
    const dialog = this.dialog.open(VisualizarCobrancaComponent, {
      data: {
        idCobranca: (itemSelecionado.idCobranca || itemSelecionado.idFatura)
      },
      backdropClass: 'modal-maior'
    });
    dialog.afterClosed().subscribe(result => {
      this._atualizouItem.next(true);
      if (result && result.nova == true) {
        this.visualizar(result.cobranca)
      }
    });
  }
  visualizarPeloExtrato(idCobranca) {
    const dialog = this.dialog.open(VisualizarCobrancaComponent, {
      data: {
        idCobranca: idCobranca
      },
      backdropClass: 'modal-maior'
    });
  }

  copiarLinkFatura(itemSelecionado) {
    const link = `${document.location.protocol}//${document.location.host}/#/fatura/visualizar/${itemSelecionado.idPublico}`;

    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', link);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  enviarViaWhatsapp(itemSelecionado) {
    let target = `https://api.whatsapp.com/send?text=${encodeURIComponent(`Olá uma cobrança foi gerada e enviada para o seu e-mail, você pode acessar pelo link: ${this.env.url.appSite}/#/fatura/visualizar/${itemSelecionado.idPublico}`)}`
    let a = document.createElement('a')
    a.target = '_blank';
    a.href = target;
    a.click();
  }

  imprimir(itemSelecionado) {
    // window.open('/#/documento/boleto/' + itemSelecionado.idCobranca, '_blank');
    window.open('/#/boleto/visualizar/' + itemSelecionado.idCobranca, '_blank');
  }

  gerarCarne(itemSelecionado) {
    // window.open('/#/documento/carne/' + itemSelecionado.seuNumero, '_blank');
    window.open('/#/boleto/carne/' + itemSelecionado.seuNumero, '_blank');
  }

  enviarMensagem(itemSelecionado) {
    this.cobrancaService.reenviarSmsEmail(itemSelecionado.idCobranca).subscribe(
      response => {
        Swal.fire('Sucesso', response, 'success');
      },
      error => {
        Swal.fire('Atenção', error.error, 'warning');
      }
    );
  }

  liquidarCobranca(itemSelecionado) {
    const dialog = this.dialog.open(LiquidarCobrancaComponent, {
      data: {
        cobrancaId: itemSelecionado.idCobranca,
        liquidouCobranca: false
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        if (result.liquidouCobranca === true) {
          this._liquidouItem.next(true);
        }
      }
    });
  }

  protestarCobranca(cobranca) {
    const dialog = this.dialog.open(ProtestarCobrancaComponent, {
      data: {
        cobranca: cobranca,
        protestouCobranca: false
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        if (result.protestouCobranca === true) {
          this._protestouItem.next(true);
        }
      }
    });
  }

  cancelarProtestoCobranca(cobranca) {
    const dialog = this.dialog.open(ProtestarCobrancaComponent, {
      data: {
        cobranca: cobranca,
        protestouCobranca: false
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        if (result.protestouCobranca === true) {
          this._protestouItem.next(true);
        }
      }
    });
  }

  askExcluir(itemSelecionado, abaLista?) {
    // se a verificação no tipoCobranca nao funcionar vou ter que passar o tipo no parametro
    let tipoCobranca;
    if (itemSelecionado.intervaloAssinatura) {
      if (abaLista === 'todasCobrancas') {
        tipoCobranca = 'avulsa';
      } else {
        tipoCobranca = 'assinatura'
      }
    } else if (itemSelecionado.numeroParcelas === 1) {
      tipoCobranca = 'avulsa';
    } else {
      tipoCobranca = 'parcelada';
    }

    const dialog = this.dialog.open(ExcluirCobrancaComponent, {
      data: {
        cobrancaId: itemSelecionado.idCobranca,
        numeroParcelas: itemSelecionado.numeroParcelas,
        excluiuCobranca: false,
        tipoCobranca: tipoCobranca
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        if (result.excluiuCobranca === true) {
          this._excluiuItem.next(true);
        }
      }
    });
  }

  filtroNotificacao(params) {
    if (params['is-boleto']) {
      this.filtro.boleto = this.filtro.excluidas || {};
      this.filtro.boleto.choice = params['is-boleto'];
    }
    if (params['is-cartao']) {
      this.filtro.cartao = this.filtro.cartao || {};
      this.filtro.cartao.choice = params['is-cartao'];
    }
    if (params['is-excluida']) {
      this.filtro.excluidas = this.filtro.excluidas || {};
      this.filtro.excluidas.choice = params['is-excluida'];
    }
    if (params['is-vencida']) {
      this.filtro.vencidas = this.filtro.vencidas || {};
      this.filtro.vencidas.choice = params['is-vencida'];
    }
    if (params['is-paga']) {
      this.filtro.pagas = this.filtro.pagas || {};
      this.filtro.pagas.choice = params['is-paga'];
    }
    if (params['data-emissao-maior-igual']) {
      this.filtro.data = this.filtro.data || {};
      this.filtro.data.emissao = this.filtro.data.emissao || {};
      this.filtro.data.emissao.de = new Date(parseFloat(params['data-emissao-maior-igual'].slice(13, 17)), parseFloat(params['data-emissao-maior-igual'].slice(11, 13)), parseFloat(params['data-emissao-maior-igual'].slice(9, 11)));
    }
    if (params['data-emissao-menor-igual']) {
      this.filtro.data = this.filtro.data || {};
      this.filtro.data.emissao = this.filtro.data.emissao || {};
      this.filtro.data.emissao.ate = new Date(parseFloat(params['data-emissao-menor-igual'].slice(13, 17)), parseFloat(params['data-emissao-menor-igual'].slice(11, 13)), parseFloat(params['data-emissao-menor-igual'].slice(9, 11)));
    }
    if (params['data-pagamento-maior-igual']) {
      this.filtro.data = this.filtro.data || {};
      this.filtro.data.pagamento = this.filtro.data.pagamento || {};
      this.filtro.data.pagamento.de = new Date(parseFloat(params['data-pagamento-maior-igual'].slice(13, 17)), parseFloat(params['data-pagamento-maior-igual'].slice(11, 13)), parseFloat(params['data-pagamento-maior-igual'].slice(9, 11)));
    }
    if (params['data-pagamento-menor-igual']) {
      this.filtro.data = this.filtro.data || {};
      this.filtro.data.pagamento = this.filtro.data.pagamento || {};
      this.filtro.data.pagamento.ate = new Date(parseFloat(params['data-pagamento-menor-igual'].slice(13, 17)), parseFloat(params['data-pagamento-menor-igual'].slice(11, 13)), parseFloat(params['data-pagamento-menor-igual'].slice(9, 11)));
    }
    if (params['data-vencimento-maior-igual']) {
      this.filtro.data = this.filtro.data || {};
      this.filtro.data.vencimento = this.filtro.data.vencimento || {};
      this.filtro.data.vencimento.de = new Date(parseFloat(params['data-vencimento-maior-igual'].slice(13, 17)), parseFloat(params['data-vencimento-maior-igual'].slice(11, 13)), parseFloat(params['data-vencimento-maior-igual'].slice(9, 11)));
    }
    if (params['data-vencimento-menor-igual']) {
      this.filtro.data = this.filtro.data || {};
      this.filtro.data.vencimento = this.filtro.data.vencimento || {};
      this.filtro.data.vencimento.ate = new Date(parseFloat(params['data-vencimento-menor-igual'].slice(13, 17)), parseFloat(params['data-vencimento-menor-igual'].slice(11, 13)), parseFloat(params['data-vencimento-menor-igual'].slice(9, 11)));
    }
    if (params['page']) {
      this.filtro.page = this.filtro.page || 10;
      this.filtro.page = params['page'];
    }
    if (params['q']) {
      this.filtro.query = this.filtro.query || ' ';
      this.filtro.query = params['q'];
    }
    if (params['size']) {
      this.filtro.size = this.filtro.size || 10;
      this.filtro.size = params['size'];
    }
    if (params['order']) {
      this.filtro.order = this.filtro.order || ' ';
      this.filtro.order = params['order'];
    }
    if (params['tipo']) {
      this.filtro.tipo = this.filtro.tipo || ' ';
      this.filtro.tipo = params['tipo'];
    }

    return this.filtro;
  }

}
