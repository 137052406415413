import { Component, Inject, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PessoaCoreEditarComponent } from '@project/src/app/pages/interno/pessoa/pessoa-core-editar/pessoa-core-editar.component';
import { ModalConfirmacaoComponent } from '@components/modal/modal-confirmacao/modal-confirmacao.component';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';
import { DataService } from '@service/data.service';
import { environment } from '@src/environments/environment';
import Swal from 'sweetalert2';
import { IPessoaService, PessoaServiceBuilder } from '@service/pessoa-endpoint.service';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';
import { StringFormatService } from "@project/src/app/service/util/stringFormater.service"
import { ModalConfirmaTokenComponent } from '@project/src/app/components/modal/modal-confirma-token/modal-confirma-token.component';
import { Environment } from '@project/src/environments/environment.default';
import { CheckAuthoritiesService } from '@project/src/app/service/util/checkAuthorities.service';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-pessoa-editar',
  templateUrl: './pessoa-editar.component.html',
  styleUrls: ['./pessoa-editar.component.less']
})
export class PessoaEditarComponent implements OnInit, OnDestroy {
  private service: IPessoaService;
  private dialogToken?: MatDialogRef<ModalConfirmaTokenComponent>;

  public telaConfiguracaoModificada: boolean = false;
  public tokenCorreto: boolean = true;
  public processando: boolean = false;
  public env: Environment = environment;
  public subscription: Subscription;
  public tipoCadastro: string;
  public empresaLogada: any;
  public id_corporacao: any;
  public data: any = {};
  public form: FormGroup;
  public numeroTelefone: string;
  public ehPix: boolean = false;

  public ehColaborador: boolean = false;
  public authEmpresaAtt: boolean = false;
  public ehOperador: boolean = false;

  @ViewChild(PessoaCoreEditarComponent, { static: true })
  public core: PessoaCoreEditarComponent;

  constructor(
    public dialogRef: MatDialogRef<PessoaEditarComponent>,
    public stringFormat: StringFormatService,
    public router: Router,
    private dialog: MatDialog,
    private injector: Injector,
    private autenticacao: AutenticacaoEndpointService,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any
  ) {
    dialogRef.disableClose = true;

    this.tipoCadastro = this.dataDialog.tipo;
    this.id_corporacao = (this.dataDialog.id_corporacao || 0);

    if (this.tipoCadastro && this.tipoCadastro !== 'corporacao') {
      this.service = PessoaServiceBuilder.getInstance(
        this.injector,
        this.tipoCadastro
      );
    }

    if (this.dataDialog.pessoa) {
      this.data = this.dataDialog;
    }

    this.empresaLogada = this.autenticacao.regraEmpresa
      ? this.autenticacao.regraEmpresa.empresa
      : null;

    if (this.dataDialog.tipo != 'contato') {
      if (this.data.configuracao.telefoneSeguranca.length > 0) {
        this.numeroTelefone = this.getUltimosNumeros(this.data.configuracao.telefoneSeguranca[0]);
      }
    }
    const userData = this.autenticacao.sessao;
    const checkAuthorites = new CheckAuthoritiesService(userData);
    this.ehColaborador = checkAuthorites.isColaborador;
    this.authEmpresaAtt = checkAuthorites.permissaoGuard("ROLE_EMPRESA_ATUALIZAR")

    this.ehOperador = checkAuthorites.isOperador;
  }

  public ModificouTelaConfiguracao(pessoaData: any) {
    this.dialogToken = this.dialog.open(ModalConfirmaTokenComponent, {
      data: {
        mensagem: "Para editar as configurações da empresa, é necessário autoriza-la por meio do seu token.",
        estrutura: "configuracaoEmpresa",
        numeroTelefone: this.numeroTelefone,
        celular: "" + this.data.configuracao.telefoneSeguranca[0].ddd + this.data.configuracao.telefoneSeguranca[0].numero,
        onSave: (token) => this.salvar(pessoaData, token),
      },
      height: '45vh',
      backdropClass: 'modal-alerta'
    });
  }

  public getUltimosNumeros(telefone): string {
    if (telefone == undefined) {
      Swal.fire(
        'Cadastro incompleto',
        'Para realizar as operações, por favor verifique o telefone em Empresa-> Configurações -> Autenticação',
        'warning'
      ).then((result) => {
        this.dialogRef.close();
      });
    } else {
      const numero = String(telefone.numero);
      return '(' + telefone.ddd + ')' + ' XXXXX-XX' + numero.substring((numero.length - 2), numero.length);
    }
  }

  ngOnInit() {
    this.form = new FormGroup({});

    if (this.dataDialog && this.dataDialog.tab == "pix") {
      this.ehPix = true;
    }
  }

  ngOnDestroy() { }

  fecha(): void {
    this.dialogRef.close();
  }

  public ehPessoaJuridica(documento): boolean {
    if (documento) {
      const documentoInput = documento.replace(/[^0-9]/g, '');
      if (documentoInput.length <= 11) {
        return false;
      } else {
        return true;
      }
    }
  }

  public async finalizar() {
    const pessoaData: any = await this.core.build();
    if (pessoaData) {
      if (this.dataDialog.tipo != 'contato') {
        if (pessoaData.configuracao.Dirty && !this.autenticacao.sessao.principal.usuario.email.endsWith('@root')
          && !this.ehColaborador
        ) {
          if (this.data.configuracao.telefoneSeguranca.length > 0) {
            this.ModificouTelaConfiguracao(pessoaData);
          } else {
            Swal.fire({
              title: "Warning!",
              text: "Para modificar as configurações, você precisa primeiro adicionar um número de autenticação.",
              icon: "warning"
            })
          }
        } else {
          if (this.data.configuracao.telefoneSeguranca.length > 0 && !this.autenticacao.sessao.principal.usuario.email.endsWith('@root')
            && !this.ehColaborador
          ) {
            this.ModificouTelaConfiguracao(pessoaData);
          } else {
            this.tokenCorreto = true;
            this.salvar(pessoaData);
          }
        }
      } else {
        this.tokenCorreto = true;
        this.salvar(pessoaData);
      }
    } else {
      this.processando = false;
    }
  }

  public salvar(pessoaData: any, token?: any) {
    if (this.processando) return;
    this.processando = true;
    const id = this.dataDialog.id;
    if (token) {
      pessoaData.token = token;
    }

    // Se for cadastro de corporacao:
    if (this.tipoCadastro === 'corporacao') {
      if (!this.dataDialog.pessoa) {  // se não veio um objeto pra edição:
        DataService.data.push(pessoaData); // adiciona no service a nova pessoa
      } else { // se veio:
        // então acha qual o index do objeto que veio e troca pelo novo
        const index = DataService.data.indexOf(this.dataDialog.pessoa);
        DataService.data.splice(index, 1, pessoaData);
      }
      this.dialogRef.close({ salvou: true });
    } else { // Se for pessoa:
      pessoaData.pessoa.contas = pessoaData.pessoa.contas.filter(value => value.isAtivo)
      if (!this.ehPessoaJuridica(pessoaData.pessoa.documento)) {
        if (pessoaData.id > 0) {
          this.service
            .atualizarPessoaFisica(pessoaData)
            .pipe(untilDestroyed(this))
            .subscribe(
              response => {
                this.processando = false;
                if (this.dialogToken) this.dialogToken.close();
                let titulo = 'Atualizada com sucesso!';
                let texto = this.tipoCadastro !== 'contato'
                  ? 'Empresa atualizada com sucesso!'
                  : 'Contato atualizado com sucesso!';
                this.modalFinal(titulo, texto, pessoaData, response);
              },
              error => {
                this.processando = false;
                Swal.fire({
                  title: 'Erro',
                  text: error.error,
                  icon: 'error',
                  confirmButtonText: 'OK'
                });
              }
            );
        } else {
          this.service
            .cadastrarPessoaFisica(pessoaData)
            .pipe(untilDestroyed(this))
            .subscribe(
              response => {
                this.dataDialog.contatoId = response;
                this.processando = false;
                if (this.dialogToken) this.dialogToken.close();
                let titulo = 'Criada com sucesso!';
                let texto = this.tipoCadastro !== 'contato'
                  ? 'Empresa criada com sucesso!'
                  : 'Contato criada com sucesso!';
                this.modalFinal(titulo, texto, pessoaData, response);
              },
              error => {
                this.processando = false;
                Swal.fire({
                  title: 'Erro',
                  text: error.error,
                  icon: 'error',
                  confirmButtonText: 'OK'
                });
              }
            );
        }
      } else {
        if (pessoaData.id > 0) {
          pessoaData.id = id;
          this.service
            .atualizarPessoaJuridica(pessoaData)
            .pipe(untilDestroyed(this))
            .subscribe(
              response => {
                this.processando = false;
                if (this.dialogToken) this.dialogToken.close();
                let titulo = 'Contato atualizado com sucesso!';
                let texto = this.tipoCadastro !== 'contato'
                  ? 'Empresa (pessoa juridica) atualizada com sucesso!'
                  : 'Contato (pessoa juridica) atualizado com sucesso!';
                this.modalFinal(titulo, texto, pessoaData, response);
              },
              error => {
                this.processando = false;
                Swal.fire({
                  title: 'Erro',
                  text: error.error,
                  icon: 'error',
                  confirmButtonText: 'OK'
                });
              }
            );
        } else {
          this.service
            .cadastrarPessoaJuridica(pessoaData)
            .pipe(untilDestroyed(this))
            .subscribe(
              response => {
                this.processando = false;
                if (this.dialogToken) this.dialogToken.close();
                let titulo = 'Contato criado com sucesso!';
                let texto = this.tipoCadastro !== 'contato'
                  ? 'Empresa (pessoa juridica) inserida com sucesso!'
                  : 'Contato (pessoa juridica) inserido com sucesso!';
                this.modalFinal(titulo, texto, pessoaData, response);
              },
              error => {
                this.processando = false;
                Swal.fire({
                  title: 'Erro',
                  text: error.error,
                  icon: 'error',
                  confirmButtonText: 'OK'
                });
              });
        }
      }
    }
  }

  public modalFinal(titulo, texto, pessoaData, response) {
    const dialog = this.dialog.open(ModalConfirmacaoComponent, {
      data: {
        titulo: titulo,
        texto: texto,
        subtexto: `Você poderá usar esse contato para realizar os procedimentos no ${this.env.nomeApp}`,
        btnTexto: 'Criar novo contato',
        whatsapp: false,
        checkout: false
      },
      width: '600px',
      backdropClass: 'modal-alerta'
    });
    dialog.afterClosed().subscribe(result => {
      if (result) {
        if (result.finalizar === true) {
          this.dialogRef.close({
            contatoId: response,
            salvou: true,
            pessoaCadastrada: pessoaData
          });
        } else if (result.nova === true) {
          this.dialogRef.close({
            nova: true
          });
        } else if (result.checkout === true) {
          this.dialogRef.close({
            checkout: true,
            pessoaCadastrada: pessoaData
          });
        }
      }
    });
  }
}
