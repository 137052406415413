import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LiquidacaoManualCobrancaDTO } from '@model/cobranca/liquidacao-manual-cobranca-dto.model';
import { ModeracaoArquivo, ModeracaoEmpresa } from '@model/enum.model';
import { FiltroListaGenericaDTO } from '@model/filtro/filtro-lista-generica-dto.model';
import { FiltroRootUsuarioDTO } from '@model/filtro/filtro-root-usuario-dto.model';
import { PageResponse } from '@model/page-response.model';
import { ArquivoDTO } from '@model/sistema/arquivo-dto.model';
import { UsuarioDTO } from '@model/usuario/usuario-dto.model';
import moment from 'moment';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { EmailDTO } from '../model/pessoa/email-dto.model';
import { EmpresaDTO } from '../model/pessoa/empresa-dto.model';
import { PessoaDTO } from '../model/pessoa/pessoa-dto.model';
import { RepresentanteEmpresaDTO } from '../model/pessoa/representante-empresa-dto.model';
import { TelefoneDTO } from '../model/pessoa/telefone-dto.model';
import { FiltroListaLogsDTO } from '../model/filtro/filtro-list-logs-dto.model';

@Injectable({
  providedIn: 'root',
})
export class RootEndpointService {
  // [x: string]: any;
  constructor(private client: HttpClient) {}

  liquidarBoleto(arquivoData): Observable<any> {
    const url = '/root/liquidar-boletos';
    return this.client.post(url, arquivoData);
  }

  liquidarTransferencia(arquivoData): Observable<any> {
    const url = '/root/transferencia/liquidar-transferencias';
    return this.client.post(url, arquivoData);
  }

  getUsuarioByEmail(email: string) {
    let params = new HttpParams();
    params = params.append('q', `${email}`);
    const url = `/root/usuario/findByEmail`;
    return this.client.get<UsuarioDTO>(url, { params });
  }

  liquidar(arquivo: LiquidacaoManualCobrancaDTO): Observable<any> {
    if (moment.isDate(arquivo.dataPagamento)) {
      arquivo.dataPagamento = arquivo.dataPagamento.toISOString();
    }
    return this.client.post('/root/cobranca/liquidar', arquivo);
  }

  dashboard(): Observable<any> {
    const url = '/root/dashboard';
    return this.client.get(url);
  }

  getAllEmpresas(page?: number, size?: number, filter?: ModeracaoEmpresa, order?: String, query?: string): Observable<any> {

    let params = new HttpParams();
    params = params.append('page', `${page}`);

    if (size) {
      params = params.append('size', `${size}`);
    }

    if (filter) {
      params = params.append('filter', `${filter}`);
    }

    if (query) {
      params = params.append('query', `${query}`);
    }

    if (order) {
      params = params.append('order', `${order}`);
    }


    const url = '/root/empresa/listar';
    return this.client.get(url, { params });
  }

  // id, aceite e descrição
  moderarEmpresas(gerenciarData): Observable<any> {
    const url = '/root/empresa/gerenciar';
    return this.client.post(url, gerenciarData);
  }

  empresaDesativar(desativarData): Observable<any> {
    const url = '/root/empresa/bloquear';
    return this.client.post(url, desativarData);
  }

  empresaReativar(reativarData): Observable<any> {
    const url = '/root/empresa/reativar';
    return this.client.post(url, reativarData);
  }

  criarDocumentoSistema(documento): Observable<any> {
    const url = '/root/criar-documento-sistema';
    return this.client.post(url, documento);
  }

  atualizarDocumentoSistema(documento): Observable<any> {
    const url = '/root/atualizar-documento-sistema';
    return this.client.patch(url, documento);
  }

  // se passar o id retorna todas as cobranças da empresa
  // se não passar nada retorna todas as cobranças geradas sem filtro
  getAllCobrancas(empresaId?): Observable<any> {
    const url = empresaId
      ? '/root/cobranca/lista-boletos?idEmpresa=' + empresaId
      : '/root/cobranca/lista-boletos';
    return this.client.get(url);
  }

  getCobrancasAntecipaveis(filtro): Observable<any> {
    let params = new HttpParams();
    params = params.append('idEmpresa', filtro.empresaId);
    params = params.append('page', filtro.page);
    params = params.append('size', filtro.size);
    params = params.append('q', filtro.q);

    if (filtro.data.vencimento && filtro.data.vencimento.show) {
      if (filtro.data.vencimento.de) {
        params = params.append(
          'dataVencimentoInicial',
          `${moment(filtro.data.vencimento.de).format('DDMMYYYY')}`
        );
      }
      if (filtro.data.vencimento.ate) {
        params = params.append(
          'dataVencimentoFinal',
          `${moment(filtro.data.vencimento.ate).format('DDMMYYYY')}`
        );
      }
    }

    if (filtro.query) {
      params = params.append('q', `${filtro.query}`);
    }

    const url = '/root/antecipacao/lista-cobrancas-antecipaveis';
    return this.client.get(url, { params });
  }

  atualizaCobrancasAntecipaveis(empresaId, atualizadasData): Observable<any> {
    const url = '/root/antecipacao/atualizar-antecipaveis/' + empresaId;
    return this.client.post(url, atualizadasData);
  }

  gerenciarAntecipacao(gerenciarData): Observable<any> {
    const url = '/root/antecipacao/gerenciar';
    return this.client.post(url, gerenciarData);
  }

  // filter pode ser aprovado, aguardandoModeracao, reprovado, se não passar retorna todos
  getAllUsuarios(filtro: FiltroRootUsuarioDTO) {
    filtro = filtro || {};
    let params = new HttpParams();
    if (filtro.desativado) {
      params = params.append('desativado', `${filtro.desativado}`);
    }
    if (filtro.order) {
      params = params.append('order', `${filtro.order}`);
    }

    params = params.append('page', `${filtro.page}`);

    if (filtro.query) {
      params = params.append('q', `${filtro.query}`);
    }
    if (filtro.size) {
      params = params.append('size', `${filtro.size}`);
    }
    let moderacao = filtro.moderacao;
    const url = `/root/usuario/listar/${moderacao}`;
    return this.client.get<PageResponse<UsuarioDTO>>(url, { params });
  }

  // array de objeto contendo id: number e aceite: true/false
  moderarUsuarios(gerenciarData): Observable<any> {
    const url = '/root/usuario/gerenciar';
    return this.client.post(url, gerenciarData);
  }

  alternarUsuario(gerenciarUsuario): Observable<any> {
    const url = `/root/usuario/alternar`;
    return this.client.post(url, gerenciarUsuario);
  }

  getConfiguracoesSistema(): Observable<any> {
    const url = '/root/configuracoes-sistema';
    return this.client.get(url);
  }

  getFixaSidebar(): Observable<any> {
    const url = '/root/fixa-sidebar';
    return this.client.get(url);
  }
  updateConfiguracoesSistema(config): Observable<any> {
    const url = '/root/atualizar-configuracoes-sistema';
    return this.client.patch(url, config);
  }

  cardTotal(): Observable<any> {
    const url = '/root/dashboard/card-total-saldos';
    return this.client.get(url);
  }

  saldoDiscriminado(): Observable<any> {
    const url = '/root/dashboard/saldo-discrimidado-empresa';
    return this.client.get(url);
  }

  cardReceitas(dataInicial, dataFinal): Observable<any> {
    let params = new HttpParams();

    if (dataInicial) {
      params = params.append('i', dataInicial);
    }
    if (dataFinal) {
      params = params.append('f', dataFinal);
    }
    const url = '/root/dashboard/card-receitas';
    return this.client.get(url, { params });
  }

  cardContatos(dataInicial, dataFinal): Observable<any> {
    let params = new HttpParams();

    if (dataInicial) {
      params = params.append('i', dataInicial);
    }
    if (dataFinal) {
      params = params.append('f', dataFinal);
    }
    const url = '/root/dashboard/card-contatos';
    return this.client.get(url, { params });
  }

  getListaLogs(filtro: FiltroListaLogsDTO): Observable<any> {
    let params = new HttpParams();
    params = params.append('page', filtro.page ? `${filtro.page}` : '0');
    params = params.append('size', filtro.size ? `${filtro.size}` : '10');
    if (filtro.i) {
      params = params.append('i', `${moment(filtro.i).format('DDMMYYYY')}`);
    }
    if (filtro.f) {
      params = params.append('f', `${moment(filtro.f).format('DDMMYYYY')}`);
    }
    if (filtro.user) {
      params = params.append('user', filtro.user);
    }
    if (filtro.logMessage) {
      params = params.append('logMessage', filtro.logMessage);
    }
    if (filtro.empresaId) {
      params = params.append('empresaId', `${filtro.empresaId}`);
    }
    if (filtro.statusCode) {
      params = params.append('statusCode', `${filtro.statusCode}`);
    }
    const url = '/root/lista-logs';
    return this.client.get(url, { params });
  }

  cardFaturas(dataInicial, dataFinal): Observable<any> {
    let params = new HttpParams();

    if (dataInicial) {
      params = params.append('i', dataInicial);
    }
    if (dataFinal) {
      params = params.append('f', dataFinal);
    }
    const url = '/root/dashboard/card-faturas';
    return this.client.get(url, { params });
  }

  cardFaturasFiltradas(dataInicial, dataFinal): Observable<any> {
    let params = new HttpParams();

    if (dataInicial) {
      params = params.append('i', dataInicial);
    }
    if (dataFinal) {
      params = params.append('f', dataFinal);
    }
    const url = '/root/dashboard/card-faturas-filtradas';
    return this.client.get(url, { params });
  }

  cardAtencipacoes(dataInicial, dataFinal): Observable<any> {
    let params = new HttpParams();

    if (dataInicial) {
      params = params.append('i', dataInicial);
    }
    if (dataFinal) {
      params = params.append('f', dataFinal);
    }
    const url = '/root/dashboard/card-antecipacoes';
    return this.client.get(url, { params });
  }

  cardTransferencias(dataInicial, dataFinal): Observable<any> {
    let params = new HttpParams();

    if (dataInicial) {
      params = params.append('i', dataInicial);
    }
    if (dataFinal) {
      params = params.append('f', dataFinal);
    }
    const url = '/root/dashboard/card-transferencias';
    return this.client.get(url, { params });
  }

  cardPagamentoDeContas(dataInicial, dataFinal): Observable<any> {
    let params = new HttpParams();

    if (dataInicial) {
      params = params.append('i', dataInicial);
    }
    if (dataFinal) {
      params = params.append('f', dataFinal);
    }
    const url = '/root/dashboard/card-pagamento-contas';
    return this.client.get(url, { params });
  }

  conciliarTitulosAbertosBanco(arquivo: ArquivoDTO): Observable<any> {
    const url = '/root/cobranca/conciliar-titulos-abertos-banco';
    return this.client.post<string>(url, arquivo);
  }

  obterHorarioLimite(query: any): Observable<any> {
    const url = '/root/horario-limite';
    let params = new HttpParams();
    if (query) {
      params = params.append('query', query);
    }
    return this.client.get<string>(url, { params });
  }

  buscarTiposContas(documento: string): Observable<any> {
    let params = new HttpParams();

    if (documento) {
      params = params.append('documento', documento);
    }

    const url = '/root/empresa/buscarTiposContas';
    return this.client.get<any>(url, { params });
  }

  salvarCriarContas(moderarDTO): Observable<any> {
    const url = '/root/empresa/criar-contas-empresa';
    return this.client.post<any>(url, moderarDTO);
  }

  getAllExportacoes(filtro): Observable<any> {
    let params = new HttpParams();
    if (filtro.page >= 0) {
      params = params.append('page', `${filtro.page}`);
    }
    if (filtro.size >= 0) {
      params = params.append('size', `${filtro.size}`);
    }
    if (filtro.order) {
      params = params.append('order', `${filtro.order}`);
    }
    if (filtro.data.exportacao) {
      if (filtro.data.exportacao.de) {
        params = params.append(
          'i',
          `${moment(filtro.data.exportacao.de).format('DDMMYYYY')}`
        );
      }
      if (filtro.data.exportacao.ate) {
        params = params.append(
          'f',
          `${moment(filtro.data.exportacao.ate).format('DDMMYYYY')}`
        );
      }
    }
    const url = '/root/exportacoes';
    return this.client.get<any>(url, { params });
  }

  getUsuariosEmpresa(empresaId): Observable<any> {
    const url = `/root/empresa/getUsuariosEmpresa/${empresaId}`;
    return this.client.get<any>(url);
  }

  converteDocumentoHtmlToPdf(id): Observable<any> {
    const url = `/root/converte-documento-html-pdf/${id}`;
    return this.client.get<any>(url);
  }

  verificaFeriadoDiaUtil(query?: any): Observable<any> {
    const url = '/root/verifica-final-semana-feriado';
    return this.client.get<boolean>(url);
  }

  informacoesSistemaDiaria(filtro?): Observable<any> {
    if (filtro) {
      let params = new HttpParams();
      if (filtro.page >= 0) {
        params = params.append('page', `${filtro.page}`);
      }
      if (filtro.size >= 0) {
        params = params.append('size', `${filtro.size}`);
      }
      if (filtro.order) {
        params = params.append('order', `${filtro.order}`);
      }
      const url = '/root/informacao-diaria-sistema';
      return this.client.get<any>(url, { params });
    }
    const url = '/root/informacao-diaria-sistema';
    return this.client.get<any>(url);
  }

  criarSignatario(signer) {
    const url = '/root/criar-signatario';
    return this.client.post(url, signer);
  }

  excluirSignatario(signerId) {
    const url = '/root/excluir-signatario';
    return this.client.post(url, signerId);
  }

  listarSignatario(filtro): Observable<any> {
    let params = new HttpParams();
    if (filtro.size >= 0) {
      params = params.append('size', `${filtro.size}`);
    }
    if (filtro.page >= 0) {
      params = params.append('page', `${filtro.page}`);
    }
    const url = '/root/lista-signatario';
    return this.client.get<any>(url, { params });
  }

  removerSignatario(info) {
    const url = '/root/remover-signatario';
    return this.client.post(url, info);
  }

  finalizarDocumento(info) {
    const url = '/root/finalizar-documento';
    return this.client.get(url, info);
  }

  salvarAuths(auths, docId): Observable<any> {
    const url = '/root/salvar-auths/' + `${docId}`;
    return this.client.patch<any>(url, auths);
  }

  vincularSignatarioDocumento(signerId, docId) {
    const url =
      '/root/vincular-signatario-documento/' + `${signerId}` + '/' + `${docId}`;
    return this.client.post<any>(url, null);
  }

  getVinculoSignatarioDocumento(signerId): Observable<any> {
    const url = '/root/get-vinculo-signatario-documento/' + `${signerId}`;
    return this.client.get<any>(url);
  }

  getSigner(id): Observable<any> {
    const url = '/root/get-signer/' + `${id}`;
    return this.client.get<any>(url);
  }

  public gerenciarModeracao(
    classe: 'usuário' | 'empresa' | 'representante',
    objeto: any,
    form: FormGroup,
    empresaAuxiliar?: EmpresaDTO
  ) {
    const url = '/root/gerenciar-moderacao';
    let numero: string = '';
    switch (classe) {
      case 'usuário':
        const user: UsuarioDTO = objeto as UsuarioDTO;
        let userNovo: UsuarioDTO = form.value as UsuarioDTO;

        numero = form.get('celular').value.replace(/[ ().-]/g, '');
        userNovo.celular = {
          principal: true,
          tipo: 'celular',
          ddd: +numero.substring(0, 2),
          numero: +numero.substring(2, numero.length),
          ramal: 1,
          pais: '+55',
        };
        userNovo.endereco = userNovo.endereco[0];
        userNovo.cpf = userNovo.cpf.replace(/[^0-9]/g, '');

        return this.client.post(url, {
          classe,
          user,
          userNovo,
          empresa: empresaAuxiliar,
        });
      case 'empresa':
        const empresa: EmpresaDTO = objeto as EmpresaDTO;

        //Separa empresa.pessoa empresa.faturamento
        const faturamento: string = form.get('faturamento').value;
        form.get('faturamento').disable();

        //formata telefone
        numero = form.get('telefone.numero').value.replace(/[ ().-]/g, '');
        let telefone: TelefoneDTO = {
          principal: true,
          tipo: form.get('telefone.tipo').value,
          ddd: +numero.substring(0, 2),
          numero: +numero.substring(2, numero.length),
          ramal: form.get('telefone.ramal').value,
          pais: '+55',
        };
        const telefones: TelefoneDTO[] = [telefone];
        form.get('telefone').disable();

        //formata email
        let email: EmailDTO = {
          email: form.get('email').value,
          principal: true,
        };
        const emails: EmailDTO[] = [email];
        form.get('email').disable();

        //controi empresa.pessoa
        const pessoa: PessoaDTO = form.value as PessoaDTO;
        pessoa.telefones = telefones;
        pessoa.emails = emails;
        pessoa.documento = pessoa.documento.replace(/[^0-9]/g, '');

        //nova empresa é pessoa + faturamento + tipoEmpresa
        let empresaNovo: EmpresaDTO = {} as EmpresaDTO;
        empresaNovo.faturamento = faturamento;
        empresaNovo.pessoa = pessoa;
        empresaNovo.tipoEmpresa = empresa.tipoEmpresa;
        empresaNovo.codigoIdentificacaoCnab = form.get(
          'codigoIdentificacaoCnab'
        ).value;

        return this.client.post(url, { classe, empresa, empresaNovo });
      case 'representante':
        const representante: RepresentanteEmpresaDTO =
          objeto as RepresentanteEmpresaDTO;
        const representanteNovo: RepresentanteEmpresaDTO =
          form.value as RepresentanteEmpresaDTO;
        representanteNovo.documento = representanteNovo.documento.replace(
          /[^0-9]/g,
          ''
        );
        representanteNovo.telefone = representanteNovo.telefone.replace(
          /[^0-9]/g,
          ''
        );
        return this.client.post(url, {
          classe,
          empresa: empresaAuxiliar,
          representante,
          representanteNovo,
        });
      default:
        Swal.fire('error!', 'erro na construção da requisição.', 'error');
    }
  }

  getAllEmpresasIaas(
    filter?: ModeracaoEmpresa,
    page?: number,
    size?: number
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('page', `${page}`);

    if (size) {
      params = params.append('size', `${size}`);
    }

    if (filter) {
      params = params.append('filter', `${filter}`);
    }

    const url = '/root/empresa/listar-iaas';
    return this.client.get(url, { params });
  }

  getEmpresasModeracaoFundos(fundoId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('fundoId', `${fundoId}`);

    const url = '/root/empresa/listar-moderacao-fundos';
    return this.client.get(url, { params });
  }

  moderarInvestidorFundo(moderar) {
    const url = '/root/empresa/moderar-investidor-fundo';
    return this.client.post(url, moderar);
  }

  moderarInvestidor(moderar) {
    const url = '/root/empresa/moderar-investidor';
    return this.client.post(url, moderar);
  }

  getAllEmpresasColaborador(): Observable<any> {
    const url = '/empresa/getAll-empresas';
    return this.client.get(url);
  }

  listarTodasEmpresas(): Observable<any> {
    const url = '/empresa/getAll-empresas';
    return this.client.get(url);
  }

  getNumeroTipoConta(pessoaId?: any): Observable<any> {
    const url = `/root/empresa/getNumeroTipoConta`;
    let params = new HttpParams();

    params = params.append('pessoaId', `${pessoaId}`);

    return this.client.get(url, { params });
  }

  getCarrossel(): Observable<any> {
    const url = '/carrossel/listar';
    return this.client.get(url);
  }

  atualziaCarrossel(tipo): Observable<any> {
    const url = '/carrossel/atualizar?tipo=' + tipo;
    return this.client.post(url, tipo);
  }

  adicionarColaborador(
    nroColaborador: string,
    usuarioId: number,
    tipoColaborador: string
  ): Observable<any> {
    const url = '/root/usuario/adicionar-colaborador';
    let params = new HttpParams();
    params = params.append('nroColaborador', `${nroColaborador}`);
    params = params.append('usuarioId', `${usuarioId}`);
    params = params.append('tipoColaborador', `${tipoColaborador}`);
    return this.client.post(url, null, { params });
  }

  removerVinculoColaborador(
    nroColaborador: string,
    usuarioId: number,
    tipoColaborador: string
  ): Observable<any> {
    const url = '/root/usuario/remover-vinculo-colaborador';
    let params = new HttpParams();
    params = params.append('nroColaborador', `${nroColaborador}`);
    params = params.append('usuarioId', `${usuarioId}`);
    params = params.append('tipoColaborador', `${tipoColaborador}`);
    return this.client.post(url, null, { params });
  }

  salvarErrosTeams(erros, api?): Observable<any> {
    const url = '/teams-erros/criar?api=' + api;
    return this.client.post(url, erros);
  }

  getUrl(): Observable<any> {
    const url = '/root/urls-login';

    return this.client.get(url);
  }

  getAjudas(): Observable<any> {
    const url = '/root/urls-ajuda';

    return this.client.get(url);
  }

  atualizarHorarioCadastro(horarioInicial, horarioFinal): Observable<any> {
    const url =
      '/root/atualizar-horario-cadastro?horarioInicial=' +
      horarioInicial +
      '&horarioFinal=' +
      horarioFinal;
    return this.client.post(url, {});
  }

  getHorarioCadastro(): Observable<any> {
    const url = '/root/horario-cadastro';
    return this.client.get(url);
  }

  getBloqueioNovosCadastros(): Observable<any> {
    const url = '/root/bloqueio-novos-cadastros';
    return this.client.get(url);
  }

  getOcultarBotaoCadastro(): Observable<any> {
    const url = '/root/ocultar-botao-cadastro';
    return this.client.get(url);
  }

  planilhaTiposContas(moderacao: string, email: string): Observable<any> {
    const url = '/root/planilha-tipos-contas';
    let params = new HttpParams();
    if (moderacao)
      params = params.append("moderacao", `${moderacao}`)

    if (email)
      params = params.append("email", `${email}`)

    return this.client.get(url, { params });
  }

  planilhaResumoTaxas(empresaId: number, filtro: string): Observable<any> {
    const url = '/root/planilha-resumo-taxas';
    let params = new HttpParams();
  
    params = params.append("empresaId", `${empresaId}`)
    params = params.append("filtro", `${filtro}`)
    
    return this.client.get(url, { params });
  }
}
