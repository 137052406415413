<button class="btn-close" (click)="fechar()">
    <i class="material-icons">close</i>
</button>

<h2 mat-dialog-title class="corPrimaria" style=" font-weight: 600; text-align: center; margin: 0;">Liquidar
    {{ dataDialog.tipo === 'boleto' ? 'Boletos' : 'Transferencias' }}</h2>

<div mat-dialog-content class="mat-dialog-content-responsive">
    <div class="box-body">
        <form [formGroup]="form" style="text-align: center;">
            <div class="fileinput fileinput-new text-center" data-provides="fileinput" text-center>
                <div class="fileinput-new thumbnail">
                    <img *ngIf="arquivo" [src]="arquivo" alt="..." />
                </div>
                <div class="fileinput-preview fileinput-exists thumbnail"></div>
                <div>
                    <span class="btn btn-primary btn-file" style="background-color: #1400c8;">
                        <span class="fileinput-new">Fazer upload do arquivo</span>
                        <span class="fileinput-exists">Trocar</span>
                        <input type="file" name="..." formControlName="arquivoPath" (change)="onSelectFile($event)" />
                    </span>
                    <a href="#pablo" class="btn btn-danger fileinput-exists" (click)="uploadNull()"
                        data-dismiss="fileinput"><i class="fas fa-times"></i> Remover</a>
                </div>
            </div>
        </form>
    </div>

</div> <!-- fim_dialog-content -->
<div mat-dialog-actions class="espacamento">
    <div class="col-12 col-md-4">
        <button type="button" (click)="fechar()" class="btn btn-block" mat-raised-button>
            Cancelar
        </button>
    </div>

    <div class="col-12 col-md-4">
        <button type="button" (click)="enviar()" class="btn btn-block fundoCorPrimaria" 
            mat-raised-button>
            Enviar
        </button>
    </div>
</div> <!-- fim_dialog-actions -->