<div>
  <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
    PUBLICIDADE
    <i (click)="fecha()" class="material-icons btn-fechar" id="btn-close">close</i>
    <span id="fechar" (click)="fecha()">FECHAR</span>
  </h2>
  <div mat-dialog-content class="mat-dialog-content-responsive">
    <div mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
      <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <div class="mudaCor input-size row "
        style="background-color: white; margin: 0px 10px; border-radius: 5px;padding: 28px 0px; height: 27vw;">
        <div class="card-body">
          <div class="publicidade-content">
            <div class="publicidade-card">
              <div fxFlex="100" style="text-align: center;">
                <div>
                  <ngb-carousel (slide)="slideChanged($event)">
                    <ng-template ngbSlide *ngFor="let item of listaPublicidade, let i = index" [id]="item.id">
                      <div style="display:flex; justify-content: center;">
                        <a href="{{ item?.href }}" target="_blank">
                          <img style="max-width: 100%; width: 744px;" src="data:image/png;base64,{{ item?.base64 }}">
                        </a>
                      </div>
                    </ng-template>
                  </ngb-carousel>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons-ajusta">
            <button class="btn btn-default next" id="color-remove" mat-raised-button (click)="editaImagem()"
              style="width: 10vw">
              Editar
            </button>
            <button class="btn btn-default next" id="color-btn" mat-raised-button (click)="abreCropper()"
              style="width: 10vw">
              Adicionar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>