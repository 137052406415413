<form [formGroup]="form">
  <div class="row alinha" *ngFor="let endereco of form.controls; index as i" [formGroup]="endereco">

    <div class="col-8" *ngIf="multiplosEnderecos == true">
      <h4 class="titulo">ENDEREÇO {{ i+1 }} </h4>
    </div>
    <div class="col-2" style="text-align: end;" *ngIf="multiplosEnderecos == true">
      <i class="fas fa-trash icones"
        [title]="!endereco.get('principal').value ? 'Excluir' : 'Não é possivel excluir o endereço principal'"
        (click)="!endereco.get('principal').value && contador > 1 ? del(i) : null"
        [style.color]="!endereco.get('principal').value && contador > 1 ? 'red' : 'grey'" style="padding-top: 12px"></i>
    </div>
    <div class="col-2" style="margin-top: 14px; text-align: end;" *ngIf="multiplosEnderecos == true">
      <mat-slide-toggle formControlName="principal" (change)="changeContaPrincipal($event)">Principal
      </mat-slide-toggle>
      <mat-error style="font-size: 12px;">{{ getErrorMessage(endereco, "principal") }}</mat-error>
    </div>

    <div [hidden]="!processando" class="col-12" style="padding: 5px 5px 10px 5px">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div class="col-5 col-md-5">
      <mat-form-field appearance="outline">
        <mat-label>CEP</mat-label>
        <input matInput formControlName="cep" (focusout)="buscaEndereco(endereco)"
          mask="00000-000" required />
        <mat-error>{{ getErrorMessage(endereco, "cep") }}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-1 col-md-1" style="padding-top: 11px;">
      <i class="fas fa-search search-icon" aria-hidden="true"></i>
    </div>
    <div class="col-6 col-md-6">
      <mat-form-field appearance="outline">
        <mat-label>Endereço</mat-label>
        <input matInput type="text" formControlName="logradouro" required />
        <mat-error>{{ getErrorMessage(endereco, "logradouro") }}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-5 col-md-5">
      <mat-form-field appearance="outline">
        <mat-label>Bairro</mat-label>
        <input matInput type="text" formControlName="bairro" required />
        <mat-error>{{ getErrorMessage(endereco, "bairro") }}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-3 col-md-3">
      <mat-form-field appearance="outline">
        <mat-label>Número</mat-label>
        <input matInput type="text" formControlName="numero" required />
        <mat-error>{{ getErrorMessage(endereco, "numero") }}</mat-error>
      </mat-form-field>

    </div>
    <div class="col-4 col-md-4">
      <mat-form-field appearance="outline">
        <mat-label>Complemento</mat-label>
        <input matInput type="text" formControlName="complemento" />
        <mat-error>{{ getErrorMessage(endereco, "complemento") }}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-3 col-md-3">
      <mat-form-field appearance="outline">
        <mat-label>Estado</mat-label>
        <mat-select formControlName="estado" required>
          <mat-option *ngFor="let estado of estados" [value]="estado">
            {{estado}}
          </mat-option>
        </mat-select>
        <mat-error>{{ getErrorMessage(endereco, "estado") }}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-5 col-md-5">
      <mat-form-field appearance="outline">
        <mat-label>Cidade</mat-label>
        <input matInput type="text" formControlName="cidade" required />
        <mat-error>{{ getErrorMessage(endereco, "cidade") }}</mat-error>
      </mat-form-field>
    </div>
    <!-- <div class="col-4 col-md-4">
      <mat-form-field appearance="outline">
        <mat-label>País</mat-label>
        <input matInput type="text" formControlName="pais" />
        <mat-error>{{ getErrorMessage(endereco, "pais") }}</mat-error>
      </mat-form-field>
    </div> -->

  </div>
</form>

<div *ngIf="3 >= contador && multiplosEnderecos == true" class="row alinha">
  <div class="col-6 col-md-6 add">
    <span (click)="add()" style="padding-left: 10px;">+ adicionar novo endereço</span>
  </div>
</div>