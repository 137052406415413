import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AutenticacaoEndpointService } from "../autenticacao-endpoint.service";
import { CheckAuthoritiesService } from "../util/checkAuthorities.service";

@Injectable({
  providedIn: "root",
})
export class acessoContaAprovadaService implements CanActivate {
  constructor(private autenticacao: AutenticacaoEndpointService) { }

  canActivate(route: ActivatedRouteSnapshot):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.autenticacao.regraEmpresa) {
      const checkAuthorities = new CheckAuthoritiesService(this.autenticacao.sessao);
      return this.autenticacao.regraEmpresa.empresa.moderacaoEmpresa === 'aprovada' || checkAuthorities.isAdministrador;
    } else {
      return false;
    }
  }
}