import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UsuarioDTO } from '../model/usuario/usuario-dto.model';
import { EstruturaToken, TipoToken } from '../model/enum.model';


@Injectable({
  providedIn: 'root'
})
export class UsuarioEndpointService {
  constructor(private client: HttpClient) {}

  cadastrarUsuario(userData): Observable<any> {
    const url = '/usuario';
    return this.client.post(url, userData);
  }

  cadastrarUsuarioOperador(userData): Observable<any> {
    const url = '/usuario/usuario-operador';
    return this.client.post(url, userData);
  }

  reportarProblema(userData): Observable<any> {
    const url = '/usuario/reportar-problema';
    return this.client.post(url, userData);
  }

  obterUsuarioLogado(): Observable<any> {
    const url = '/usuario';
    return this.client.get(url);
  }

  atualizarUsuario(userData): Observable<any> {
    const url = '/usuario/atualizar';
    return this.client.patch(url, userData);
  }

  apagarUsuario(idUsuario): Observable<any> {
    const url = '/usuario/apagar/' + idUsuario;
    return this.client.delete(url);
  }

  alterarVisao(corporacaoId, empresaId?): Observable<any> {
    // alterar-visao?corporacaoId={123}[&empresaId{123}]
    const urlCorp = '/usuario/alterar-visao?corporacaoId=' + corporacaoId;
    const urlEmp = empresaId ? '&empresaId=' + empresaId : '';
    const url = urlCorp + '' + urlEmp;
    return this.client.get(url);
  }

  getUsuarioByEmail(email): Observable<any> {
    const url = '/usuario/email/' + email ;
    return this.client.get(url);
  }

  usuarioPossuiCorporacao() {
    const url = '/usuario/usuario-possui-corporacao';
    return this.client.get(url);
  }

  solicitarRecuperarSenha(email: string, celular: string, token: string): Observable<any> {
    const url = '/usuario/recuperar-senha';
    let params = new HttpParams();
    params = params.append('email', email)
    params = params.append('celular', celular)
    params = params.append('token', token)
    return this.client.get(url, { params });
  }

  recuperarSenha(data): Observable<any> {
    const url = '/usuario/confirmar-recuperacao-senha';
    return this.client.patch(url, data);
  }

  alterarSenha(senhaAntiga, novaSenha): Observable<any> {
    const url = '/usuario/alterar-senha';
    return this.client.patch(url, { senhaAntiga, novaSenha });
  }

  reenviarCodigoEmail(emailData): Observable<any> {
    const url = '/usuario/reenviar-codigo-email';
    return this.client.patch(url, emailData);
  }

  reenviarCodigoCelular(celularData): Observable<any> {
    const url = '/usuario/reenviar-codigo-celular';
    return this.client.patch(url, celularData);
  }

  confirmaEmailCelular(codigoEmail, codigoCelular): Observable<any> {
    const url = '/usuario/confirma-codigos-cadastro';
    return this.client.patch(url, { codigoEmail, codigoCelular });
  }

  cadastrarPerguntasSeguranca(data): Observable<any> {
    const url = '/usuario/perguntas-seguranca';
    return this.client.post(url, data);
  }

  listarPerguntasSeguranca(usuarioId): Observable<any> {
    const url = `/usuario/perguntas-seguranca/${usuarioId}`;
    return this.client.get(url);
  }

  convitesRecebidos(): Observable<any> {
    const url = '/usuario/convites-recebidos';
    return this.client.get(url);
  }

  convitesEnviados(): Observable<any> {
    const url = '/usuario/convites-enviados';
    return this.client.get(url);
  }

  getRegraCorporacaoLogada(): Observable<any> {
    const url = '/usuario/corporacao-logada';
    return this.client.get(url);
  }

  getRegraEmpresaLogada(): Observable<any> {
    const url = '/usuario/empresa-logada';
    return this.client.get(url);
  }

  getCorporacoesDoUsuario(query?: string): Observable<any> {
    const url = '/usuario/corporacoes';
    let params = new HttpParams();
    if (query) { params = params.append('query', `${query}`); }
    return this.client.get(url, { params });
  }

  solicitarToken(estrutura: EstruturaToken, tipo: TipoToken, celular?: string) {
    const url = '/usuario/gerar-token';
    let params = new HttpParams();
    if (celular) {
      params = params.append('telefone', `${celular}`);
    }
    return this.client.post(url, {estrutura, tipo}, { params });
  }

  existCpf(cpf) {
    let params = new HttpParams();
    params = params.append('cpf', `${cpf}`);
    const url = '/usuario/verificar-cpf';
    return this.client.get<Boolean>(url, {params});
  }

  public validaTokenGenerico(token: any): Observable<any> {
    const url = '/usuario/valida-token';
    return this.client.post(url, token);
  }

  getUsuarioById(idUsuario: number) {
    const url = `/usuario/${idUsuario}`;
    return this.client.get<UsuarioDTO>(url);
  }

  getUsuariosRoot(): Observable<any> {
    const url = `/usuario/usuarios-root`;
    return this.client.get<any>(url);
  }

  getUsuarioAdministradorByEmpresaId(empresaId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('empresaId', `${empresaId}`);
    const url = '/usuario/usuario-administrador/';
    return this.client.get<any>(url, {params});
  }

  atualizarMultifator(usuarioId: number, ativado?: boolean, validado?: boolean, authenticatorKey?: string) {
    let params = new HttpParams();
    params = params.append('usuarioId', `${usuarioId}`);
    if (ativado != null) { params = params.append('ativado', `${ativado}`); }
    if (validado != null) { params = params.append('validado', `${validado}`); }
    if (authenticatorKey) { params = params.append('authenticatorKey', authenticatorKey); }
    const url = '/usuario/atualizar-multifator';
    return this.client.patch(url, null, { params });
  }

  getApiToken(usuarioId, empresaId){
    const url = '/usuario/get-api-token'
    let params = new HttpParams();
    params = params.append("usuarioId",`${usuarioId}`)
    params = params.append("empresaId",`${empresaId}`)

    return this.client.get<any>(url, { params })
  }
}
