import { PERMISSOES_ROOT } from "../../pages/interno/root/usuarios/convidar-usuario/permissoes.service";

export class CheckAuthoritiesService {
  userData: any;
  constructor(
    userData: any
  ) {
    this.userData = userData;
  }
  get isRoot() {
    if (this.userData?.name === "root@root") {
      return true
    } else {
      return false;
    }
  }

  get isColaborador() {
    return this.userData?.principal.authorities.filter(
      (item) =>
        item.permissao === "ROLE_COLABORADOR" &&
        item.authority === "ROLE_COLABORADOR"
    ).length > 0;
  }

  get isAdministrador() {
    return this.userData?.principal.authorities.filter(
      (item) =>
        item.permissao === "ROLE_ADMINISTRADOR" &&
        item.authority === "ROLE_ADMINISTRADOR"
    ).length > 0;
  }

  get isProprietario() {
    return this.userData?.principal.authorities.filter(
      (item) =>
        item.permissao === "ROLE_PROPRIETARIO" &&
        item.authority === "ROLE_PROPRIETARIO"
    ).length > 0;
  }

  get isOperador() {
    return this.userData?.principal.authorities.filter(
      (item) =>
        item.permissao === "ROLE_OPERADOR" &&
        item.authority === "ROLE_OPERADOR"
    ).length > 0;
  }

  permissaoGuard(role: string): boolean {
    if (this.isAdministrador || this.isProprietario || this.isRoot) {
      return true;
    }
    if (
      this.userData?.authorities.filter(
        (item) =>
          item.permissao === role &&
          item.authority === role
      ).length > 0
    ) {
      return true;
    } else return false;
  }

  permissaoToken(): boolean {
    if (
      this.userData.authorities.filter(
        (item) =>
          item.permissao === 'ROLE_TOKEN_EMAIL' &&
          item.authority === 'ROLE_TOKEN_EMAIL'
      ).length > 0
    ) {
      return true;
    } else return false;
  }


  possuiPermissaoRoot() {
    const roles = [
      ...PERMISSOES_ROOT,
      "ROLE_MODERAR_COLABORADOR_ROOT"
    ];

    let permitido = false;
    this.userData.authorities.forEach((regra) => {
      if (roles.includes(regra.permissao)) permitido = true;
    });
    return permitido;
  }
} 