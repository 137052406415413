import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { environment } from '@project/src/environments/environment.default';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { RootEndpointService } from '../../service/root-endpoint.service';
import { ModalConfirmacaoComponent } from '../modal/modal-confirmacao/modal-confirmacao.component';
import Swal from 'sweetalert2';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-personalizao-geral',
  templateUrl: './personalizao-geral.component.html',
  styleUrls: ['./personalizao-geral.component.less']
})
export class PersonalizaoGeralComponent implements OnInit {
  env = environment;
  config: any;

  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<PersonalizaoGeralComponent>,
    private rootService: RootEndpointService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) { }

  ngOnInit() {
    this.getConfig();
  }

  fecha(): void {
    this.dialogRef.close();
  }

  getConfig() {
    this.rootService.getConfiguracoesSistema().pipe(untilDestroyed(this)).subscribe(
      (response) => {
        this.config = response;
      },
      (error) => {
        Swal.fire({
          title: 'Erro',
          text: error.error,
          icon: 'error',
        })
        console.error(error);
      }
    );
  }

  ngOnDestroy() { }

  salvaConfig() {
    this.rootService.updateConfiguracoesSistema(this.config).pipe(untilDestroyed(this)).subscribe(
      (response) => {
        this.modalFinal('Configurações foram atualizadas com sucesso!', response);
      }, (error) => {
        console.error(error);
        Swal.fire('Erro', error.error, 'error');
      });
    if (this.config.fixaSidebar === false) {
      this.storage.set("fixaSidebar", false)
    } else {
      this.storage.set("fixaSidebar", true)
    }
  }

  modalFinal(titulo, response) {
    const dialog = this.dialog.open(ModalConfirmacaoComponent, {
      data: {
        titulo: titulo,
        texto: response,
        subtexto: ``,
        btnTexto: 'Rever configurações',
        whatsapp: false,
        checkout: false
      },
      width: '600px',
      backdropClass: 'modal-alerta'
    }).afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        location.reload()
      });
  }
}
