<h2 mat-dialog-title class="corPrimaria" style="font-weight: 600; text-align: center" *ngIf="!cobranca.statusProtesto">Confirmar protesto da cobrança</h2>
<h2 mat-dialog-title class="corPrimaria" style="font-weight: 600; text-align: center" *ngIf="cobranca.statusProtesto">Confirmar cancelamento do protesto</h2>
<div mat-dialog-content class="espacamento" style="max-width: 500px; text-align: center">
  <form [formGroup]="form" class="box-body input-size">
    <div class="row" style="justify-content: center; margin: 5%" *ngIf="!cobranca.statusProtesto">
      <mat-checkbox formControlName="notificarCliente">
        Notificar meu cliente sobre o protesto desta cobrança</mat-checkbox>
    </div>
    <div class="row" style="justify-content: center; margin: 2%" *ngIf="cobranca.statusProtesto">
      <p class="formata-texto" style="margin:5px !important">
        Aqui você deve incluir a justificativa do cancelamento do protesto.
      </p>
      <mat-form-field appearance="outline" style="margin-top: 5%;">
        <mat-label>Justificativa</mat-label>
        <textarea matInput rows="4" formControlName="justificativa" maxlength="500"></textarea>
      </mat-form-field>
    </div>
    <div class="row" style="justify-content: center;">
      <p style="font-size: 13px; font-weight: 400; text-align: center; max-width: 375px;" *ngIf="cobranca.statusProtesto && cobranca.autorizacaoRepasseCustasProtesto === 'SIM'">
        Atenção! Como a cobrança possui autorização de repasse, o cancelamento do protesto incidirá em custos operacionais que serão repassados ao devedor.
        <i class="fas fa-info-circle info-icon" matTooltip="Os custos operacionais serão repassados para o devedor em conjunto com os custos ja cobrados para emissão do protesto"></i>
      </p>
      <p style="font-size: 13px; font-weight: 400; text-align: center; max-width: 375px;" *ngIf="cobranca.statusProtesto && cobranca.autorizacaoRepasseCustasProtesto !== 'SIM'">
        Atenção! Como a cobrança não possui autorização de repasse, o cancelamento do protesto incidirá em cobrança de custos operacionais.
        <i class="fas fa-info-circle info-icon" matTooltip="Os custos operacionais serão repassados em conjunto com os custos ja cobrados para emissão do protesto"></i>
      </p>
      <p style="font-size: 13px; font-weight: 400; text-align: center;" *ngIf="!cobranca.statusProtesto">
        Atenção! Essa cobrança será protestada no IEPTB.
        <i class="fas fa-info-circle info-icon" matTooltip="O IEPTB é o canal oficial dos cartórios de protestos"></i>
      </p>
    </div>
  </form>
</div>
<div mat-dialog-actions style="justify-content: center; padding-bottom: 20px">
  <div [hidden]="!processando" class="col-12" style="padding-bottom: 20px">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="col-12 col-md-6">
    <button [disabled]="!form.valid" (click)="fecha()" class="btn btn-default btn-block" style="color: #fff;"
      mat-raised-button>
      Cancelar
    </button>
  </div>
  <div class="col-12 col-md-6" *ngIf="cobranca.statusProtesto">
    <button [disabled]="!form.valid" (click)="operacaoProtesto()" class="btn btn-block fundoCorPrimaria" 
      mat-raised-button>
      Confirmar
    </button>
  </div>
  <div class="col-12 col-md-6" *ngIf="!cobranca.statusProtesto">
    <button [disabled]="!form.valid" (click)="enviarProtesto()" class="btn btn-block fundoCorPrimaria" 
      mat-raised-button>
      Confirmar
    </button>
  </div>
</div>