import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExportarPlanilhaService {

  constructor(private client: HttpClient) { }

  exportarPlanilha(data): Observable<any> {
    
    let params = new HttpParams();

    if(data){
      params = params.append('i',data.de);
      params = params.append('f',data.ate);  
      if(data.corporacao){
        params = params.append('correntista',data.correntista);  
        params = params.append('corporacao',data.corporacao);  
      }
      params = params.append('banco',data.banco); 
      if(data.fechamentoDiario){
        params = params.append('fechamentoDiario', data.fechamentoDiario);
      }
      if(data.interno){
        params = params.append('interno', data.interno);
      }
      if(data.conciliacaoSTR){
        params = params.append('str', data.conciliacaoSTR);
      }
      if(data.tudo){
        //params = params.append('tipo',data.tudo);
        params = params.append('boletos',data.boletos);
        params = params.append('cartao',data.cartao);
        params = params.append('antecipacoes',data.antecipacoes);
        params = params.append('teds',data.teds);
        params = params.append('manutencao',data.manutencao);
        params = params.append('pagamentos',data.pagamentos);
      }else {
        if(data.boletos != null)  params = params.append('boletos',data.boletos);
        if(data.antecipacoes != null) params = params.append('antecipacoes',data.antecipacoes);
        if(data.cartao != null) params = params.append('cartao',data.cartao);
        if(data.teds != null) params = params.append('teds',data.teds);
        if(data.manutencao != null) params = params.append('manutencao',data.manutencao);
        if(data.pagamentos != null) params = params.append('pagamentos',data.pagamentos);
      }   
      if(data.emailColaborador) params = params.append('emailColaborador', data.emailColaborador)   
      if(data.tiposConta) params = params.append('tiposConta', data.tiposConta)
    }

    return this.client.get('/root/exportar-planilha-conciliacao', {
      params
    });
  }

  exportarRelatorio(codBanco, data, agrupamento, emailColaborador?: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('codBanco', codBanco);
    params = params.append('data', data);
    params = params.append('agrupamento', agrupamento);
    if(emailColaborador) params = params.append('emailColaborador', emailColaborador) 

    return this.client.get('/root/exportar-relatorio', {
      params
    });  
  }

  exportarMovimentacoesPorConta(data): Observable<any> {
    let params = new HttpParams();
    params = params.append('i', data.de);
    params = params.append('f', data.ate); 
    params = params.append('email', data.email); 
    
    return this.client.get('/root/exportar-movimentacoes-por-conta', {
      params
    });  
  }

  relatorioSimplificado(email?: string): Observable<any> {
    const url = '/root/relatorio-simplificado';
    let params = new HttpParams();

    if (email)
      params = params.append("email", `${email}`)

    return this.client.get(url, { params });
  }
}