import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  Inject,
} from '@angular/core';
import {
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { ConsultasEndpointService } from '@service/consultas-endpoint.service';
import swal from 'sweetalert2';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ValidacoesService } from '@service/validacoes.service';
import { PessoaContatoService } from '@project/src/app/service/pessoa-endpoint.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BuscaCnpjDTO } from '@project/src/app/model/apionboard/busca-cnpj-dto.model';
import { BuscaCpfDTO } from '@project/src/app/model/apionboard/busca-cpf-dto.model';

@Component({
  selector: 'app-geral',
  templateUrl: './geral.component.html',
  styleUrls: ['./geral.component.less'],
})
export class GeralComponent implements OnInit, OnDestroy {
  logotipo = null;

  @Input()
  public id_corporacao: any;
  @Input()
  public processando: boolean;
  @Input()
  public faturamento: any;
  @Input()
  public tipoEmpresa: any;
  @Input()
  public form: FormGroup;
  @Input()
  public formGeral: FormGroup;
  @Input()
  public data: any;
  @Input()
  public tipoCadastro: string = null;
  @Input()
  tipoConta: string = null;
  @Input()
  public superData: any;
  @Input()
  public id_pessoa: any;
  @Input()
  public reload: Function;
  @Output()
  public dadosDoCnpjCpfAmazon: EventEmitter<any> = new EventEmitter();
  @Output()
  public dadosDoCnpjBanco: EventEmitter<any> = new EventEmitter();

  constructor(
    private consulta: ConsultasEndpointService,
    private validacoesService: ValidacoesService,
    private pessoaContatoService: PessoaContatoService,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any
  ) {}

  ngOnInit() {
    this.data = this.data || {};

    this.form.addControl(
      'documento',
      new FormControl(this.data.documento || '', [Validators.required])
    );
    this.form.addControl(
      'nome',
      new FormControl(this.data.nome || '', [Validators.required])
    );
    this.form.addControl(
      'razaoSocial',
      new FormControl(this.data.razaoSocial || '')
    );
    this.form.addControl(
      'dataNascimento',
      new FormControl(this.data.dataNascimento || '')
    );
    this.form.addControl('site', new FormControl(this.data.site || ''));
    this.form.addControl(
      'tipoEmpresa',
      new FormControl(this.tipoEmpresa || '', [Validators.required])
    );
    this.form.addControl(
      'faturamento',
      new FormControl(this.faturamento || '', [Validators.required])
    );
    this.form.addControl('logotipoPath', new FormControl(''));
    this.form.addControl(
      'logotipo',
      new FormControl(this.data.logotipo || null)
    );

    if (this.tipoCadastro !== 'contato') {
      this.verificaCampos();
    }

    this.form
      .get('documento')
      .setValidators([this.verificaDocumento(this.form)]);
    if (this.data.logotipo) {
      if (this.data.logotipo.dataCriacao) {
        this.carregaLogotipo();
      }
    }
    if (!this.ehPessoaJuridica()) {
      this.form.get('faturamento').clearValidators();
      this.form.get('faturamento').updateValueAndValidity();
      this.form.get('tipoEmpresa').clearValidators();
      this.form.get('tipoEmpresa').updateValueAndValidity();
    }
  }

  verificaCampos() {
    if (this.data) {
      if (this.data.documento) {
        this.form.get('documento').disable();
      }
      if (this.data.nome) {
        this.form.get('nome').disable();
      }
      if (this.data.razaoSocial) {
        this.form.get('razaoSocial').disable();
      }
      if (this.data.dataNascimento) {
        this.form.get('dataNascimento').disable();
      }
    }
  }

  verificaDocumento(form: FormGroup) {
    return (control: AbstractControl) => {
      const documento = this.form.get('documento').value;

      if (!this.ehPessoaJuridica()) {
        if (!this.validacoesService.validaCPF(documento)) {
          this.dataDialog.id = 0;
          return { cpfinvalido: true } as ValidationErrors;
        }
      }

      if (this.ehPessoaJuridica()) {
        if (!this.validacoesService.validaCNPJ(documento)) {
          this.dataDialog.id = 0;
          return { cnpjinvalido: true } as ValidationErrors;
        }

        if (this.tipoCadastro === 'contato') {
          this.pessoaContatoService
            .getPessoaDoc(documento, this.id_corporacao)
            .toPromise()
            .then(
              (response) => {
                if (response) {
                  if (this.dataDialog.id != response.id) {
                    this.dataDialog.id = response.id;
                    this.dadosDoCnpjBanco.emit(response.pessoa);
                  }
                }
              },
              (reason) => {
                this.dataDialog.id = 0;
              }
            );
          this.form.get('faturamento').clearValidators();
          this.form.get('faturamento').updateValueAndValidity();
          this.form.get('tipoEmpresa').clearValidators();
          this.form.get('tipoEmpresa').updateValueAndValidity();
        }
        return null as ValidationErrors;
      } else {
        this.form.get('faturamento').clearValidators();
        this.form.get('faturamento').updateValueAndValidity();
        this.form.get('faturamento').setErrors(null);
        this.form.get('tipoEmpresa').clearValidators();
        this.form.get('tipoEmpresa').updateValueAndValidity();
        this.form.get('tipoEmpresa').setErrors(null);

        if (!this.validacoesService.validaCPF(documento)) {
          this.dataDialog.id = 0;
          return { cpfinvalido: true } as ValidationErrors;
        }

        this.pessoaContatoService
          .getPessoaDoc(documento, this.id_corporacao)
          .toPromise()
          .then(
            (response) => {
              if (response) {
                if (this.dataDialog.id != response.id) {
                  this.dataDialog.id = response.id;
                  this.dadosDoCnpjBanco.emit(response.pessoa);
                }
              }
            },
            (reason) => {
              this.dataDialog.id = 0;
            }
          );
        return null as ValidationErrors;
      }
    };
  }

  ngOnDestroy() {}

  getErrorMessage(field) {
    if (this.form.get(field)) {
      return this.form.get(field).hasError('required')
        ? 'Campo requerido'
        : this.form.get(field).hasError('minlength')
        ? 'Campo inválido'
        : this.form.get(field).hasError('cnpjinvalido')
        ? 'CNPJ inválido'
        : this.form.get(field).hasError('cpfinvalido')
        ? 'CPF inválido'
        : '';
    }
  }

  isValid(field) {
    if (this.form.get(field)) {
      if (
        this.form.get(field).value === '' ||
        this.form.get(field).value === null
      ) {
        return false;
      }
      return this.form.get(field).valid;
    }
  }

  carregaLogotipo() {
    this.logotipo = this.data.logotipo.link;

    this.form.get('logotipo').setValue({
      id: this.data.logotipo.id,
      nome: this.data.logotipo.nome,
      tipo: this.data.logotipo.tipo,
      s3key: null,
      base64: 'igual',
    });
  }

  buscaCnpj() {
    if (this.ehPessoaJuridica()) {
      this.loading();

      this.consulta
        .buscarCnpj(this.form.get('documento').value.replace(/[^0-9]/g, ''))
        .pipe(untilDestroyed(this))
        .subscribe(
          (response: BuscaCnpjDTO) => {
            this.dadosDoCnpjCpfAmazon.emit(response);
            this.form.get('documento').setErrors(null);

            this.disloading();
          },
          (error) => {
            if (error.status == 500) {
              this.form.get('documento').setErrors(null);
              this.disloading();
            } else {
              this.disloading();
              this.form.get('documento').setErrors({ cnpjinvalido: true });
            }
          }
        );
    } else {
      swal.fire({
        title: 'Atenção',
        text: 'Insira um CNPJ',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  }

  buscaCpf() {
    if (!this.ehPessoaJuridica()) {
      this.loading();
      this.consulta
        .buscarCpf(this.form.get('documento').value.replace(/[^0-9]/g, ''))
        .pipe(untilDestroyed(this))
        .subscribe(
          (response: BuscaCpfDTO) => {
            this.dadosDoCnpjCpfAmazon.emit(response);
            this.form.get('documento').setErrors(null);

            this.disloading();
          },
          (error) => {
            if (error.status == 500) {
              this.form.get('documento').setErrors(null);
              this.disloading();
            } else {
              this.disloading();
              this.form.get('documento').setErrors({ cpfinvalido: true });
            }
          }
        );
    } else {
      swal.fire({
        title: 'Atenção',
        text: 'Insira um CPF',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  }

  loading() {
    this.processando = true;
    this.form.disable();
  }

  disloading() {
    this.processando = false;
    this.form.enable();
  }

  ehPessoaJuridica() {
    if (this.tipoConta === 'pf') {
      return false;
    } else if (
      this.tipoCadastro === 'primeiros-passos' &&
      this.tipoConta === 'pj'
    ) {
      return true;
    } else if (this.tipoCadastro != 'primeiros-passos') {
      if (this.form.get('documento').value) {
        const documentoInput = this.form
          .get('documento')
          .value.replace(/[^0-9]/g, '');
        if (documentoInput.length <= 11) {
          this.form.get('razaoSocial').clearValidators();
          this.form.get('razaoSocial').updateValueAndValidity();
          return false;
        } else {
          this.form.get('razaoSocial').setValidators([Validators.required]);
          return true;
        }
      } else {
        return false;
      }
    }
  }

  logotipoNull() {
    this.logotipo = null;
  }

  onSelectFile(event) {
    // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (file.type.includes('image')) {
        const reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]); // read file as data url

        reader.onload = (event: any) => {
          // called once readAsDataURL is completed
          const base64 = event.target.result.substring(
            event.target.result.lastIndexOf(',') + 1,
            event.target.result.length
          );

          const logotipoPath = this.form.get('logotipoPath').value;
          const logotipoNome = logotipoPath.substring(
            logotipoPath.lastIndexOf('h\\') + 2,
            logotipoPath.length
          );
          const logotipoData = {
            nome: logotipoNome,
            tipo: file.type,
            s3key: null,
            base64: base64,
          };
          this.logotipo = event.target.result; // substitui o src pelo da nova img
          this.form.get('logotipo').setValue(logotipoData);
        };
      } else {
        swal.fire({
          title: 'Atenção',
          text: 'Entre com uma imagem válida',
          icon: 'warning',
          confirmButtonText: 'OK',
        });
      }
    }
  }

  mascaraData(event: any, field: string) {
    let data = event.target.value;
    data = data.replace(/\D/g, '');
    data = data.replace(/(\d{2})(\d)/, '$1/$2');
    data = data.replace(/(\d{2})(\d)/, '$1/$2');
    data = data.replace(/(\d{2})(\d{2})$/, '$1$2');
    document.getElementById(field)['value'] = data;
  }
}
