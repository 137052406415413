<form [formGroup]="form" class="espaco-top">
  <div class="row-inputs pb-3" *ngFor="let representante of form.controls; index as i" [formGroup]="representante">
    <div class="row" style="padding-bottom: 1em;padding-top: 3rem;">
      <div class="col-12 d-flex flex-row justify-content-between align-items-center">
        <p style="text-align: center; margin: 0;">Lembre-se, os representantes legais devem estar no contrato social do
          seu negócio.
        </p>
        <i *ngIf="i>0" class="fas fa-trash icones"
        title="Excluir"
        (click)="del(i)"
        style="color: red; padding-top: 12px"></i>
      </div>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div fxFlex="100" style="display: flex" >
        <div fxFlex="60" style="margin-right: 15px;">
          <mat-form-field appearance="outline">
            <mat-label>Qual é o nome do representante?</mat-label>
            <input matInput type="text" formControlName="nome" required />
            <mat-error >{{ getErrorMessage(representante, "nome") }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="30" style="margin-right: 15px;">
          <mat-form-field appearance="outline">
            <mat-label>Qual é o telefone do representante?</mat-label>
            <input matInput type="text" formControlName="telefone" mask="(00)00000099?9" required />
            <mat-error >{{ getErrorMessage(representante, "nome") }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="30">
          <mat-form-field appearance="outline">
            <mat-label>Qual é o email do representante?</mat-label>
            <input matInput type="text" formControlName="email" required />
            <mat-error >{{ getErrorMessage(representante, "email") }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      
      <div fxFlex="49" style="display: flex; align-items: baseline">
        <mat-form-field appearance="outline" fxFlex="25" style="margin-right : 10px">
          <mat-label>Documento</mat-label>
          <mat-select placeholder="Documento" required [(ngModel)]="tipoDocumento[form.controls.indexOf(representante)]" [ngModelOptions]="{standalone: true}">
            <mat-option (click)="setarDocumento(form.controls.indexOf(representante), 'CPF')" value="CPF">
              CPF
            </mat-option>
            <mat-option (click)="setarDocumento(form.controls.indexOf(representante), 'CNPJ')" value="CNPJ">
              CNPJ
            </mat-option>
          </mat-select>   
        </mat-form-field>    
        <mat-form-field appearance="outline" *ngIf="tipoDocumento[form?.controls?.indexOf(representante)]==='CNPJ'">
          <mat-label>Qual é o CNPJ do representante?</mat-label>
          <input (ngModelChange)="validaDocumento(representante.get('documento'), i)" id="documento{{form.controls.indexOf(representante)}}" matInput type="text" formControlName="documento"  mask="00.000.000/0000-00" required />
          <mat-error>{{ getErrorMessage(representante, "documento") }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="tipoDocumento[form?.controls?.indexOf(representante)]==='CPF'">
          <mat-label>Qual é o CPF do representante?</mat-label>
          <input (ngModelChange)="validaDocumento(representante.get('documento'), i)" id="documento{{form.controls.indexOf(representante)}}" matInput type="text" formControlName="documento"  mask="000.000.000-00" required />
          <mat-error>{{ getErrorMessage(representante, "documento") }}</mat-error>
        </mat-form-field>
      </div>

      <ng-container *ngIf="tipoDocumento[form?.controls?.indexOf(representante)]==='CPF'">
        <div fxFlex="49">
          <mat-form-field appearance="outline">
            <mat-label>Data de nascimento</mat-label>
            <input matInput [matDatepicker]="picker1" id="dataNascimento{{i}}" (ngModelChange)="mascaraData(i, representante?.value?.dataNascimento,'dataNascimento')"
              formControlName="dataNascimento" [min]='dataMinima' [max]="dataMaxima">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-error>{{ getErrorMessage(form, "dataNascimento") }}</mat-error>
          </mat-form-field>
        </div>
      </ng-container>
      <div fxFlex="100">
        <mat-form-field appearance="outline">
          <input matInput type="text" hidden formControlName="pessoaPoliticamenteExposta" />
          <mat-slide-toggle formControlName="pessoaPoliticamenteExposta">Politicamente Exposta</mat-slide-toggle>
          <i class="fas fa-info-circle info-icon"
          matTooltip="É uma pesssoa que desempenha ou desempenhou, no Brasil ou no exterior nos últimos 5 anos, funções públicas relevantes, ou tece familiares, representantes ou colaboradores próximos que desempenharam ou desempenham esse tipo de função. Para saber mais, veja a Circular BCB n.3.978/20."></i>
          <mat-error>{{ getErrorMessage(form, "pessoaPoliticamenteExposta") }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>

<div class="row row-inputs">
  <div class="col-6 col-md-6 add">
    <span (click)="add()">+ adicionar novo representante legal</span>
  </div>
</div>
