import { PermissionamentoEndpointService } from "@project/src/app/service/permissionamento.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { untilDestroyed } from "ngx-take-until-destroy";
import { environment } from "@src/environments/environment";
import { SelecionarCorporacaoComponent } from "@project/src/app/pages/interno/corporacao/selecionar-corporacao/selecionar-corporacao.component";
import { AutenticacaoEndpointService } from "@service/autenticacao-endpoint.service";
import { PaginaService } from "@service/pagina.service";
import { UsuarioEndpointService } from "@service/usuario-endpoint.service";
import { AdminService } from "../admin.service";
import { StringFormatService } from "@project/src/app/service/util/stringFormater.service";
import { EmpresaEndpointService } from "@project/src/app/service/empresa-endpoint.service";
import { CheckAuthoritiesService } from "@project/src/app/service/util/checkAuthorities.service";
import { Router } from "@angular/router";

declare const $: any;

export interface RouteInfo {
  path: string;
  info: string;
  role?: string;
  load?: boolean;
  permite?: boolean;
  collapse?: string;
  children?: RouteInfo[];
}

export var ROUTES: RouteInfo[];
@Component({
  selector: "app-sidebar-cmp",
  templateUrl: "sidebar.component.html",
  styleUrls: ["./sidebar.component.less"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public menuItems: any[];
  corporacaoLogada: any;
  empresaLogada: any;
  usuarioLogado: any;
  empresaList: any = [];
  corporacaoList: any = [];
  operador: any;
  processando = false;
  escondeTitulos = true;
  env = environment;
  doc: string;
  sidebarOpen: boolean = false;
  public matToltipText = "";
  modifiedText = false;
  ehAdmin = false;
  ehOperador = false;
  naoBloquearCobranca = false;
  areaPix = false;

  constructor(
    public paginaService: PaginaService,
    public autenticacao: AutenticacaoEndpointService,
    public dialog: MatDialog,
    private usuario: UsuarioEndpointService,
    private adminService: AdminService,
    private empresaService: EmpresaEndpointService,
    public _rolePermission: PermissionamentoEndpointService,
    public stringFormat: StringFormatService,
    private router: Router
  ) {
    this.corporacaoLogada = this.autenticacao.regraCorporacao;
    const userData = this.autenticacao.sessao;
    const checkAuthorities = new CheckAuthoritiesService(userData);
    this.areaPix = this.autenticacao?.regraEmpresa?.empresa?.configuracao?.areaPix;
    if (userData.principal.authorities) {
      this.ehAdmin = checkAuthorities.isAdministrador;

      this.ehOperador = checkAuthorities.isOperador;
    }
    ROUTES = [
      {
        path: "principal",
        info: "dashboard",
      },
      {
        path: "pessoa/contato",
        info: "contato",
        role: this.ehOperador ? "ROLE_EMPRESA_PESSOAS_VISUALIZAR" : "ROLE_CONTATO_LISTAR",
      },
      {
        path: "cobranca",
        info: "cobranca",
        load: environment.modulos.cobranca.sidebar,
        role: this.ehOperador ? "ROLE_EMPRESA_COBRANCAS_VISUALIZAR" : "ROLE_COBRANCA_LISTAR",
      },
      {
        path: "pix",
        info: "pix",
        load: environment.modulos.pix.sidebar
      },
      {
        path: "pague-contas",
        info: "pague-contas",
        load: environment.modulos.pagamentoContas.sidebar,
        role: this.ehOperador ? "ROLE_EMPRESA_PAGAMENTOS_VISUALIZAR" : "ROLE_PAGAMENTOCONTAS_LISTAR",
      },
      {
        path: "extrato",
        info: "extrato",
        load: environment.modulos.extrato.sidebar,
        role: this.ehOperador ? "ROLE_EMPRESA_EXTRATOFINANCEIRO_VISUALIZAR" : "ROLE_EXTRATO_LISTAR",
      },
      {
        path: "investimentos",
        load: environment.modulos.investidor.sidebar,
        info: "investimentos",
        role: this.ehOperador ? "ROLE_EMPRESA_INVESTIMENTOS_VISUALIZAR" : "ROLE_INVESTIMENTO_ATUALIZAR",
      },
      {
        path: "transferencia",
        info: "transferencia",
        load: environment.modulos.transferencia.sidebar,
        role: this.ehOperador ? "ROLE_EMPRESA_TRANSFERENCIAS_VISUALIZAR" : "ROLE_TRANSFERENCIA_LISTAR",
      },
      {
        path: "cartao",
        load: environment.modulos.cartaoCredito.sidebar,
        info: "cartao",
      },
      {
        path: "credito",
        load: environment.modulos.credito.sidebar,
        info: "credito",
      },
      {
        path: "indicar-amigos",
        info: "indicar-amigos",
        load: environment.modulos.convite.indicarAmigos,
        role: this.ehOperador ? "ROLE_EMPRESA_PESSOAS_VISUALIZAR" : "ROLE_CONVIDARAMIGO_LISTAR",
      }
    ];

    this.usuario
      .obterUsuarioLogado()
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.usuarioLogado = response;
        this.operador = this.usuarioLogado.ehOperador;
      });
    this.adminService.pegaUpdate().subscribe((sidebarBool) => {
      this.sidebarOpen = sidebarBool;
    });
  }

  async ngOnInit() {
    const checkAuthorities = new CheckAuthoritiesService(this.autenticacao.sessao);
    this.processando = true;
    this.menuItems = ROUTES.filter((menuItem) =>
      menuItem.load == null ? true : menuItem.load
    );

    for (const item of this.menuItems) {
      if (item.role) {
        item.permite = checkAuthorities.permissaoGuard(item.role);
      } else {
        item.permite = true;
      }
    }

    this.usuario
      .getRegraCorporacaoLogada()
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.corporacaoLogada = response;
          this.getEmpresasDaCorporacao(this.corporacaoLogada.corporacao);
          this.usuario
            .getRegraEmpresaLogada()
            .pipe(untilDestroyed(this))
            .subscribe(
              (res) => {
                this.empresaLogada = res;
                this.processando = false;
              },
              (error) => {
                this.processando = false;
              }
            );

        },
        (error) => {
          console.error(error.erro);
          this.processando = false;
        }
      );
  }

  ngOnDestroy() { }

  getEmpresasDaCorporacao(corporacao) {
    this.processando = false;
    let empresa: number;
    if (this.ehAdmin) {
      return true;
    }
    this.usuario
      .getCorporacoesDoUsuario()
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.corporacaoList = response;
      });

    if (corporacao) {
      empresa = corporacao.id;
    }
    let page = 0,
      size = 20;
    this.usuario;
    this.empresaService
      .listar({ empresa, page, size })
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.empresaList = res.content;
      });
    if (this.empresaList.length <= 1 && this.corporacaoList.length <= 1) {
      return false;
    } else {
      return true;
    }
  }

  visualiza(route: RouteInfo) {
    if (!this.processando) {
      if (this.empresaLogada) {
        if (
          this.empresaLogada.empresa.moderacaoEmpresa != "aprovada" &&
          this.empresaLogada.empresa.moderacaoEmpresa != "bloqueada" &&
          !this.ehAdmin
        )
          return false;
        else if (this.empresaLogada.empresa.moderacaoEmpresa === "aprovada" || this.ehAdmin) {
          if (this.usuarioLogado && (this.usuarioLogado.ehOperador || this.usuarioLogado.tipoUsuario === "colaborador" || this.ehAdmin)) {
            return route.permite;
          }
        }
        if (
          this.empresaLogada.empresa.tipoConta === "contaDigitalS" &&
          route.info === "investimentos"
        ) {
          return false;
        }
        return true;
      }
    }
    if (!!route.children && route.children.length) {
      for (const children of route.children) {
        if (!this.visualiza(children)) {
          return false;
        }
      }
    }
    const pagina = this.paginaService.obter(route.info);
    return this.autenticacao.permite(pagina.permissoes);
  }

  selecionaCorporacao() {
    if (this.operador) {
    } else {
      this.dialog.open(SelecionarCorporacaoComponent, {
        width: "800px",
        panelClass: "selecionar-corporacao",
      });
    }
  }

  adicionarPontosDocumento() {
    const documento = this.empresaLogada.empresa.pessoa.documento;
    return (
      documento.substring(0, 2) +
      "." +
      documento.substring(2, 5) +
      "." +
      documento.substring(5, 8) +
      "/" +
      documento.substring(8, 12) +
      "-" +
      documento.substring(12, 14)
    );
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  updatePS(): void {
    // if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
    //   const elemSidebar = <HTMLElement>(
    //     document.querySelector('.sidebar .sidebar-options')
    //   );
    //   const ps = new PerfectScrollbar(elemSidebar, {
    //     wheelSpeed: 2,
    //     suppressScrollX: true
    //   });
    // }
  }

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  mudaTexto(menuItem) {
    if (!this.visualiza(menuItem)) {
      if (
        menuItem.info === "investimentos" &&
        this.empresaLogada.empresa.tipoConta === "contaDigitalS" && !this.ehAdmin
      ) {
        return "Funcionalidade não disponível para Conta Digital S";
      }

      return "Você não pode acessar essa funcionalidade";
    }  else if (
      (
        ((this.empresaLogada.empresa.numIdentcBenfcrio === null || this.empresaLogada.empresa.numIdentcBenfcrio == "0")
        && this.empresaLogada.empresa.tipoConta !== "contaDigitalS") ||
        (this.env.modulos.pix.gerarQrCode && !this.areaPix && (this.empresaLogada.empresa.numIdentcBenfcrio === null || this.empresaLogada.empresa.numIdentcBenfcrio == "0") && this.empresaLogada.empresa.tipoConta !== "contaDigitalS")
      ) && menuItem.info === "cobranca"
    ) {
      return "Conta não cadastrada para emissão de boletos. Entre em contato com seu gerente.";
    } else if (
      !this.empresaLogada.empresa.configuracao.habilitaPagamentos && menuItem.info === "pague-contas" && !this.ehAdmin
    ) {
      return "Conta não cadastrada para pagamentos. Entre em contato com seu gerente.";
    } else if (
      (this.env.modulos.pix.gerarQrCode === true && this.areaPix === false) && menuItem.info === "pix"
    ) {
      return "Conta não cadastrada para Pix. Entre em contato com seu gerente.";
    }
    else {
      return null;
    }
  }

  navegar(menuItem) {
    if (this.visualiza(menuItem)) {
      this.router.navigate([`privado/${menuItem.path}`])
    }
  }
}
