import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FalarComGerenteComponent } from '@project/src/app/components/falar-com-gerente/falar-com-gerente.component';
import { ModalConfirmaTokenComponent } from '@project/src/app/components/modal/modal-confirma-token/modal-confirma-token.component';
import { TransferenciaEndpointService } from '@project/src/app/service/transferencia-endpoint.service';
import { EmpresaEndpointService } from '@project/src/app/service/empresa-endpoint.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import swal from 'sweetalert2';
import { environment } from '@project/src/environments/environment';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';
import { Router } from '@angular/router';
import { CobrancaEndpointService } from '@project/src/app/service/cobranca-endpoint.service';

@Component({
  selector: 'app-encerrar-conta',
  templateUrl: './encerrar-conta.component.html',
  styleUrls: ['./encerrar-conta.component.less']
})
export class EncerrarContaComponent implements OnInit {

  @Input()
  public data: any;

  @Input()
  public empresaId: number;
  public form: FormGroup;

  public numeroTelefone: string;
  public celular: string;
  public saldoAtual: number = 0.0;
  public empresaLogada: any;

  constructor(
    private dialog: MatDialog,
    private transferenciaService: TransferenciaEndpointService,
    private empresaService: EmpresaEndpointService,
    private autenticacao: AutenticacaoEndpointService,
    private router: Router,
    private cobrancaService: CobrancaEndpointService,
  ) {
    this.form = new FormGroup({});
    this.empresaLogada = this.autenticacao.regraEmpresa
      ? this.autenticacao.regraEmpresa.empresa
      : null;
  }

  ngOnInit() {
    this.form.addControl('ciente', new FormControl(false, Validators.requiredTrue));
    this.data = this.data || {};
  }

  public ngOnDestroy() { }

  scroll(el: HTMLElement) {
    setTimeout(() => {
      el.scrollIntoView({ behavior: 'smooth' });
    }, 2);
  }

  public gerenteModal() {
    this.dialog.open(FalarComGerenteComponent, {
      width: "800px",
      panelClass: "fundo-empresa",
    });
  }

  public carregaTelefone(telefoneSeguranca) {

    let num: string = telefoneSeguranca.ddd + "" + telefoneSeguranca.numero;
    if (num.length === 10) {
      this.numeroTelefone = '(' + num.substring(0, 2) + ')' + num.substring(2, 6) + '-' + num.substring(6, 10);
    }
    else if (telefoneSeguranca.ddd && telefoneSeguranca.numero) {
      this.numeroTelefone = '(' + num.substring(0, 2) + ')' + num.substring(2, 7) + '-' + num.substring(7, 11);
    }
    else {
      this.numeroTelefone = '';
    }
    this.celular = num;
  }

  public solicitaEncerramentoConta() {
    this.cobrancaService.contarCobrancas(this.empresaId).pipe(untilDestroyed(this)).subscribe(
      (response) => {
        let qntCobrancas = response;
        if (qntCobrancas === 0) {
          this.transferenciaService
            .saldoDisponivelOuNegativo(this.empresaId)
            .pipe(untilDestroyed(this))
            .subscribe(
              (response) => {
                this.saldoAtual = response;
                this.verificaSaldo();
              },
              (error) => {
                swal.fire({
                  title: "Erro",
                  text: error.error,
                  icon: "error",
                  confirmButtonText: "OK",
                });
                console.error(error);
              }
            );
        }
        else {
          swal.fire({
            title: "VOCÊ TEM COBRANÇAS A RECEBER",
            text: "Exclua-as antes de solicitar o encerramento de sua conta.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      },
      (error) => {
        swal.fire({
          title: "Erro",
          text: error.error,
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error(error);
      }
    )



  }

  public verificaSaldo() {

    if (this.saldoAtual > 0) {
      swal.fire({
        title: "SALDO NA CONTA!",
        text: "Transfira o saldo antes de solicitar o encerramento de sua conta.",
        icon: "error",
        confirmButtonText: "OK",
      });

    } else if (this.saldoAtual < 0) {
      swal.fire({
        title: "CONTA NEGATIVA!",
        text: "Realize o pagamento antes de solicitar o encerramento de sua conta.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      this.carregaTelefone(this.data.configuracao.telefoneSeguranca[0]);
      const dialog = this.dialog.open(ModalConfirmaTokenComponent, {
        data: {
          mensagem: "Enviamos um token para solicitação de encerramento de conta.",
          estrutura: "encerrarConta",
          numeroTelefone: this.numeroTelefone,
          celular: this.celular,
        },
        height: '45vh',
        backdropClass: 'modal-alerta'
      });
      dialog.afterClosed().subscribe(result => {
        if (result) {
          if (result.validado === true) {

            if (this.empresaId != undefined) {
              this.empresaService
                .enviarEmailEncerrarConta(this.empresaId)
                .pipe(untilDestroyed(this))
                .subscribe(
                  (res) => {
                    swal.fire({
                      title: "SOLICITAÇÃO ENVIADA!",
                      text: "Lamentamos muito que você quis encerrar a sua conta conosco.",
                      icon: "success",
                      confirmButtonText: "OK",
                    }).then((result) => {
                      if (this.empresaId == this.empresaLogada.id) {
                        this.router.navigate(['/privado/principal']).then(() => {
                          window.location.reload();
                        });
                      }

                    });
                  },
                  (err) => {
                    swal.fire({
                      title: "Error",
                      text: err,
                      icon: "error",
                      confirmButtonText: "OK",
                    });
                    console.error(err);
                  }
                );
            }
          }
        }
      })
    }
  }


  public showInfo() {

    swal.fire({
      title: "Informações Importantes",
      html: "<p> Caso exista saldo em sua conta, <br>realize a transferência dele. <br> Caso esteja negativado, <br>realize o pagamento antes de solicitar<br> o cancelamento de sua conta.</p>" +
        "<p>Se houver alguma dúvida,<br> estamos à disposição para auxiliar.<br> Entre em contato conosco.</p> " +
        " <p><strong> Contato </strong><br>" + environment.empresa.telefone.suporte + "<br>" + environment.empresa.email.suporte + "<br> Segunda à Sexta - 9:00 às 18:00 </p>"
      ,
      icon: "warning",
      confirmButtonText: "OK",
    });

  }

}
