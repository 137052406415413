<div mat-dialog-content class="div-body" style="background: #EEEEEE;">
    <div class="main-content" style="background: #EEEEEE;">
        <div class="container-fluid" style="padding-left: 0px;padding-right: 0px;">
            <div class="row" style="justify-content: center;">
                <div class="col-12">

                    <div class="card">
                        <div [hidden]="!processando" style="padding: 10px 0 20px 0;">
                            <div class="col-12">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </div>
                        </div>

                        <div class="card-body" *ngIf="!processando && data !== null">
                            <!-- Título do modal -->
                            <h2 mat-dialog-title class="titulo-modal">
                                <div id="avatar-icon">
                                    <i class="fas fa-building fa" style="font-size: 0.8em !important;"></i>
                                </div>
                                <span id="empresa-logada"
                                    [matTooltip]="empresaLogada.pessoa.razaoSocial ||  empresaLogada.pessoa.nome">
                                    <span>{{ empresaLogada.pessoa.razaoSocial || empresaLogada.pessoa.nome }}</span>
                                    <p>{{ stringFormat.adicionarPontosDocumento(empresaLogada.pessoa.documento) }}</p>
                                </span>
                                DADOS DA COBRANÇA
                                <i (click)="fecha()" class="material-icons btn-fechar"
                                    style="margin-right: 3%;">close</i>
                                <span id="fechar" (click)="fecha()" style="margin-right: 1%;">FECHAR</span>
                                <!-- </div> -->
                            </h2>
                            <!-- Título do modal -->

                            <!-- Menu -->
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="visualizarFatura()">
                                    <i class="fas fa-eye"></i>
                                    Visualizar Fatura
                                </button>
                                <button [disabled]="status === 'PAGAMENTO EFETUADO'" mat-menu-item
                                    (click)="editarCobranca()">
                                    <i class="fas fa-barcode"></i>
                                    Editar Cobrança
                                </button>
                                <button mat-menu-item (click)="visualizarBoleto()" [disabled]="data?.statusProcessamentoBanco === 'erro'">
                                    <i class="fas fa-barcode"></i>
                                    Visualizar Boleto
                                </button>
                                <button mat-menu-item (click)="reenviar()"
                                    [disabled]="data.contatoSemNotificacao === true || data?.statusProcessamentoBanco === 'erro'">
                                    <i class="far fa-envelope"></i>
                                    Reenviar Notificações
                                </button>
                                <div
                                    [matTooltip]="data.dataAssinaturaContratoAntecipacao ? 'Não é possível liquidar em dinheiro cobranças antecipadas.' : ''">
                                    <button mat-menu-item (click)="liquidarCobranca()"
                                        [disabled]="data.dataPagamento || data.dataDesativacao || data.dataAssinaturaContratoAntecipacao || data?.statusProcessamentoBanco === 'erro'">
                                        <i class="fas fa-money-bill "></i>
                                        Confirmar Recebimento em Dinheiro
                                    </button>
                                </div>
                            </mat-menu>
                            <!-- Menu -->


                            <!-- Dados Cobrança -->
                            <div class="box-body">
                                <i [matMenuTriggerFor]="menu" class="fas fa-bars btn-fechar"
                                    style="font-size: 26px;border: solid 0px white;"
                                    [hidden]="!authCobranca && !authCobrancaOperador"></i>
                                <p>
                                    <span class="negrito">Situação:&nbsp;</span> <span class="bordinha"
                                        [style.background-color]="status === 'COBRANÇA VENCIDA' ? '#FE431C' : status === 'PAGAMENTO EFETUADO' ? '#4FA953' : status === 'FATURA EXCLUÍDA' ? '#4A494A' : status === 'AGUARDANDO PAGAMENTO' ? '#DFB924' : status === 'ERRO' ? '#FE431C' : ''" >{{
                                        data.valorPagoEmDinheiro ? status + ' EM DINHEIRO' : status }}
                                    </span>
                                    <span class="bordinha" style="margin-left: 10px;" *ngIf="data.lastro"
                                        [style.background-color]="status === 'COBRANÇA VENCIDA' ? '#FE431C' : status === 'PAGAMENTO EFETUADO' ? '#4FA953' : status === 'FATURA EXCLUÍDA' ? '#4A494A' : status === 'AGUARDANDO PAGAMENTO' ? '#DFB924' : status === 'ERRO' ? '#FE431C' : ''">LASTRO
                                    </span>
                                    <span *ngIf="data.dataAssinaturaContratoAntecipacao"
                                        class="bordinha fundoCorPrimaria" style="margin-left: 20px; ">COBRANÇA
                                        ANTECIPADA</span>
                                </p>
                                <p>
                                    <span class="negrito">Link para a fatura: </span> <a href="{{ data.link }}"
                                        target="_blank">{{ data.link }}</a>&nbsp;&nbsp;<span class="bordinha"
                                        style="background-color: #1400c8; cursor: pointer;"><i matTooltip="Copiar"
                                            style="font-size: large;" (click)="copyToClipboard(data.link)"
                                            class="fas fa-copy"></i></span>
                                </p>
                                <p style="line-height: normal;">
                                    <span *ngIf="data.dataAberturaFatura || data.dataAberturaBoleto"
                                        class="negrito">Última visualização:&nbsp;</span>
                                    <br *ngIf="data.dataAberturaFatura" />
                                    <span *ngIf="data.dataAberturaFatura" style="margin-left: 1.2rem;"><i
                                            class="fas fa-eye" style="margin-right: 10px;"></i>{{
                                        data.dataAberturaFatura | date:'dd/MM/yyyy, HH:mm' }}
                                        - Fatura
                                        Visualizada</span>
                                    <br *ngIf="data.dataAberturaFatura" />
                                    <span *ngIf="data.dataAberturaBoleto" style="margin-left: 1.2rem;"><i
                                            class="fas fa-barcode" style="margin-right: 10px;"></i>{{
                                        data.dataAberturaBoleto | date:'dd/MM/yyyy, HH:mm'}}
                                        - Boleto
                                        Visualizado
                                    </span>
                                </p>
                                <div class="row">
                                    <p class="col-4" *ngIf="!data.valorPagoEmDinheiro">
                                        <span class="negrito">Valor pago: </span>
                                        {{ status === 'PAGAMENTO EFETUADO' ? (data.valorRecalculado | currency: 'BRL':
                                        'R$') : status === 'FATURA EXCLUÍDA' ? 'Fatura Excluída' : status === 'ERRO' ? 'Ocorreu um erro ao emitir a cobrança' : 'Aguardando
                                        pagamento' }}
                                    </p>
                                    <p class="col-4" *ngIf="data.valorPagoEmDinheiro">
                                        <span class="negrito">Valor pago em dinheiro: </span>
                                        {{ (data.valorPagoEmDinheiro | currency: 'BRL': 'R$') }} <i
                                            *ngIf="data.valorPagoEmDinheiro != data.valorOriginal"
                                            class="fas fa-info-circle" style="margin-left: 6px;"
                                            matTooltip="Verifique com o seu cliente o motivo da cobrança ter sido paga com um valor diferente do original.">
                                        </i>
                                    </p>
                                    <p class="col-4">
                                        <span class="negrito">Valor real: </span>
                                        {{ data.valorOriginal | currency: 'BRL': 'R$' }}
                                    </p>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <div style="margin-bottom: 1rem">
                                            <span class="negrito">Criada em:</span>
                                            {{ data.criadoEm | date:'dd/MM/yyyy'}}
                                        </div>
                                    </div>
                                    <p class="col-4">
                                        <span class="negrito">Vencimento:&nbsp;</span>
                                        <del *ngIf="!vencimentoEmDiaUtil"
                                            matTooltip="Vencimento agendado para o dia útil mais próximo"
                                            style="margin-right: 5px;">{{ data.dataVencimentoOriginal |
                                            date:'dd/MM/yyyy' }}</del>
                                        {{ data.dataVencimentoRecalculada | date:'dd/MM/yyyy' }}
                                    </p>
                                    <p class="col-4" *ngIf="status === 'PAGAMENTO EFETUADO'">
                                        <span class="negrito">Pago em:</span>
                                        {{ data.dataPagamento | date:'dd/MM/yyyy'}}
                                    </p>
                                    <div class="col-4" *ngIf="status === 'FATURA EXCLUÍDA'">
                                        <div style="margin-bottom: 1rem">
                                            <span class="negrito">Excluida em:</span>
                                            {{ data.dataDesativacao | date:'dd/MM/yyyy'}}
                                        </div>
                                    </div>
                                </div>
                                <p *ngIf="data.dataAssinaturaContratoAntecipacao">
                                    <span class="negrito">Antecipada em:</span>
                                    {{ data.dataAssinaturaContratoAntecipacao | date:'dd/MM/yyyy' }}
                                </p>
                                <p>
                                    <span class="negrito">Cliente:</span> {{ nomeOuRazaoSocial(data.cliente.pessoa) }} -
                                    {{ data.cliente.pessoa.documento }}
                                </p>
                                <p>
                                    <span class="negrito">Descrição:</span> {{ data.descricao }} <i
                                        class="fas fa-info-circle" style="vertical-align: top; font-size: x-small;"
                                        matTooltip="A descrição informada será impressa no boleto."></i>
                                </p>
                                <p>
                                    <span class="negrito">Forma de pagamento:</span> <b class="corPrimaria"
                                        style="font-weight: bold;">
                                        {{ data.valorPagoEmDinheiro ? 'DINHEIRO' : status === 'FATURA EXCLUÍDA' ?
                                        'Fatura excluída' : data.formaPagamento }}</b>
                                </p>
                                <p
                                    *ngIf="!data.valorPagoEmDinheiro && data.formaPagamento === 'CARTÃO DE CRÉDITO' && status === 'PAGAMENTO EFETUADO'">
                                    <span class="negrito">Recebido e disponível para saque em até <span
                                            class="corPrimaria" style="font-weight: bold;">30 dias.</span></span>
                                </p>
                                <p
                                    *ngIf="!data.valorPagoEmDinheiro && data.formaPagamento !== 'CARTÃO DE CRÉDITO' && status === 'PAGAMENTO EFETUADO'">
                                    <span class="negrito">Recebido e disponível para saque em:</span>
                                    {{ status === 'FATURA EXCLUÍDA' ? 'Fatura excluída' : status === 'PAGAMENTO
                                    EFETUADO' ? (data.dataPagamento | date:'dd/MM/yyyy') :'Aguardando pagamento' }}
                                </p>
                                <p *ngIf="status !== 'PAGAMENTO EFETUADO'">
                                    {{ status === 'COBRANÇA VENCIDA' ? 'Cobrança vencida' : status !== 'ERRO' ? 'Aguardando pagamento' : '' }}
                                </p>
                                <p *ngIf="status === 'ERRO'">
                                    <span class="negrito" *ngIf="status === 'ERRO'">
                                        Descrição do erro: 
                                    </span>
                                    {{data.descricaoErro}}
                                </p>
                            </div>
                            <!-- Dados Cobrança -->

                            <!-- Juros, multas e descontos -->
                            <h3 class="card-title corPrimaria" style="font-weight: 600;  margin-top: 20px;">Juros,
                                multas
                                e desconto
                            </h3>
                            <div class="box-body">
                                <p>
                                    <span class="negrito" style="font-size: 16px;">Juros e multas aplicados por
                                        atraso:</span>
                                </p>
                                <p *ngIf="!data.importacaoCnabId" style="padding-left: 20px;">
                                    <span class="negrito">Juros ao mês:&nbsp;</span>
                                    {{ data.juros != 0 ? data.juros + '%' : 'Isento' }}
                                </p>

                                <p *ngIf="data.importacaoCnabId" style="padding-left: 20px;">
                                    <span class="negrito">Valor cobrado por dia de atraso:&nbsp;</span>
                                    {{ data.juros != 0 ? (data.juros | currency: 'BRL': 'R$') : 'Isento' }}
                                </p>

                                <p style="padding-left: 20px;">
                                    <span class="negrito">Multa:&nbsp;</span>
                                    {{ data.multa != 0 ? data.porcentagemMulta + '% (' + (data.multa | currency: 'BRL':
                                    'R$') + ')' : 'Isento' }}
                                </p>
                                <p>
                                    <span class="negrito" style="font-size: 16px;">Desconto concedido até o
                                        vencimento:</span>
                                </p>
                                <p style="padding-left: 20px;">
                                    <span class="negrito">Desconto {{ data.dataLimiteDesconto ? 'até '+
                                        (data.dataLimiteDesconto | date:'dd/MM/yyyy') : ''}}:</span>
                                    {{ data.valorDesconto != 0 ? (data.valorDesconto | currency: 'BRL': 'R$') :
                                    'Isento'}}
                                </p>
                                <p *ngIf="data.dataLimiteDesconto2 && data.valorDesconto2" style="padding-left: 20px;">
                                    <span class="negrito">Desconto até {{(data.dataLimiteDesconto2 |
                                        date:'dd/MM/yyyy')}}:</span>
                                    {{ (data.valorDesconto2 | currency: 'BRL': 'R$') }}
                                </p>
                                <p *ngIf="data.dataLimiteDesconto3 && data.valorDesconto3" style="padding-left: 20px;">
                                    <span class="negrito">Desconto até {{(data.dataLimiteDesconto3 |
                                        date:'dd/MM/yyyy')}}:</span>
                                    {{ (data.valorDesconto3 | currency: 'BRL': 'R$') }}
                                </p>
                            </div>
                            <!-- Juros, multas e descontos -->

                            <!-- Protesto -->
                            <h3 *ngIf="informacoesProtesto" class="card-title corPrimaria" style="font-weight: 600;  margin-top: 20px;">
                                Informações do Protesto
                            </h3>
                            <div *ngIf="informacoesProtesto" class="box-body">
                                <div class="row" *ngIf="data?.statusProtesto">
                                    <p>
                                        <span class="negrito" style="font-size: 16px;">Status do protesto:&nbsp;</span> 
                                        <span class="bordinha"
                                        [style.background-color]="data?.statusProtesto === 'AGUARDANDORETORNO' ? '#DFB924' : data?.statusProtesto === 'REMOVIDO' ? '#4A494A' : data?.statusProtesto === 'DESISTIDO' ? '#4A494A' : data?.statusProtesto === 'INEXISTENTE' ? '#4A494A' : data?.statusProtesto === 'COLETADO' ? '#4FA953' : data?.statusProtesto === 'GERADO' ? '#4FA953' : data?.statusProtesto === 'ENVIADO' ? '#4FA953' : data?.statusProtesto === 'CONFIRMADO' ? '#4FA953' : data?.statusProtesto === 'DEVOLVIDO' ? '#DFB924' :data?.statusProtesto === 'PAGO' ? '#4FA953' : data?.statusProtesto === 'RETIRADO' ? '#4A494A' : data?.statusProtesto === 'PROTESTADO' ? '#DFB924' : data?.statusProtesto === 'SUSTADO' ? '#FE431C' : data?.statusProtesto === 'CANCELADO' ? '#FE431C' : ''">
                                        {{data?.statusProtesto}}
                                        </span>
                                    </p>
                                </div>
                                <div class="row">
                                    <div class="col-4" *ngIf="informacoesProtesto?.cartorio">
                                        <p *ngIf="informacoesProtesto?.cartorio">
                                            <span class="negrito" style="font-size: 16px;">Cartorio:</span>
                                        </p>
                                            <p *ngIf="informacoesProtesto?.cartorio?.numero" style="padding-left: 20px;">
                                                <span class="negrito">Número:</span> {{informacoesProtesto?.cartorio?.numero}} 
                                            </p>
                                            <p *ngIf="informacoesProtesto?.cartorio?.nome" style="padding-left: 20px;">
                                                <span class="negrito">Nome:</span> {{informacoesProtesto?.cartorio?.nome}} 
                                            </p>
                                            <p *ngIf="informacoesProtesto?.cartorio?.comarca"  style="padding-left: 20px;">
                                                <span class="negrito">Comarca:</span> {{informacoesProtesto?.cartorio?.comarca}} 
                                            </p>
                                            <p *ngIf="informacoesProtesto?.cartorio?.cidade"  style="padding-left: 20px;">
                                                <span class="negrito">Cidade:</span> {{informacoesProtesto?.cartorio?.cidade}} - {{informacoesProtesto?.cartorio?.uf}} 
                                            </p>
                                        <p *ngIf="informacoesProtesto?.cartorio?.protocolo">
                                            <span class="negrito" style="font-size: 16px;">Protocolo</span>
                                        </p>
                                            <p *ngIf="informacoesProtesto?.cartorio?.protocolo" style="padding-left: 20px;">
                                                <span class="negrito">Número:</span>  {{informacoesProtesto?.cartorio?.protocolo}}
                                            </p>
                                            <p *ngIf="informacoesProtesto?.cartorio?.cartorioData" style="padding-left: 20px;">
                                                <span class="negrito">Data:</span> {{informacoesProtesto?.cartorio?.cartorioData | date:'dd/MM/yyyy'}}
                                            </p>
                                    </div>
                                    <div *ngIf="informacoesProtesto?.custas" class="col-4">
                                        <p *ngIf="informacoesProtesto?.custas" >
                                            <span class="negrito" style="font-size: 16px;">Custas:</span>
                                        </p>
                                            <p *ngIf="informacoesProtesto?.custas?.confirmacao" style="padding-left: 20px;">
                                                <span class="negrito">Confirmação:</span> {{informacoesProtesto?.custas?.confirmacao | currency: 'BRL': 'R$'}}
                                            </p>
                                            <p *ngIf="informacoesProtesto?.custas?.gravacao"  style="padding-left: 20px;">
                                                <span class="negrito">Gravação:</span> {{informacoesProtesto?.custas?.gravacao | currency: 'BRL': 'R$'}}
                                            </p>
                                            <p *ngIf="informacoesProtesto?.custas?.gravacaoEletronica"  style="padding-left: 20px;">
                                                <span class="negrito">Gravação Eletrônica:</span> {{informacoesProtesto?.custas?.gravacaoEletronica | currency: 'BRL': 'R$'}}
                                            </p>
                                            <p *ngIf="informacoesProtesto?.custas?.despesas"  style="padding-left: 20px;">
                                                <span class="negrito">Despesas:</span> {{informacoesProtesto?.custas?.despesas | currency: 'BRL': 'R$'}}
                                            </p>
                                            <p *ngIf="informacoesProtesto?.custas?.retorno"  style="padding-left: 20px;">
                                                <span class="negrito">Retorno:</span> {{informacoesProtesto?.custas?.retorno | currency: 'BRL': 'R$'}} 
                                            </p>
                                            <p *ngIf="informacoesProtesto?.custas?.cartorio"  style="padding-left: 20px;">
                                                <span class="negrito">Cartório:</span> {{informacoesProtesto?.custas?.cartorio | currency: 'BRL': 'R$'}} 
                                            </p>
                                            <p *ngIf="informacoesProtesto?.custas?.distribuicaoConfirmacao"  style="padding-left: 20px;">
                                                <span class="negrito">Distribuição Confirmação:</span> {{informacoesProtesto?.custas?.distribuicaoConfirmacao | currency: 'BRL': 'R$'}}
                                            </p>
                                            <p *ngIf="informacoesProtesto?.custas?.distribuicaoRetorno" style="padding-left: 20px;">
                                                <span class="negrito">Distribuição Retorno:</span> {{informacoesProtesto?.custas?.distribuicaoRetorno | currency: 'BRL': 'R$'}}
                                            </p>
                                            <p *ngIf="informacoesProtesto?.custas?.distribuidor" style="padding-left: 20px;">
                                                <span class="negrito">Distribuidor:</span> {{informacoesProtesto?.custas?.distribuidor | currency: 'BRL': 'R$'}}
                                            </p>
                                    </div>
                                    <div *ngIf="informacoesProtesto?.valorRepasse || informacoesProtesto?.dataRepasse" class="col-4">
                                        <p>
                                            <span class="negrito" style="font-size: 16px;">Repasse</span>
                                        </p>
                                            <p *ngIf="informacoesProtesto?.valorRepasse" style="padding-left: 20px;">
                                                <span class="negrito">Valor:</span> {{informacoesProtesto?.valorRepasse | currency: 'BRL': 'R$'}}
                                            </p>
                                            <p  *ngIf="informacoesProtesto?.dataRepasse" style="padding-left: 20px;">
                                                <span class="negrito">Data:</span> {{informacoesProtesto?.dataRepasse | date:'dd/MM/yyyy'}}
                                            </p>
                                    </div>
                                </div>
                            </div>
                            <!-- Protesto -->

                            <!-- Ocorrências do Protesto -->
                            <h3 *ngIf="informacoesProtesto?.ocorrencias.length > 0" class="card-title corPrimaria" style="font-weight: 600;  margin-top: 20px;">Ocorrências do Protesto</h3>
                            <div *ngIf="informacoesProtesto?.ocorrencias.length > 0" class="box-body">
                                <div class="table-responsive">
                                    <app-listar-ocorrencias-protesto [listaOcorrenciasProtesto] = "informacoesProtesto?.ocorrencias"></app-listar-ocorrencias-protesto>
                                </div>
                            </div>
                            <!-- Ocorrências do Protesto -->

                            <!-- Parcelas / Recorrente (Assinaturas) -->
                            <h3 *ngIf="data.parcelasCobranca.length > 0" class="card-title corPrimaria"
                                style="font-weight: 600;margin-top: 20px;">
                                {{ data.tipoCobranca !== 'assinatura' ? 'Parcelas' : 'Recorrente (Assinaturas)' }}</h3>
                            <div *ngIf="data.parcelasCobranca.length > 0" class="box-body">
                                <app-tabela [data]="data.parcelasCobranca" [tipoCobranca]="data.tipoCobranca"
                                    [cobrancaId]="cobrancaId">
                                </app-tabela>
                                <br />
                                <span class="negrito">Você está visualizando {{ data.parcelasCobranca.length }}
                                    cobranças de
                                    um total real de
                                    {{ (data.parcelasCobranca.length * data.valorOriginal) | currency: 'BRL': 'R$' }}
                                    e com um total pago de {{ getValorTotalPago() | currency: 'BRL': 'R$' }}
                                </span>
                            </div>
                            <!-- Parcelas / Recorrente (Assinaturas) -->

                            <!-- Mensagems Enviadas -->
                            <h3 class="card-title corPrimaria" style="font-weight: 600;  margin-top: 20px;">Mensagens
                                enviadas</h3>
                            <div class="box-body">
                                <div class="table-responsive">
                                    <app-listar-mensagens [idCobranca]="dataDialog.idCobranca"></app-listar-mensagens>
                                </div>
                            </div>
                            <!-- Mensagems Enviadas -->

                            <!-- Lastro -->
                            <h3 *ngIf="lastros?.totalElements > 1" class="card-title corPrimaria"
                                style="font-weight: 600;  margin-top: 20px;">Lastro
                            </h3>
                            <div class="box-body" *ngIf="lastros?.totalElements > 1">
                                <div class="table-responsive">
                                    <app-listar-lastro [idCobranca]="dataDialog.idCobranca"></app-listar-lastro>
                                </div>
                            </div>
                            <!-- Lastro -->

                            <!-- Informações da Fatura -->
                            <div *ngIf="data.emailUsuarioCriou || data.emailUsuarioExcluiu">
                                <h3 class="card-title corPrimaria" style="font-weight: 600;  margin-top: 20px;">
                                    Informações da Fatura
                                </h3>
                                <div id="infos" class="box-body">
                                    <div class="table-responsive">
                                        <table style="width: 100%" id="tabela">
                                            <tr
                                                style="background-color: #e0e0e0; border-bottom: 1px solid rgba(0,0,0,.12);">
                                                <th style="padding-left: 24px;">Data</th>
                                                <th>Usuário</th>
                                                <th>Situação</th>
                                                <!-- <th *ngIf="data.dataDesativacao">Excluida por</th> -->
                                            </tr>

                                            <tr *ngIf="data.emailUsuarioCriou">
                                                <td style="padding-left: 24px;">
                                                    <span>
                                                        {{ data.criadoEm | date:'dd/MM/yyyy HH:mm' }}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span>
                                                        {{data.emailUsuarioCriou}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="bordinha" [style.background-color]="'#DFB924'">FATURA
                                                        CRIADA
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr *ngIf="data.emailUsuarioExcluiu">
                                                <td style="padding-left: 24px;">
                                                    <span>
                                                        {{ data.dataDesativacao | date:'dd/MM/yyyy HH:mm' }}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span>
                                                        {{ data.emailUsuarioExcluiu}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="bordinha" [style.background-color]="'#4A494A'">FATURA
                                                        EXCLUÍDA
                                                    </span>
                                                </td>
                                                <!--  <td >
                                                <p *ngIf="!data.valorPagoEmDinheiro && !(status === 'FATURA EXCLUÍDA')">
                                                    <span class="negrito">Valor pago: </span>
                                                    {{ status === 'PAGAMENTO EFETUADO' ? (data.valorRecalculado | currency: 'BRL': 'R$') : status === 'FATURA EXCLUÍDA' ? 'Fatura Excluída' : 'Aguardando pagamento' }}
                                                </p>
                                                <p *ngIf="data.valorPagoEmDinheiro">
                                                    <span class="negrito">Valor pago em dinheiro: </span>
                                                    {{ (data.valorPagoEmDinheiro | currency: 'BRL': 'R$') }} <i
                                                        *ngIf="data.valorPagoEmDinheiro != data.valorOriginal"
                                                        class="fas fa-info-circle" style="margin-left: 6px;"
                                                        matTooltip="Verifique com o seu cliente o motivo da cobrança ter sido paga com um valor diferente do original.">
                                                    </i>
                                                </p>
                                                <p>
                                                    <span class="negrito">Valor real: </span>
                                                    {{ data.valorOriginal | currency: 'BRL': 'R$' }}
                                                </p>
                                            </td>
                                            <td>
                                                {{ usuarioCriou?.email }}
                                            </td>
                                            <td *ngIf="data.dataDesativacao">
                                                {{ usuarioExcluiu?.email }}
                                            </td> -->

                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- Informações da Fatura -->
                        </div>

                        <div *ngIf="!processando && data === null" class="card-body"
                            style="padding-top: 1.8rem !important; text-align: center;">

                            <i class="fas fa-triangle-exclamation" style="font-size:6em;"></i>
                            <h2 style="padding-top: 1em;">Cobrança não encontrada</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>