import { PermissaoGuard } from './guard/permissao.guard';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgPipesModule } from 'ngx-pipes';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { DateFnsModule, DateFnsConfigurationService } from 'ngx-date-fns';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { pt } from 'date-fns/locale';
import { ToastrModule } from 'ngx-toastr';

const ptConfig = new DateFnsConfigurationService();
ptConfig.setLocale(pt);

registerLocaleData(localePt, 'pt-BR');

import { AppComponent } from './app.component';
import { SidebarModule } from '@pages/layouts/admin/sidebar/sidebar.module';
import { FooterModule } from '@pages/layouts/admin/footer/footer.module';
import { NavbarModule } from '@pages/layouts/admin/navbar/navbar.module';
import { AdminLayoutComponent } from '@pages/layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from '@pages/layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpsRequestInterceptor } from './service/http-request.interceptor';
import { CustomErrorHandler } from './service/exceptions/error-handler.interceptor';
import { MaterialModule } from './material.module';
import { RequestCache } from './service/request-cache.service';
import { LoaderModule } from '@components/loader/loader.module';
import { VisualizarCobrancaComponent } from './pages/interno/cobranca/visualizar-cobranca/visualizar-cobranca.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { ModulosHabilitadosComponent } from './components/modulos-habilitados/modulos-habilitados.component';
import { PersonalizacaoSistemaComponent } from './components/personalizacao-sistema/personalizacao-sistema.component';
import { ImportacoesComponent } from './components/importacoes/importacoes.component';
import { translatePaginator } from './service/util/translate-paginator.service';
import { PersonalizaoGeralComponent } from './components/personalizao-geral/personalizao-geral.component';
import { DocumentacaoModule } from './components/documentacao/documentacao.module';
import { PersonalizacaoInvestimentosComponent } from './components/personalizacao-investimentos/personalizacao-investimentos.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PersonalizacaoEmprestimoComponent } from './components/personalizacao-emprestimo/personalizacao-emprestimo.component';
import { AtendimentoClienteComponent } from './components/atendimento-cliente/atendimento-cliente.component';
import { NotificacaoModule } from './components/notificacao/notificacao.module';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    SweetAlert2Module.forRoot(),
    NgxCaptchaModule,
    NgPipesModule,
    DateFnsModule.forRoot(),
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
    }),
    HttpClientModule,
    MaterialModule,
    MatNativeDateModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    LoaderModule,
    HighchartsChartModule,
    DocumentacaoModule,
    AngularEditorModule,
    NotificacaoModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true,
    },
    { provide: DateFnsConfigurationService, useValue: ptConfig },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {},
    },
    RequestCache,
    PermissaoGuard,
    { provide: MatPaginatorIntl, useValue: translatePaginator() },
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ModulosHabilitadosComponent,
    PersonalizaoGeralComponent,
    PersonalizacaoSistemaComponent,
    ImportacoesComponent,
    PersonalizacaoInvestimentosComponent,
    PersonalizacaoEmprestimoComponent,
    AtendimentoClienteComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
