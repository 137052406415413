<div
  style="width: 100%; display: flex; justify-content: space-between;background-color: #ffffff; padding: 10px 10px 0px; margin-bottom: 15px;">
  <div class="col-3" style="display: flex;">
    <div style="margin-top: 0.2%; margin-right: 0.6%;">
      <i class="fas fa-building fa" style="font-size: 1.9em !important; color: rgba(0,0,0,.3);"></i>
    </div>
    <span id="contato" [matTooltip]="empresaLogada.pessoa.razaoSocial ||  empresaLogada.pessoa.nome"
      *ngIf="tipoCadastro==='contato'">
      <span>{{ empresaLogada.pessoa.razaoSocial || empresaLogada.pessoa.nome }}</span>
      <p>{{ stringFormat.adicionarPontosDocumento(empresaLogada.pessoa.documento) }}</p>
    </span>
    <span id="contato" [matTooltip]="data.pessoa.razaoSocial ||  data.pessoa.nome" *ngIf="tipoCadastro!=='contato'">
      <span>{{ data.pessoa.razaoSocial || data.pessoa.nome }}</span>
      <p>{{ stringFormat.adicionarPontosDocumento(data.pessoa.documento) }}</p>
    </span>
  </div>

  <h2 class="col-6 corPrimaria" *ngIf="tipoCadastro !== 'contato'; else elseContato" mat-dialog-title
    style=" font-weight: 600; text-align: center">Cadastro</h2>
  <div class="col-3" style="text-align: end">
    <button class="btn-close" (click)="fecha()">
      <i class="material-icons">close</i>
    </button>
  </div>

</div>

<ng-template #elseContato>
  <h2 mat-dialog-title class="corPrimaria" style=" font-weight: 600; text-align: center">Cadastre seu contato</h2>
</ng-template>

<form [formGroup]="form" (ngSubmit)="finalizar()" style="overflow: hidden;">
  <div mat-dialog-content class="div-body" style="background: #EEEEEE;">
    <div class="main-content" style="background: #EEEEEE;">
      <app-pessoa-core-editar [data]="data" [tipoCadastro]="tipoCadastro" [id_corporacao]="id_corporacao"
        [ehPix]="ehPix">
      </app-pessoa-core-editar>
    </div>
  </div> <!-- fecha dialog-content -->
  <div mat-dialog-actions>
    <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div class="row botao-centro">
      <div class="col-12 col-md-4">
        <button type="button" (click)="fecha()" class="btn btn-default btn-block" style="color: #fff;"
          mat-raised-button>
          Cancelar
        </button>
      </div>
      <div class="col-12 col-md-4">
        <button type="submit" class="btn btn-block fundoCorPrimaria" style=" color: #fff;" mat-raised-button
          [disabled]="form.invalid || !authEmpresaAtt && !ehOperador">
          Cadastrar
        </button>
      </div>
    </div>
  </div> <!-- fecha dialog-actions -->
</form>