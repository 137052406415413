import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  AbstractControl,
} from '@angular/forms';
import { BuscaCepDTO } from '@project/src/app/model/apionboard/busca-cep-dto.model';
import { UtilCaniveteService } from '@project/src/app/service/util/util-canivete.service';
import { ConsultasEndpointService } from '@service/consultas-endpoint.service';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-endereco',
  templateUrl: './endereco.component.html',
  styleUrls: ['./endereco.component.less'],
})
export class EnderecoComponent implements OnInit, OnDestroy {
  pagina = {
    temEnderecoPrincipal: true,
    temEnderecoCobranca: false,
    temEnderecoEntrega: false,
  };

  estados: string[] = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  contador = 0; // só pode ter 3 endereços
  processando = false;
  temPrincipal = false;

  @Input()
  public form: FormArray;

  @Input()
  public data: any;

  @Input()
  public multiplosEnderecos: boolean = true;
  errors: any;

  constructor(
    private fb: FormBuilder,
    private consulta: ConsultasEndpointService,
    private caniveteService: UtilCaniveteService,
  ) {}

  ngOnInit() {
    if (this.data && this.data.enderecos && this.data.enderecos.length > 0) {
      this.data.enderecos.forEach((element) => this.add(element));
    } else if (this.form.controls.length === 0) {
      this.add();
      this.form.controls[0].get('principal').setValue(true);
      this.form.controls[0].get('principal').enable();
    }
    this.temPrincipal = true;
  }

  ngOnDestroy() {}

  buscaEndereco(endereco: AbstractControl) {
    this.loading(endereco);

    this.consulta
      .buscarCep(endereco.get('cep').value)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: BuscaCepDTO) => {
          if(response.tipologia && response.enderecoPrincipal && response.bairro && response.cidade && response.estado){
            const titulo = response.titulo ? ` ${response.titulo}` : ''
            endereco.get('logradouro').setValue(this.caniveteService.capitalizeFirstLetterOfEachWord(response.tipologia + titulo + ' ' + response.enderecoPrincipal));
            endereco.get('bairro').setValue(this.caniveteService.capitalizeFirstLetterOfEachWord(response.bairro));
            endereco.get('cidade').setValue(this.caniveteService.capitalizeFirstLetterOfEachWord(response.cidade));
            endereco.get('estado').setValue(response.estado);
            endereco.get('codigo_ibge').setValue(response.codigoIBGECidade);
            endereco.get('complemento').setValue('');
            endereco.get('principal').setValue(true);
            endereco.get('numero').markAllAsTouched();
          }else{
            endereco.patchValue({
              logradouro: '',
              bairro: '',
              cidade: '',
              estado: '',
              codigo_ibge: '',
              complemento: '',
              numero: ''
            })
          };
          this.disloading(endereco);
        },
        (error) => {
          console.error(error.status);
          this.disloading(endereco);
        }
      );
  }

  loading(endereco) {
    this.processando = true;
    endereco.disable();
  }

  disloading(endereco) {
    this.processando = false;
    endereco.enable();
  }

  public add(endereco?: any) {
    if (this.contador <= 3) {
      endereco = endereco || {};
      this.form.push(
        this.fb.group(
          {
            principal: this.fb.control(
              {
                value: endereco.principal || false,
                disabled:
                  endereco.principal === true || this.temPrincipal === false
                    ? false
                    : true,
              },
              [Validators.required]
            ),
            id: this.fb.control(endereco.id || null),
            cep: this.fb.control(endereco.cep, [
              Validators.required,
              Validators.minLength(8),
            ]),
            logradouro: this.fb.control(endereco.logradouro, [
              Validators.required,
            ]),
            numero: this.fb.control(endereco.numero, [Validators.required]),
            complemento: this.fb.control(endereco.complemento),
            bairro: this.fb.control(endereco.bairro, [Validators.required]),
            cidade: this.fb.control(endereco.cidade, [
              Validators.required,
              Validators.maxLength(20),
            ]),
            estado: this.fb.control(endereco.estado, [Validators.required]),
            codigo_ibge: this.fb.control(endereco.codigo_ibge),
          },
          {
            validator: this.obrigatorioTerPrincipal('principal'),
          }
        )
      );
      this.contador++;
    }
  }

  obrigatorioTerPrincipal(principal) {
    return (group: FormGroup) => {
      const principalInput = group.controls[principal];

      let temPrincipal = false;
      this.form.controls.forEach((element) => {
        if (element.get('principal').value === true) {
          temPrincipal = true;
          return principalInput.setErrors(null);
        }
      });
      if (temPrincipal === false) {
        return principalInput.setErrors({ obrigatorio: true });
      }
    };
  }

  changeContaPrincipal(event?) {
    this.temPrincipal = !this.temPrincipal;

    if (this.temPrincipal === true) {
      this.form.controls.forEach((element) => {
        if (element.get('principal').value === false) {
          element.get('principal').disable();
        }
      });
    } else {
      this.form.controls.forEach((element) => {
        element.get('principal').enable();
      });
    }
  }

  public del(index) {
    if (this.form.controls[index].get('principal').value === true) {
      this.changeContaPrincipal();
    }
    this.form.removeAt(index);
    this.contador--;
  }

  public esvazia() { // esvazia até sobrar apenas 1
		while (this.form.length > 1) { 
      this.form.removeAt(1);
      this.contador--;
    }
	}

  getErrorMessage(form: FormGroup, field) {
    if (form.get(field)) {
      return form.get(field).hasError('required')
        ? 'Campo requerido'
        : form.get(field).hasError('minlength')
        ? 'Campo inválido'
        : form.get(field).hasError('maxlength')
        ? 'Tamanho máximo excedido'
        : form.get(field).hasError('obrigatorio')
        ? 'Deve haver um principal'
        : '';
    }
  }

  isValid(form: FormGroup, field) {
    if (form.get(field)) {
      if (form.get(field).value === '' || form.get(field).value === null) {
        return false;
      }
      return form.get(field).valid;
    }
  }
}
