import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EmailSistemaEndpointService } from '../../service/email-sistema-endpoint.service';
import Swal from 'sweetalert2';
import { EmailSistemaDTO } from '../../model/email-sistema-dto.model';

@Component({
  selector: 'app-email-sistema',
  templateUrl: './email-sistema.component.html',
  styleUrls: ['./email-sistema.component.less']
})
export class EmailSistemaComponent implements OnInit {
  form: FormGroup;
  saveObj: object;
  todosEmails: EmailSistemaDTO;
  isFormValid: boolean = false;
  processando: boolean = false;
  dominio: string = '@movvime.com.br';
  tipos: string[] = ['comercial', 'admin', 'suporte', 'financeiro', 'compliance']

  constructor(
    public dialogRef: MatDialogRef<EmailSistemaComponent>,
    public formBuilder: FormBuilder,
    public emailSistema: EmailSistemaEndpointService,
  ) { }

  ngOnInit() {
    this.formsEmails();
    this.listarTodos();

    this.form.statusChanges.subscribe(status => {
      this.isFormValid = status === 'VALID';
    });
  }

  formsEmails(): void {
    this.form = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.minLength(2)]),
      tipo: new FormControl('suporte'),
    });
  }

  salvar(): void {
    this.saveObj = {
      email: this.form.value.email.trim(),
      tipo: this.form.value.tipo,
    }
    this.emailSistema.cadastrarEmail(this.saveObj).subscribe((res) => {
      Swal.fire(
        "Sucesso",
        "O email foi cadastrado com sucesso",
        "success"
      ).then(() => {
        this.listarTodos();
      });
    },
      (error) => {
        Swal.fire(
          "Erro ao cadastrar email",
          error.error,
          "error"
        );
        console.error(error);
      }
    );
  }

  fecha(): void {
    this.dialogRef.close();
  }

  listarTodos(): void {
    this.processando = true;
    this.emailSistema
      .listarTodos()
      .subscribe((res) => {
        this.processando = false;
        this.todosEmails = res;
      }, error => {
        this.processando = false;
        Swal.fire(
          "Erro ao listar emails",
          error.error,
          "error"
        );
        console.error(error);
      });
  }

  ativar(idEmail): void {
    this.processando = true;
    this.emailSistema.ativarEmail(idEmail).subscribe((res) => {
      Swal.fire(
        "Sucesso",
        "O email foi ativado com sucesso",
        "success"
      ).then(() => {
        this.listarTodos();
        this.processando = false;
      });
    },
      (error) => {
        this.processando = false;
        Swal.fire(
          "Erro",
          "Ocorreu um erro ao ativar o email",
          "error"
        )
      }
    )
  }

  desativar(idEmail): void {
    this.processando = true;
    this.emailSistema.desativarEmail(idEmail).subscribe((res) => {
      Swal.fire(
        "Sucesso",
        "O email foi desativado com sucesso",
        "success"
      ).then(() => {
        this.listarTodos();
        this.processando = false;
      });
    },
      (error) => {
        this.processando = false;
        Swal.fire(
          "Erro",
          "Ocorreu um erro ao desativar o email",
          "error"
        )
      })
  }

  nomeTipo(tipo): string {
    switch (tipo) {
      case 'admin':
        return 'Administrador'
      case 'suporte':
        return 'Suporte'
      case 'financeiro':
        return 'Financeiro'
      case 'comercial':
        return 'Comercial'
      case 'compliance':
        return 'Compliance'
      case 'controladoria':
        return 'Controladoria'
      default:
        break;
    }
  }
}
