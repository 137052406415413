<form [formGroup]="form" class="mudaCor input-size">
  <div class="row">
    <div class="col-12">
      <mat-tab-group *ngIf="tipoCadastro !== 'contato'; else elseContato" [selectedIndex]="ehPix ? 3 : 0">
        <mat-tab>
          <ng-template mat-tab-label>
            <i class='fas fa-building' style="margin-right: 8px"></i>
            Informações
            <fa-icon
              *ngIf="form.get('geral')?.invalid || form.get('endereco')?.invalid || form.get('telefone')?.invalid || form.get('email')?.invalid "
              [icon]="['fas', 'triangle-exclamation']" class="alert-icon"
              matTooltip="Preencha todos os campos obrigatórios."> </fa-icon>
          </ng-template>
          <mat-tab-group>
            <mat-tab [label]="'Geral ' + (form.get('geral')?.invalid ? '!' : '')">
              <app-geral [faturamento]="data?.faturamento" [id_corporacao]="id_corporacao"
                [tipoEmpresa]="data?.tipoEmpresa" [data]="data?.pessoa || build(true).pessoa"
                [tipoCadastro]="tipoCadastro" (dadosDoCnpjCpfAmazon)="atualizaTudoAmazon($event)"
                [processando]="processando" (dadosDoCnpjBanco)="atualizaTudoBanco($event)" [form]="form.get('geral')">
              </app-geral>
            </mat-tab>

            <mat-tab [label]="'Endereço ' + (form.get('endereco')?.invalid ? '!' : '')">
              <app-endereco [data]="data?.pessoa || build(true).pessoa" [form]="form.get('endereco')"></app-endereco>
            </mat-tab>

            <mat-tab [label]="'Telefone ' + (form.get('telefone')?.invalid ? '!' : '')">
              <app-telefone [telaContato]="true" [data]="data?.pessoa || build(true).pessoa"
                [form]="form.get('telefone')"></app-telefone>
            </mat-tab>

            <mat-tab [label]="'E-mail ' + (form.get('email')?.invalid ? '!' : '')">
              <app-email [telaContato]="true" [data]="data?.pessoa || build(true).pessoa"
                [form]="form.get('email')"></app-email>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
        <mat-tab (ngModelChange)="enviarInformacao($event)">
          <ng-template mat-tab-label>
            <i class="fas fa-cog" style="margin-right: 8px"></i>
            Configurações
            <fa-icon *ngIf="form.get('autenticacao')?.invalid" [icon]="['fas', 'triangle-exclamation']"
              class="alert-icon" matTooltip="Preencha todos os campos obrigatórios."> </fa-icon>
          </ng-template>
          <mat-tab-group>
            <mat-tab label="Notificações">
              <app-notificacoes [data]="data?.configuracao || build(true).configuracao"
                [form]="form.get('notificacoes')">
              </app-notificacoes>
            </mat-tab>
            <mat-tab [label]="'Autenticação ' + (form.get('autenticacao')?.invalid ? '!' : '')">
              <app-autenticacao [data]="data?.configuracao || build(true).configuracao"
                [form]="form.get('autenticacao')">
              </app-autenticacao>
            </mat-tab>
            <mat-tab label="Integração">
              <app-integracao [data]="data || build(true)"></app-integracao>
            </mat-tab>
            <mat-tab label="Preços e Taxas">
              <app-plano-empresa [data]="data?.configuracao || build(true).configuracao" [tipoConta]="data?.tipoConta"
                [empresaId]="data?.id"></app-plano-empresa>
            </mat-tab>

            <mat-tab label="Juros e Desconto" *ngIf="jurosDescontos">
              <app-juros-desconto [data]="data?.configuracao || build(true).configuracao" [tipoConta]="data?.tipoConta" [dadosEmpresa]="data"
                [form]="form.get('jurosDesconto')">
              </app-juros-desconto>
            </mat-tab>

            <mat-tab label="Encerrar Conta">
              <app-encerrar-conta [data]="data || build(true).configuracao" [empresaId]="data?.id">
              </app-encerrar-conta>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>

        <mat-tab *ngIf="listarPix && env?.modulos?.pix?.transferenciaPix && areaPix">
          <ng-template mat-tab-label>
            <i class="fa-brands fa-pix" style="font-size: 15px; margin-right: 8px"></i>
            Área Pix
          </ng-template>
          <mat-tab-group>
            <mat-tab label="Chaves Pix">
              <app-chaves-pix [data]="data || build(true).configuracao" [empresaId]="data?.id" *ngIf="listarPix">
              </app-chaves-pix>
            </mat-tab>
            <mat-tab label="Limites Pix">
              <app-limite-pix [data]="data || build(true).configuracao" [empresaId]="data?.id" *ngIf="listarPix">
              </app-limite-pix>
            </mat-tab>
            <mat-tab label="Portabilidade">
              <app-portabilidade-pix [data]="data || build(true).configuracao" [empresaId]="data?.id" *ngIf="listarPix">
              </app-portabilidade-pix>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
      </mat-tab-group>

      <ng-template #elseContato>
        <mat-tab-group>
          <mat-tab [label]="'Informações'">
            <mat-tab-group>

              <mat-tab [label]="'Geral ' + (form.get('geral')?.invalid ? '!':'')">
                <app-geral [data]="data?.pessoa || build(true).pessoa" [tipoCadastro]="tipoCadastro"
                  [processando]="processando" [id_corporacao]="id_corporacao"
                  (dadosDoCnpjCpfAmazon)="atualizaTudoAmazon($event)" [form]="form.get('geral')"
                  (dadosDoCnpjBanco)="atualizaTudoBanco($event)" (cpfCnpj)="capturarCpfCnpj($event)"></app-geral>
              </mat-tab>

              <mat-tab [label]="'Endereço ' + (form.get('endereco')?.invalid ? '!' : '')">
                <app-endereco [data]="data?.pessoa || build(true).pessoa" [form]="form.get('endereco')"></app-endereco>
              </mat-tab>

              <mat-tab [label]="'Telefone ' + (form.get('telefone')?.invalid ? '!' : '')">
                <app-telefone [telaContato]="true" [data]="data?.pessoa || build(true).pessoa"
                  [form]="form.get('telefone')"></app-telefone>
              </mat-tab>

              <mat-tab [label]="'E-mail ' + (form.get('email')?.invalid ? '!' : '')">
                <app-email [telaContato]="true" [data]="data?.pessoa || build(true).pessoa"
                  [form]="form.get('email')"></app-email>
              </mat-tab>

              <mat-tab label="Limites">
                <app-limites [telaContato]="true" [data]="data?.pessoa || build(true).pessoa"
                  [form]="form.get('configuracao')"></app-limites>
              </mat-tab>
            </mat-tab-group>
          </mat-tab>

          <mat-tab [disabled]="processando" *ngIf="tipoCadastro === 'contato'"
            [label]="'Contas Bancárias ' + (form.get('dadosBancarios')?.invalid ? '!' : '')">
            <app-dados-bancarios [data]="data?.pessoa || build(true).pessoa" [form]="form.get('dadosBancarios')"
              [documentoPreenchido]="form.get('geral.documento').valid" [formGeral]="form.get('geral')">
            </app-dados-bancarios>
          </mat-tab>

          <mat-tab label="Chaves Pix" *ngIf="env?.modulos?.pix?.transferenciaPix && areaPix">
            <app-chaves-pix-contato [data]="data || build(true).pessoa" [form]="form.get('chavePix')"
              [empresaId]="data?.id" *ngIf="listarPix">
            </app-chaves-pix-contato>
          </mat-tab>

        </mat-tab-group>
      </ng-template>
    </div>
  </div>
</form>