<h2 mat-dialog-title class="titulo-modal pl-4">
    <div id="avatar-icon" *ngIf="dataDialog.apenasCadastroPessoa">
        <i class="fas fa-building fa" style="font-size: 0.8em !important;"></i>
    </div>
    <span id="empresa-logada" *ngIf="dataDialog.apenasCadastroPessoa"
        [matTooltip]="empresaLogada.pessoa.razaoSocial ||  empresaLogada.pessoa.nome">
        <span>{{ empresaLogada.pessoa.razaoSocial || empresaLogada.pessoa.nome }}</span>
        <p>{{ stringFormat.adicionarPontosDocumento(empresaLogada.pessoa.documento) }}</p>
    </span>
    {{ dataDialog.apenasCadastroPessoa ? 'Cadastro' : 'Boas-vindas à ' + env.nomeApp + '!' }}
    <i (click)="fecha()" class="material-icons btn-fechar">close</i>
    <span id="fechar" (click)="fecha()">FECHAR</span>
</h2>


<div mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
    <div class="row">
        <div class="col-9 horizontal-stepper">
            <mat-horizontal-stepper class="top-stp" [linear]=false (selectionChange)="onStepChange($event)"
                #elementstepper1>
                <mat-step [editable]="true" state="done">
                    <app-stepper1 [form]="form.get('formStepper1')" (atualizarForm)="atualizarForm($event)"
                        (changeUserInfo)="changeUserInfo($event)" (fecha)="fecha()">
                    </app-stepper1>
                </mat-step>
                <mat-step [editable]="true" state="done">
                    <app-stepper2 *ngIf="elementstepper1.selectedIndex === 1" [form]="form.get('formStepper2')"
                        (atualizarForm)="atualizarForm($event)">
                    </app-stepper2>
                </mat-step>
                <mat-step [editable]="true" state="done">
                    <app-stepper-informacoes-financeiras [form]="form.get('formStepperInformacoesFinanceiras')"
                        (atualizar)="atualizar($event)" (atualizarForm)="atualizarForm($event)">
                    </app-stepper-informacoes-financeiras>
                </mat-step>
                <mat-step [editable]="true" state="done">
                    <app-stepper3 [form]="form.get('formStepper3')" (atualizar)="atualizar($event)"
                        (atualizarForm)="atualizarForm($event)"></app-stepper3>
                </mat-step>
                <mat-step [editable]="true" state="done" *ngIf="form.get('formStepper3.tipoConta').value == 'pj'">
                    <app-stepper4 *ngIf="elementstepper1.selectedIndex === 4" [form]="form.get('formStepper4')"
                        [dadosRecuperados]="dadosRecuperados" (atualizarForm)="atualizarForm($event)"
                        [tipoConta]="form.get('formStepper3.tipoConta').value">
                    </app-stepper4>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
        <div class="col-3 resumo" style="height:60vh;overflow-y: auto;">
            <p class="super-title">{{ dataDialog.apenasCadastroPessoa ? 'RESUMO DO CADASTRO' : 'OLÁ! VAMOS COMEÇAR?' }}
            </p>
            <div *ngIf="!dataDialog.apenasCadastroPessoa">
                <p class="menor-title">Resumo da sua conta: </p>
                <p *ngIf="usuarioLogado.emailConfirmado === true">- E-mail confirmado</p>
                <p *ngIf="usuarioLogado.celularConfirmado === true">- Celular confirmado</p>
                <p *ngIf="usuarioLogado.cpf">- Informações pessoais adicionadas</p>
                <br />
                <p>{{ usuarioLogado.nome || '' }}</p>
                <p>{{ usuarioLogado.email || '' }}</p>
                <p>({{usuarioLogado.celular.ddd}}) {{usuarioLogado.celular.numero || '' }}</p>
                <br />
                <div *ngIf="elementstepper1.selectedIndex > 0 && usuarioLogado.cpf === null">
                    <p *ngIf="form.get('formStepper2.cpf').valid">Seu CPF é
                        {{ stringFormat.adicionarPontosDocumento(form.get('formStepper2.cpf').value) || '' }}</p>
                    <p *ngIf="form.get('formStepper2.rg').valid">Seu RG é {{ form.get('formStepper2.rg').value || '' }}
                    </p>
                    <br />
                    <p *ngIf="form.get('formStepper2.endereco')?.value[0]?.cep">
                        {{ form.get('formStepper2.endereco').value[0].logradouro + ', ' +
                        (form.get('formStepper2.endereco').value[0].numero || ' ') +'
                        '+(form.get('formStepper2.endereco').value[0].complemento || ' ')+ ' - Bairro ' +
                        form.get('formStepper2.endereco').value[0].bairro + ', ' +
                        form.get('formStepper2.endereco').value[0].cidade + ' - ' +
                        form.get('formStepper2.endereco').value[0].estado || '' }}
                    </p>
                    <p *ngIf="form.get('formStepper2.endereco')?.value[0]?.cep">
                        {{form.get('formStepper2.endereco')?.value[0]?.cep}}</p>
                    <br />
                </div>
            </div>
            <div *ngIf="elementstepper1.selectedIndex > 3">
                <div *ngIf="dataDialog.apenasCadastroPessoa || form.get('formStepper3.tipoConta').value === 'pj'">
                    <p>{{ form.get('formStepper4.geral.nome').value + ' - ' +
                        form.get('formStepper4.geral.documento').value || '' }}
                    </p>
                    <p *ngIf="form.get('formStepper3.tipoConta').value == 'pj'">
                        {{ form.get('formStepper4.geral.razaoSocial').value || '' }}</p>
                    <p *ngIf="form.get('formStepper3.tipoConta').value == 'pj'">
                        {{ form.get('formStepper4.geral.site').value || '' }}</p>
                    <p *ngIf="form.get('formStepper3.tipoConta').value == 'pj'">
                        {{ form.get('formStepper4.geral.tipoEmpresa').value | tipoEmpresa }}</p>
                    <p *ngIf="form.get('formStepper3.tipoConta').value == 'pj'">
                        {{ form.get('formStepper4.geral.faturamento').value | currency: 'BRL': 'R$' || '' }}</p>
                    <p *ngIf="form.get('formStepper4.enderecos').value[0].cep">
                        {{ form.get('formStepper4.enderecos').value[0].logradouro + ', ' +
                        (form.get('formStepper4.enderecos').value[0].numero || ' ') + ' - Bairro ' +
                        form.get('formStepper4.enderecos').value[0].bairro + ', ' +
                        form.get('formStepper4.enderecos').value[0].cidade + ' - ' +
                        form.get('formStepper4.enderecos').value[0].estado || '' }}
                    </p>
                    <p>{{ form.get('formStepper4.telefones').value[0].ddd + '' + form.get('formStepper4.telefones').value[0].numero | telefone }}</p>
                    <p>{{ form.get('formStepper4.emails').value[0].email || '' }}</p>
                </div>

                <div *ngIf="form.get('formStepper3.tipoConta').value === 'pj'" class="mt-3">
                    <br />
                    <div *ngFor="let representante of form.get('formStepper4.representantes')?.value; index as i">
                        <p>{{representante.nome}} - {{representante.tipoRepresentante ? 'Sócio':'Representante Legar'}}
                        </p>
                    </div>
                </div>
            </div>
            <p style="font-size: 10px;">Dúvidas? <a (click)="gerenteModal()" href="javascript:void(0);">Fale com o seu
                    gerente de contas</a></p>
        </div>
    </div>
</div>
<div mat-dialog-actions class="footer-modal">
    <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div class="row espacamento">
        <div class="col-12 col-md-2">
            <button
                *ngIf="(elementstepper1.selectedIndex > 1 && !dataDialog.apenasCadastroPessoa) || (elementstepper1.selectedIndex > 2 && dataDialog.apenasCadastroPessoa)"
                type="button" (click)="back()" class="btn btn-block btn-secundario corPrimaria bordaCorPrimaria"
                style="background-color: white; margin-top: 10% !important;" mat-raised-button>
                Passo Anterior
            </button>
        </div>

        <div class="col-12 col-md-8">
            <mat-horizontal-stepper class="bottom-stp" labelPosition="bottom" [linear]=false #elementstepper2>
                <mat-step [editable]="true" state="done">
                    <ng-template matStepLabel>Autenticar</ng-template>
                </mat-step>
                <mat-step [editable]="true" state="done">
                    <ng-template matStepLabel>Informações pessoais</ng-template>
                </mat-step>
                <mat-step [editable]="true" state="done">
                    <ng-template matStepLabel>Informações adicionais</ng-template>
                </mat-step>
                <mat-step [editable]="true" state="done">
                    <ng-template matStepLabel>Tipo cadastro</ng-template>
                </mat-step>
                <mat-step [editable]="true" state="done" *ngIf="form.get('formStepper3.tipoConta').value == 'pj'">
                    <ng-template matStepLabel>Dados da empresa</ng-template>
                </mat-step>

                <ng-template matStepperIcon="edit">
                    <i class="fa fa-check"></i>
                </ng-template>
                <ng-template matStepperIcon="number">
                    <i class="fa fa-circle"></i>
                </ng-template>
                <ng-template matStepperIcon="error">
                    <i class="fa fa-times-circle"></i>
                </ng-template>
            </mat-horizontal-stepper>
        </div>

        <div class="col-12 col-md-2">
            <button type="button" (click)="next()" class="btn btn-block btn-primario fundoCorPrimaria"
                style=" border-color: #ee9900;margin-top: 10%;" mat-raised-button [disabled]="processando">
                <fa-icon [icon]="['fas', 'spinner']" [pulse]="true" *ngIf="processando"></fa-icon>
                {{ elementstepper1.selectedIndex == 4 && form.get('formStepper3.tipoConta').value === 'pj' ? 'Concluir'
                : elementstepper1.selectedIndex == 3 && form.get('formStepper3.tipoConta').value === 'pf' ? 'Concluir'
                :'Próximo Passo' }}
            </button>
        </div>
    </div>
</div>