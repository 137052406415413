<div>
    <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
        HORÁRIO DE CADASTRO
        <i (click)="fecha()" class="material-icons btn-fechar" id="btn-close">close</i>
    </h2>
    <div mat-dialog-content class="mat-dialog-content-responsive">
        <div mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
            <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="mudaCor input-size row "
                style="background-color: white; margin: 0px 10px; border-radius: 5px;padding: 28px 0px; width: 95%;">
                <div class="card-body">
                    <div>
                        <div>
                            <label for="horaInicio" class="inicio">Horário de início</label>
                            <input type="time" class="form-control" id="horaInicio" placeholder="Hora Inicio"
                                [(ngModel)]="inicio" required [disabled]="disabilitaOpcoes">
                        </div>
                        <hr>
                        <div>
                            <label for="horaFinal" class="final">Horário de fim</label>
                            <input type="time" class="form-control" id="horaFinal" placeholder="Hora Final"
                                [(ngModel)]="fim" required [disabled]="disabilitaOpcoes">
                        </div>
                    </div>
                    <div style="margin-top: 25px; ">
                        <mat-checkbox [checked]="cadastroFds" (change)="setCadastroFds($event)"
                            [disabled]="disabilitaOpcoes"> <span class="final">Permitir
                                cadastros aos finais
                                de semanas e feriados</span>
                        </mat-checkbox>
                    </div>

                    <div style="margin-top: 25px; display: flex;">
                        <mat-checkbox [checked]="cadastroBloqueado" (change)="setCadastroBloqueado($event)"> <span
                                class="final">Bloquear novos cadastros</span>
                        </mat-checkbox>
                        <div class="col-1 col1-icone">
                            <i class="fas fa-info-circle info-icon" style="font-size: 8px;"
                                matTooltip="Ao marcar essa opção todos os cadastros serão bloqueados, inclusive via api"></i>
                        </div>
                    </div>

                    <div style="margin-top: 25px; display: flex;">
                        <mat-checkbox [checked]="cadastroBloqueado" (change)="setOcultarBotao($event)"
                            [disabled]="cadastroBloqueado"> <span class="final">Ocultar botão de cadastro</span>
                        </mat-checkbox>
                        <div class="col-1 col1-icone">
                            <i class="fas fa-info-circle info-icon" style="font-size: 8px;"
                                matTooltip="Essa opção oculta o botão de cadastro, porém cadastros via api e rota continuarão liberados"></i>
                        </div>
                    </div>
                </div>
                <div mat-dialog-actions
                    style="display: block;overflow: initial; margin: 0; padding: 8px 0 0;border-radius: 4px; margin: auto;">
                    <span class="div-interna flex-end" style=" align-items: center; padding: 0px 20px;border-radius: 4px;
                        margin: 0;">
                        <button (click)="cadastrarHorario()"
                            class="btn btn-default next fundoCorPrimaria">Salvar</button>
                    </span>
                </div>
            </div>
        </div>
    </div>