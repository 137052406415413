<div class="wrapper">
  <!-- <div class="sidebar" data-color="white" data-background-color="orange" data-image="./assets/img/sidebar-1.jpg"> -->
  <div class="sidebar" data-color="personalizado" data-background-color="personalizado">
    <app-sidebar-cmp></app-sidebar-cmp>
    <!-- <div class="sidebar-background" style="background-image: url(assets/img/sidebar-1.jpg)"></div> -->
  </div>
  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>
    <!-- <app-confirmar-dados *ngIf="!confirmouEmail"></app-confirmar-dados> -->
    <router-outlet></router-outlet>
    <div *ngIf="!isMap()">
      <app-footer-cmp></app-footer-cmp>
    </div>
  </div>
</div>