import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UsuarioEndpointService } from '@service/usuario-endpoint.service';
import { TrocarSenhaComponent } from './trocar-senha/trocar-senha.component';
import { untilDestroyed } from 'ngx-take-until-destroy';
import Swal from 'sweetalert2';
import { ValidacoesService } from '@project/src/app/service/validacoes.service';
import subYears from 'date-fns/subYears';
import { ModalConfirmaTokenComponent } from '@project/src/app/components/modal/modal-confirma-token/modal-confirma-token.component';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';
import { LocalizacaoComponent } from '@project/src/app/components/localizacao/localizacao.component';
import { PerguntasSeguranca } from '@project/src/app/model/enum.model';

@Component({
	selector: 'app-usuario-editar',
	templateUrl: './usuario-editar.component.html',
	styleUrls: ['./usuario-editar.component.less']
})
export class UsuarioEditarComponent implements OnInit, OnDestroy {
	[x: string]: any;

	public ehCadastroTelefone: boolean = false;
	public telefoneValido: boolean = false;
	public tamanhoValido: boolean = false;
	public tokenCorreto: boolean = false;
	public dataDezoitoAnos: Date = new Date();
	public dataDeHoje: Date = new Date();
	public dataMinima: Date = new Date();
	public numeroTelefone: string;
	public form: FormGroup;
	public formPerguntas: FormGroup;
	public data: any;
	public usuarioLogado: any;
	public tipoLocalizacao: any = "editarUsuario";
	public tipoLocalizacaoLiberado: any
	perguntasCadastradas = false;

	private validaTelefoneAtual: boolean = false;
	private numeroAtual: any;
	@ViewChild(LocalizacaoComponent, { static: false }) localizacaoComponent: LocalizacaoComponent;

	constructor(
		private fb: FormBuilder,
		private usuario: UsuarioEndpointService,
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<UsuarioEditarComponent>,
		private validacoesService: ValidacoesService,
		private usuarioService: UsuarioEndpointService,
		public autenticacao: AutenticacaoEndpointService
	) {
		dialogRef.disableClose = true;
		this.usuarioLogado = this.autenticacao.sessao.principal.usuario;
	}

	public ngOnInit() {
		this.form = this.fb.group({
			nome: new FormControl('', [Validators.required]),
			sobrenome: new FormControl('', [Validators.required]),
			numero: new FormControl('', [Validators.required]),
			dataNascimento: new FormControl('', [Validators.required]),
			estadoCivil: new FormControl('', [Validators.required]),
			email: new FormControl('', [Validators.required]),
			cpf: new FormControl('', [Validators.required]),
			rg: new FormControl('', [Validators.required]),
			orgaoEmissor: new FormControl('', [Validators.required]),
			nomeMae: new FormControl('', [Validators.required]),
			nomePai: new FormControl('', [Validators.required]),
			pessoaPoliticamenteExposta: new FormControl('', [Validators.required]),
			endereco: new FormArray([], [Validators.required]),
		});
		
		this.formPerguntas = this.fb.group({
			perguntasSeguranca: new FormArray([])
		})

		this.dataDezoitoAnos = subYears(new Date(), 18);
		this.dataMinima = subYears(new Date(), 120);
		this.getUsuario();
		this.form.get("cpf").setValidators([this.verificaCPF(this.form)]);
		this.form.get("numero").setValidators([this.verificaTelefone(this.form)]);

		this.usuario.listarPerguntasSeguranca(this.usuarioLogado.id).subscribe(
			(res)=>{
				if(res.length > 0){
					this.perguntasCadastradas = true;
				}else{
					this.addPergunta();
				}
			}
		)

		if (this.localizacaoService) {
			this.permissaoGeolocalizacao();
		}
	}

	public ngOnDestroy() { }

	getUsuario() {
		this.usuario
			.obterUsuarioLogado()
			.pipe(untilDestroyed(this))
			.subscribe(
				response => {
					this.data = response;
					this.data.enderecos = [];
					this.data.endereco.principal = true;
					this.data.enderecos.push(this.data.endereco);
					this.carregaForms(this.data);
					if (this.form.get("cpf").value != '' && this.form.get("cpf").value != null) {
						this.form.controls["cpf"].disable();
					}
					if (this.form.get("rg").value != '' && this.form.get("rg").value != null) {
						this.form.controls["rg"].disable();
					}
					if (this.form.get("orgaoEmissor").value != '' && this.form.get("orgaoEmissor").value != null) {
						this.form.controls["orgaoEmissor"].disable();
					}
					//Campo telefone desabilitado ou é edição de telefone ou não mexeu no telefone
					// if (this.form.controls["numero"].value != '' && this.form.controls["numero"].value != null) {
					// 	this.form.controls["numero"].disable();
					// 	this.ehCadastroTelefone = false;
					// }
					else {//campo telefone habilitado é 1º cadastro de telefone 
						this.ehCadastroTelefone = true;
					}

				},
				error => {
					Swal.fire({
						title: 'Erro!',
						text: error.error,
						icon: 'error',
					})
					console.error(error);
				}
			);
	}

	public carregaForms(data) {
		const usuario = data || {};
		let num: string = data.celular.ddd + "" + data.celular.numero;
		if (num.length === 10) {
			this.numeroTelefone = '(' + num.substring(0, 2) + ')' + num.substring(2, 6) + '-' + num.substring(6, 10);
		}
		else if (data.celular.ddd && data.celular.numero) {
			this.numeroTelefone = '(' + num.substring(0, 2) + ')' + num.substring(2, 7) + '-' + num.substring(7, 11);
		}
		else {
			this.numeroTelefone = '';
		}
		this.form.get('nome').setValue(usuario.nome);
		this.form.get('sobrenome').setValue(usuario.sobrenome);

		if (usuario.celular.ddd && usuario.celular.numero) {
			this.form.get('numero').setValue(usuario.celular.ddd + '' + usuario.celular.numero || '');
			this.numeroAtual = usuario.celular;
			this.telefoneValido = true;
		}

		this.form.addControl('celular', new FormControl(usuario.celular || '', [Validators.required]));
		this.form.get('email').setValue(usuario.email);
		this.form.addControl('id', new FormControl(usuario.id || '', [Validators.required]));
		if (usuario.dataNascimento !== null) {
			this.form.get('dataNascimento').setValue(new Date(usuario.dataNascimento).getFullYear() + "-" + this.adicionaZero(new Date(usuario.dataNascimento).getMonth() + 1) + "-" + this.adicionaZero(new Date(usuario.dataNascimento).getDate()));
			this.form.controls["dataNascimento"].disable();
		}
		this.form.get('estadoCivil').setValue(usuario.estadoCivil);
		this.form.get('cpf').setValue(usuario.cpf);
		this.form.get('rg').setValue(usuario.rg);
		this.form.get('orgaoEmissor').setValue(usuario.orgaoEmissor);
		this.form.get('nomeMae').setValue(usuario.nomeMae);
		this.form.get('nomePai').setValue(usuario.nomePai);
		this.form.get('pessoaPoliticamenteExposta').setValue(usuario.pessoaPoliticamenteExposta);
	}

	async openDialog() {
		// if (this.localizacaoComponent) {
		// 	this.localizacaoComponent.getLocationAndCheckPermission();

		// 	if (await this.localizacaoComponent.getLocationAndCheckPermission() !== "OK") {
		// 		return Swal.fire(
		// 			'Atenção',
		// 			'Você precisa permitir o acesso a sua localização para prosseguir',
		// 			'warning'
		// 		);
		// 	}
		// }
		this.dialog.open(TrocarSenhaComponent, {
			width: '480px',
			height: '400px'
		})
	}

	public fecha(): void {
		this.dialogRef.close();
	}

	public async salvar() {
		// if (this.localizacaoComponent) {
		// 	this.localizacaoComponent.getLocationAndCheckPermission();

		// 	if (await this.localizacaoComponent.getLocationAndCheckPermission() !== "OK") {
		// 		return Swal.fire(
		// 			'Atenção',
		// 			'Você precisa permitir o acesso a sua localização para prosseguir',
		// 			'warning'
		// 		);
		// 	}
		// }

		const userData = this.formatUserData(this.form.value);
		let cpf = "";
		let existCpf = null;

		if (this.form.get("cpf").enabled) {
			cpf = userData.cpf.replace(/[^0-9]/g, "");
			existCpf = await this.usuarioService.existCpf(cpf).toPromise()
				.then(data => {
					return data
				});
		}

		this.usuario
			.atualizarUsuario(userData)
			.pipe(untilDestroyed(this))
			.subscribe(
				response => {
					Swal.fire({
						title: 'Sucesso',
						text: response,
						icon: 'success',
						confirmButtonText: 'OK'
					}).then(() => {
						this.dialogRef.close();
					});
				},
				error => {
					Swal.fire({
						title: 'Atenção',
						text: error.error,
						icon: 'warning',
						confirmButtonText: 'OK'
					});
				}
			);
	}

	public adicionaZero(numero) {
		if (numero <= 9)
			return "0" + numero;
		else
			return numero;
	}

	public formatUserData(userData) {
		var numero = "";
		if (this.form.get("numero").enabled) {
			numero = userData.numero.replace(/[ ().-]/g, '');
		}
		else {
			numero = this.form.get("numero").value;
		}
		const newUserData = {
			id: userData.id,
			nome: userData.nome,
			sobrenome: userData.sobrenome,
			estadoCivil: userData.estadoCivil,
			dataNascimento: userData.dataNascimento,
			email: userData.email,
			senha: '',
			celular: {
				tipo: 'celular',
				ddd: numero.substring(0, 2), // arrumar essa parada aqui
				numero: numero.substring(2, numero.length),
				ramal: '1',
				pais: '+55'
			},
			cpf: userData.cpf,
			rg: userData.rg,
			orgaoEmissor: userData.orgaoEmissor,
			nomeMae: userData.nomeMae,
			nomePai: userData.nomePai,
			pessoaPoliticamenteExposta: userData.pessoaPoliticamenteExposta,
			endereco: this.form.value.endereco[0]

		};
		return newUserData;
	}

	getErrorMessage(form: FormGroup, field) {
		if (form.get(field)) {
			return form.get(field).hasError('required')
				? 'Campo requerido'
				: form.get(field).hasError('obrigatorio')
					? 'Campo requerido'
					: form.get(field).hasError('matDatepickerMax')
						? 'Apenas usuários maiores de 18 anos podem utilizar a plataforma'
						: form.get(field).hasError('cpfinvalido')
							? 'CPF inválido'
							: form.get(field).hasError('telefone')
								? 'Tamanho de telefone inválido'
								: form.get(field).hasError('confirmar')
									? 'Telefone não confimardo'
									: '';
		}
	}

	get endereco() {
		return this.form.get('endereco');
	}

	set endereco(endereco) {
		this.form.removeControl('endereco');
		this.form.addControl('endereco', endereco);
	}

	public verificaCPF(form: FormGroup) {
		return (control: AbstractControl) => {
			const cpf = form.get("cpf").value;

			if (!this.validacoesService.validaCPF(cpf)) {
				return { cpfinvalido: true } as ValidationErrors;
			} else {
				return null as ValidationErrors;
			}
		};
	}

	public verificaTelefone(form: FormGroup) {
		return (control: AbstractControl) => {

			const tamanhoTelefone = form.get("numero").value.replace(/[^0-9]/g, "").length;

			if (tamanhoTelefone != 10 && tamanhoTelefone != 11) {
				this.tamanhoValido = false;
				return { telefone: true } as ValidationErrors;
			} else if (!this.telefoneValido) {
				this.tamanhoValido = true;
				return { confirmar: true } as ValidationErrors;
			} else {
				this.tamanhoValido = true
				return null as ValidationErrors;
			}
		};
	}

	public valueCel() {
		let num = this.form.get('numero').value;
		num = num.replace(/[^0-9]/g, "");
		if (this.numeroAtual && (("" + this.numeroAtual.ddd + this.numeroAtual.numero) != num)) {
			this.telefoneValido = false;
		} else {
			this.telefoneValido = true;
		}

		if (num.length > 9) {
			if (num.length === 10) {
				this.numeroTelefone = '(' + num.substring(0, 2) + ')' + num.substring(2, 6) + '-' + num.substring(6, 10);
			} else if (num.length === 11) {
				this.numeroTelefone = '(' + num.substring(0, 2) + ')' + num.substring(2, 7) + '-' + num.substring(7, 11);
			} else if (num.length > 11) {
				this.numeroTelefone = '(' + num.substring(0, 2) + ')' + num.substring(2, 7) + '-' + num.substring(7, 11);
			}
		}
		this.form.get('numero').updateValueAndValidity();
	}

	public confirmaTelefone() {
		var celular: { ddd: string, numero: string } = {
			ddd: this.form.get("numero").value.replace(/[()-]/g, '').slice(0, 2),
			numero: `${(this.form.get("numero").value.replace(/[()-]/g, '') || '   ')}`.substring(2)
		}

		var tam: number = celular.numero.length + celular.ddd.length

		if ((this.numeroAtual.ddd != celular.ddd || this.numeroAtual.numero != +celular.numero) && (tam == 10 || tam == 11)) {
			this.solicitaMudancaTelefone()
		}
	}

	public solicitaMudancaTelefone() {
		if (this.validaTelefoneAtual) {
			this.validaTelefoneNovo();
		} else {
			const dialog = this.dialog.open(ModalConfirmaTokenComponent, {
				data: {
					mensagem: "Enviamos um token para solicitação de troca de telefone.",
					estrutura: "edicaoTelefoneUsuario",
					numeroTelefone: this.getUltimosNumeros(this.numeroAtual)
				},
				height: '45vh',
				backdropClass: 'modal-alerta'
			});
			dialog.afterClosed().subscribe(result => {
				if (result) {
					if (result.validado === true) {
						this.validaTelefoneAtual = true;
						this.validaTelefoneNovo();
					}
				}
			})
		}
	}

	public validaTelefoneNovo() {
		const numeroTelefone = {
			ddd: this.form.get("numero").value.replace(/[()-]/g, '').slice(0, 2),
			numero: `${(this.form.get("numero").value.replace(/[()-]/g, '') || '   ')}`.substring(2)
		}

		const dialog = this.dialog.open(ModalConfirmaTokenComponent, {
			data: {
				mensagem: "Também precisamos confirmar o novo número, verifique o token que enviamos.",
				estrutura: "edicaoTelefoneUsuario",
				numeroTelefone: this.getUltimosNumeros(numeroTelefone),
				celular: numeroTelefone.ddd + numeroTelefone.numero
			},
			height: '45vh',
			backdropClass: 'modal-alerta'
		});
		dialog.afterClosed().subscribe(result => {
			if (result) {
				if (result.validado === true) {
					this.telefoneValido = true;
					this.form.get('numero').updateValueAndValidity();
				}
			}
		});
	}

	public getUltimosNumeros(telefone): string {
		if (telefone == undefined) {
			Swal.fire(
				'Cadastro incompleto',
				'Para realizar as operações, por favor verifique o telefone em Empresa-> Configurações -> Autenticação',
				'warning'
			).then((result) => {
				this.dialogRef.close();
			});
		} else {
			const numero = String(telefone.numero);
			return '(' + telefone.ddd + ')' + ' XXXXX-XX' + numero.substring((numero.length - 2), numero.length);
		}
	}

	async permissaoGeolocalizacao() {
		await this.localizacaoService.listarConfigPorTipo(this.tipoLocalizacao)
			.subscribe((res) => {
				this.tipoLocalizacaoLiberado = res;
			}, error => {
				Swal.fire('Atenção', error.error, 'warning')
				console.log(error);
			})
	}

	get perguntasSeguranca(){
		return Object.values(PerguntasSeguranca);
	}

	get formPerguntasSeguranca(){
		return this.formPerguntas.get('perguntasSeguranca') as FormArray;
	}

	addPergunta(){
		let form = this.formPerguntas.get('perguntasSeguranca') as FormArray;
		form.push(
			new FormGroup({
				pergunta: new FormControl("", [Validators.required]),
				resposta: new FormControl("", [Validators.required]),
			})
		);
	}

	removePergunta(index: number){
		let formDesconto = this.formPerguntas.get('perguntasSeguranca') as FormArray;
		formDesconto.removeAt(index);
	}

	cadastrarPerguntas(){
		let perguntas = [], respostas = []
		const formPerguntas = this.formPerguntas.get('perguntasSeguranca') as FormArray
		formPerguntas.controls.forEach((pergunta: FormGroup)=>{
			perguntas.push(pergunta.get('pergunta').value)
			respostas.push(pergunta.get('resposta').value)
		})
		const data = {
			perguntas, respostas, usuarioId: this.usuarioLogado.id
		}
		this.usuario.cadastrarPerguntasSeguranca(data).pipe(untilDestroyed(this)).subscribe(
            res =>{
				Swal.fire('Sucesso', 'Perguntas de segurança cadastradas com sucesso', 'success').then(() => {
					this.dialogRef.close();
				});
            },
            error =>{
            	console.error(error);
            	Swal.fire('Erro', 'Erro ao cadastrar perguntas de segurança', 'error');
            }
        )
	}
}
