<div>
  <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
    EMAILS DO SISTEMA
    <i (click)="fecha()" class="material-icons btn-fechar" id="btn-close">close</i>
    <span id="fechar" (click)="fecha()">FECHAR</span>
  </h2>
  <form [formGroup]="form">
    <div mat-dialog-content class="mat-dialog-content-responsive" style="width: 65vw;">
      <div class="mudaCor input-size row"
        style="background-color: white; margin: 0px 10px; border-radius: 5px;padding: 28px 0px; ">
        <div class="card-body">
          <div class="first-container">
            <!-- FORMS -->
            <div id="input-container" class="row row-inputs">
              <h4 class="color-title">Adicionar</h4>
              <div class="col-12 col-md-12" style="margin-top: 25px;">
                <mat-form-field appearance="outline">
                  <mat-label>Email</mat-label>
                  <input matInput type="text" placeholder="Email" formControlName="email" required maxlength="45">
                </mat-form-field>
              </div>
              <div class="col-12 col-md-12">
                <mat-form-field appearance="outline">
                  <mat-label>Tipo</mat-label>
                  <mat-select required formControlName="tipo">
                    <mat-option value="admin">Administrador</mat-option>
                    <mat-option value="suporte">Suporte</mat-option>
                    <mat-option value="financeiro">Financeiro</mat-option>
                    <mat-option value="comercial">Comercial</mat-option>
                    <mat-option value="compliance">Compliance</mat-option>
                    <mat-option value="controladoria">Controladoria</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div mat-dialog-actions style="overflow: initial; margin: 0; padding: 8px 0 0;border-radius: 4px;width: 100%;
            justify-content: center; width: 100%;">
              <span class="div-interna flex-end" style="align-items: center; padding: 0px 20px;border-radius: 4px;
          margin: 0;">
                <button class="btn btn-default next" id="color-btn" (click)="salvar()"
                  [disabled]="!isFormValid">Adicionar</button>
              </span>
            </div>
          </div>

          <div class="second-container">
            <div mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
              <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </div>
            </div>
            <h4 class="color-title">Lista de Emails</h4>
            <mat-list id="list-container" class="example-list">
              <mat-list-item *ngFor="let item of todosEmails" class="example-box">
                <div id="config-list">
                  <div *ngIf="item.ativo">
                    <span class="msg-lista">
                      {{ item?.email }} -
                    </span>
                    <span class="msg-lista">
                      {{ nomeTipo(item?.tipo) }}
                    </span>
                  </div>
                  <div *ngIf="!item.ativo">
                    <span class="msg-lista-desativado">
                      {{ item?.email }} -
                    </span>
                    <span class="msg-lista-desativado">
                      {{ nomeTipo(item?.tipo) }}
                    </span>
                  </div>
                  <div style="display: flex; justify-content: flex-end;">
                    <button mat-icon-button [matMenuTriggerFor]="menu" id="edit-button">
                      <i class="fa-solid fa-ellipsis" id="tres-pontos"></i>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="desativar(item.id)" *ngIf="item.ativo">
                        <i class="fas fa-trash" style="color: rgb(223, 67, 19)"></i>
                        Desativar
                      </button>
                      <button mat-menu-item *ngIf="!item.ativo" (click)="ativar(item.id)">
                        <i class="fas fa-check icones" style="color: #4fa953"></i>
                        Ativar
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>