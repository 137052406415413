import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from "rxjs";
import { UsuarioEndpointService } from "@service/usuario-endpoint.service";
import { RegraEmpresaDTO } from "@project/src/app/model/pessoa/regra-empresa-dto.model";
import moment from 'moment';
import { startWith, map } from "rxjs/operators";
import {
  IPessoaService,
  PessoaServiceBuilder,
} from "@service/pessoa-endpoint.service";
import { EmpresaEndpointService } from "@service/empresa-endpoint.service";
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AutenticacaoEndpointService } from '@service/autenticacao-endpoint.service';
import { ExportarPlanilhaService } from '@service/exportar-planilha.service';
import { environment } from '@src/environments/environment';
import Swal from 'sweetalert2';
import { StringFormatService } from '@project/src/app/service/util/stringFormater.service';
import { CheckAuthoritiesService } from '@project/src/app/service/util/checkAuthorities.service';


@Component({
  selector: 'app-exportar-conciliacao',
  templateUrl: './exportar-conciliacao.component.html',
  styleUrls: ['./exportar-conciliacao.component.less']
})
export class ExportarConciliacaoComponent implements OnInit, OnDestroy {
  tiposConta = [
    {
      tipo: 'contaDigitalS',
      nome: 'Conta Digital S'
    },
    {
      tipo: 'contaCorrenteDigital',
      nome: 'Conta Corrente Digital'
    },
    {
      tipo: 'contaVinculadaDigital',
      nome: 'Conta Vinculada Digital'
    },
    {
      tipo: 'contaServicoDigital',
      nome: 'Conta Serviço Digital'
    },
    {
      tipo: 'contaSalarioDigital',
      nome: 'Conta Salário Digital'
    },
    {
      tipo: 'contaBloqueioJudicial',
      nome: 'Conta Bloqueio Judicial'
    },
    {
      tipo: 'contaEscrowDigital',
      nome: 'Conta Escrow Digital'
    },
    {
      tipo: 'contaInvestidorDigital',
      nome: 'Conta Investidor Digital'
    },
    {
      tipo: 'contaDigital',
      nome: 'Conta Digital'
    }
  ]
  form: FormGroup;
  private service: IPessoaService;
  formAgencia: FormGroup;
  formRelatorio: FormGroup;
  formCorrentista: FormGroup;
  formIntegral: FormGroup;
  formInterno: FormGroup;
  formPlanilhas: FormGroup;
  corporacaoList: any = [];
  empresaList: any = [];
  filtroCorporacao: Observable<any[]>;
  filtroEmpresa: Observable<any[]>;
  resetCorporacao: number = 1;
  resetEmpresa: number = 1;
  corporacaoNome: any;
  empresaNome: string;
  quantidadeEmpresas: number;
  processando: boolean = false;
  usuarioLogado: any;
  empresaLogada: any;
  anoAtual: Date = new Date();
  anos: any = [];
  filtroData = {
    dataInicial: moment().subtract(30, 'days').toDate(),
    dataFinal: new Date()
  };
  ehColaborador = false;

  tipoExtrato: string;
  exportarAte: any;
  exportarDe: any;
  env = environment;
  listaEmpresa: any;
  today = new Date();

  constructor(
    private fb: FormBuilder,
    private autenticacao: AutenticacaoEndpointService,
    public dialogRef: MatDialogRef<ExportarConciliacaoComponent>,
    private usuario: UsuarioEndpointService,
    public activatedRoute: ActivatedRoute,
    private injector: Injector,
    private empresaService: EmpresaEndpointService,
    private exportarPlanilha: ExportarPlanilhaService,
    public stringFormat: StringFormatService
  ) {
    this.usuarioLogado = this.autenticacao.sessao.principal.usuario;
    this.empresaLogada = this.autenticacao.regraEmpresa.empresa;
    const checkAuthorities = new CheckAuthoritiesService(this.autenticacao.sessao)
    this.ehColaborador = checkAuthorities.isColaborador;
  }

  ngOnInit() {
    this.form = this.fb.group({
      corporacao: new FormControl(null, [Validators.required]),
      empresa: new FormControl(null),
    });

    this.processando = false;
    this.formAgencia = new FormGroup({
      de: new FormControl(''),
      ate: new FormControl(''),
      banco: new FormControl(''),
      tudo: new FormControl(false),
      boletos: new FormControl(false),
      teds: new FormControl(false),
      pagamentos: new FormControl(false),
      cartao: new FormControl(false),
      antecipacoes: new FormControl(false),
      manutencao: new FormControl(false),
      correntista: new FormControl(''),
      corporacao: new FormControl(''),
      empresa: new FormControl(''),
      fechamentoDiario: new FormControl(false),
      conciliacaoSTR: new FormControl(false),
      tiposConta: new FormControl([])
    })
    
    this.formRelatorio = new FormGroup({
      de: new FormControl(''),
      ate: new FormControl(''),
      banco: new FormControl('')
    })

    this.formIntegral = new FormGroup({
      de: new FormControl(''),
      ate: new FormControl(''),
      banco: new FormControl('')
    })

    this.formInterno = new FormGroup({
      de: new FormControl(''),
      ate: new FormControl(''),
      interno: new FormControl('true')
    })

    this.formPlanilhas = new FormGroup({
      de: new FormControl(''),
      ate: new FormControl(''),
    })

    this.form
      .get("corporacao")
      .setValue(
        this.autenticacao.regraCorporacao
          ? this.autenticacao.regraCorporacao.corporacao
          : ""
      );
    if (this.autenticacao.regraCorporacao) {
      this.corporacaoNome = this.autenticacao.regraCorporacao.corporacao.nome;
    }

    if (this.autenticacao.regraEmpresa) {
      this.empresaNome = this.displayEmp(this.autenticacao.regraEmpresa.empresa);
    }
    this.corporacaoNome = this.autenticacao.regraCorporacao.corporacao.nome;
    this.resetCorporacao = 1;
    this.resetEmpresa = 1;
    this.form.get("empresa").setValue(this.autenticacao.regraEmpresa || "");

    this.service = PessoaServiceBuilder.getInstance(this.injector, "empresa");

    this.usuario
      .getCorporacoesDoUsuario()
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.corporacaoList = response;

          this.getEmpresasDaCorporacao(null);
        },
        (error) => {
          //this.disloading();
        }
      );

    for (let index = this.anoAtual.getFullYear(); index >= new Date(this.usuarioLogado.dataCriacao).getFullYear(); index--) {
      this.anos.push(index);
    }

    this.formAgencia.get('banco').valueChanges.subscribe((valor)=>{
      if(valor!==""){
        this.formAgencia.get('tiposConta').disable()
        this.formAgencia.get('tiposConta').setValue([])
      }else{
        this.formAgencia.get('tiposConta').enable()
        this.formAgencia.get('tiposConta').setValue([])
      }
    })
  }

  ngOnDestroy() { }

  public _filterEmpresa(searchValue: any): RegraEmpresaDTO[] {
    if (this.isString(searchValue)) {
      const filterValue = searchValue;

      if (!isNaN(filterValue)) {
        if (this.resetEmpresa === 1 && this.empresaNome != searchValue && this.autenticacao.regraEmpresa.empresa.tipoConta != null) {
          return this.empresaList.filter((empresa) =>
            empresa.pessoa.documento.toLowerCase().includes(this.getDifference(searchValue.toString(), this.empresaNome))
          );
        }
        return this.empresaList.filter((empresa) =>
          empresa.pessoa.documento.toLowerCase().includes(filterValue)
        );
      } else {
        if (this.resetEmpresa === 1 && this.empresaNome != searchValue && this.autenticacao.regraEmpresa.empresa.tipoConta != null) {
          return this.empresaList.filter((empresa) =>
            (empresa.pessoa.razaoSocial || empresa.pessoa.nome).toLowerCase().includes(this.getDifference(searchValue, this.empresaNome))
          );
        }
        return this.empresaList.filter((empresa) =>
          (empresa.pessoa.razaoSocial || empresa.pessoa.nome)
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        );
      }
    }
  }

  Str(event, name) {
    if (event.checked == true) {
      if (name == "conciliacaoSTR") {
        this.formAgencia.get('fechamentoDiario').setValue(false);
      }
      if (name == "fechamentoDiario") {
        this.formAgencia.get('conciliacaoSTR').setValue(false);
      }
    }
  }

  getEmpresasDaCorporacao(corporacao) {
    let empresa: number;

    if (corporacao) {
      this.form.get('empresa').setValue('');
      empresa = corporacao.id;
    }
    let page = 0, size = 20;
    this.empresaService
      .listar({ empresa, page, size })
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {
          this.empresaList = res.content;

          this.filtroCorporacao = this.formAgencia.get("corporacao").valueChanges.pipe(
            startWith(""),
            map((corporacao) => this._filterCorporacao(corporacao))
          );

          this.filtroEmpresa = this.formAgencia.get("empresa").valueChanges.pipe(
            startWith(""),
            map((empresa) => this._filterEmpresa(empresa))
          );
        },
        (error) => {
          this.empresaList = [];
          this.form.get("empresa").setValue("");
        }
      );
  }

  getComp(empresa: any) {
    if (empresa.tipoConta === `contaDigitalS`) {
      return `000`;
    } else {
      return this.env.codBanco;
    }
  }

  displayEmp(empresa: any): any {
    if (empresa === "") {
      return empresa;
    }

    if (empresa && !empresa.empresa) {
      if (empresa.tipoConta) {
        let compEmpresa = '';
        let tipoEmpresa = '';

        if (empresa.pessoa) {
          this.empresaNome = empresa.pessoa.razaoSocial;
        }

        const agencia = empresa.agencia ? empresa.agencia.padStart(4, '0') : environment.codAgencia;

        if (empresa.tipoConta === 'contaDigitalS') {
          compEmpresa = '000';
        } else {
          compEmpresa = environment.codBanco;
        }

        switch (empresa.tipoConta) {
          case 'contaDigitalS': {
            tipoEmpresa = 'C/DigitalS';
            break;
          }
          case 'contaCorrenteDigital': {
            tipoEmpresa = 'C/Corrente';
            break;
          }
          case 'contaVinculadaDigital': {
            tipoEmpresa = 'C/Vinculada';
            break;
          }
          case 'contaEscrowDigital': {
            tipoEmpresa = 'C/Escrow';
            break;
          }
          case 'contaBloqueioJudicial': {
            tipoEmpresa = 'C/Bloqueio';
            break;
          }
          case 'contaSalarioDigital': {
            tipoEmpresa = 'C/Salario';
            break;
          }
          case 'contaServicoDigital': {
            tipoEmpresa = 'C/Serviço';
            break;
          }
          case 'contaInvestidorDigital': {
            tipoEmpresa = 'C/Investidor';
            break;
          }
          case 'contaDigital': {
            tipoEmpresa = 'C/Digital';
            break;
          }
        }

        const contaEmpresa = empresa.numeroConta.substring(0, empresa.numeroConta.length - 1) + '-' + empresa.numeroConta.substring(empresa.numeroConta.length - 1, empresa.numeroConta.length);
        return (
          (empresa.pessoa.razaoSocial || empresa.pessoa.nome) + " - " + empresa.pessoa.documento +
          ' Bco ' + compEmpresa + ' - Ag: ' + agencia + ' - ' + tipoEmpresa + ': ' + contaEmpresa
        );
      } else {
        if (empresa.tipoConta === null) {
          if (empresa.pessoa.razaoSocial) {
            return empresa.pessoa.razaoSocial + ' - ' + empresa.pessoa.documento
          } else {
            return empresa.pessoa.nome + ' - ' + empresa.pessoa.documento
          }
        }
        return empresa;
      }
    } else {
      if (empresa.empresa.tipoConta) {
        let compEmpresa = '';
        let tipoEmpresa = '';

        if (empresa.empresa.pessoa) {
          this.empresaNome = empresa.empresa.pessoa.razaoSocial;
        }

        const agencia = empresa.empresa.agencia ? empresa.empresa.agencia.padStart(4, '0') : environment.codAgencia;

        if (empresa.empresa.tipoConta === 'contaDigitalS') {
          compEmpresa = '000';
        } else {
          compEmpresa = environment.codBanco;
        }

        switch (empresa.empresa.tipoConta) {
          case 'contaDigitalS': {
            tipoEmpresa = 'C/DigitalS';
            break;
          }
          case 'contaCorrenteDigital': {
            tipoEmpresa = 'C/Corrente';
            break;
          }
          case 'contaVinculadaDigital': {
            tipoEmpresa = 'C/Vinculada';
            break;
          }
          case 'contaEscrowDigital': {
            tipoEmpresa = 'C/Escrow';
            break;
          }
          case 'contaBloqueioJudicial': {
            tipoEmpresa = 'C/Bloqueio';
            break;
          }
          case 'contaSalarioDigital': {
            tipoEmpresa = 'C/Salario';
            break;
          }
          case 'contaServicoDigital': {
            tipoEmpresa = 'C/Serviço';
            break;
          }
          case 'contaInvestidorDigital': {
            tipoEmpresa = 'C/Investidor';
            break;
          }
          case 'contaDigital': {
            tipoEmpresa = 'C/Digital';
            break;
          }
        }

        const contaEmpresa = empresa.empresa.numeroConta.substring(0, empresa.empresa.numeroConta.length - 1) + '-' + empresa.empresa.numeroConta.substring(empresa.empresa.numeroConta.length - 1, empresa.empresa.numeroConta.length);
        return (
          (empresa.empresa.pessoa.razaoSocial || empresa.empresa.pessoa.nome) + " - " + empresa.empresa.pessoa.documento +
          ' Bco ' + compEmpresa + ' - Ag: ' + agencia + ' - ' + tipoEmpresa + ': ' + contaEmpresa
        );
      } else {
        if (empresa.empresa.tipoConta === null) {
          if (empresa.empresa.pessoa.razaoSocial) {
            return empresa.empresa.pessoa.razaoSocial + ' - ' + empresa.empresa.pessoa.documento
          } else {
            return empresa.empresa.pessoa.nome + ' - ' + empresa.empresa.pessoa.documento
          }
        }
        return empresa;
      }
    }
  }

  getTipo(empresa: any) {
    if (empresa.tipoConta != null) {
      switch (empresa.tipoConta) {
        case `contaDigitalS`: {
          return `C/DigitalS`;
        }
        case `contaCorrenteDigital`: {
          return `C/Corrente`;
        }
        case `contaVinculadaDigital`: {
          return `C/Vinculada`;
        }
        case `contaEscrowDigital`: {
          return `C/Escrow`;
        }
        case `contaBloqueioJudicial`: {
          return `C/Bloqueio`;
        }
        case `contaSalarioDigital`: {
          return `C/Salario`;
        }
        case `contaServicoDigital`: {
          return `C/Serviço`;
        }
        case `contaInvestidorDigital`: {
          return `C/Investidor`;
        }
        case `contaDigital`: {
          return `C/Digital`;
        }
      }
    }
  }

  resetFieldCorp(event: any) {
    if (this.resetCorporacao === 1) {
      if (this.form.get('corporacao').value.length > event.length) {
        this.resetCorporacao = 0;
        event = this.getDifference(this.form.get('corporacao').value, event);
      } else if (this.form.get('corporacao').value.length < event.length) {
        this.resetCorporacao = 0;
        event = '';
      }
      this.displayCorp(event)
    }
    this._filterCorporacao(event);
  }

  displayCorp(corporacao): any {
    if (corporacao.nome) {
      this.corporacaoNome = corporacao.nome;
      return this.corporacaoNome;
    } else if (!isNaN(+corporacao)) {
      return this.corporacaoNome
    } else if (corporacao) {
      this.corporacaoNome = corporacao;
      return this.corporacaoNome;
    }
  }

  atualizarCorporacao(corporacao) {
    this.resetCorporacao = 1;
    this.corporacaoNome = corporacao.nome;
    this.formAgencia.controls.corporacao.setValue(corporacao.id)
  }

  atualizarEmpresa(empresa) {
    this.filtroEmpresa = this.form.get("empresa").valueChanges.pipe(
      startWith(""),
      map((empresa) => this._filterEmpresa(empresa))
    );

    this.resetEmpresa = 1;
    this.empresaNome = empresa;
    this.formAgencia.controls.correntista.setValue(empresa.id)
  }

  resetFieldEmp(event: any) {
    if (this.resetEmpresa === 1) {
      if (this.form.get('empresa').value.length > event.length) {
        event = this.getDifference(this.form.get('empresa').value, event);
        this.resetEmpresa = 0;
      } else if (this.form.get('empresa').value.length < event.length) {
        event = '';
        this.resetEmpresa = 0;
      }
      this.displayEmp(event);
    }
  }

  getDifference(a: string, b: string) {
    var i = 0, j = 0;
    var vetorA = a.split('');
    var vetorB = b.toString().split('');
    for (i = 0; i < vetorA.length; i++) {
      if (vetorA[i] !== vetorB[i]) {
        return vetorA[i];
      }
    }
    return '';
  }

  isString(value) {
    return typeof value === "string" || value instanceof String;
  }

  public _filterCorporacao(searchValue: any): any[] {
    if (this.isString(searchValue)) {
      if (this.resetCorporacao === 1 && this.form.get('corporacao').value.nome != this.corporacaoNome) {
        return this.corporacaoList.filter((corporacao) =>
          corporacao.nome.toLowerCase().includes(this.getDifference(searchValue.toString(), this.corporacaoNome))
        );
      }
      return this.corporacaoList.filter((corporacao) =>
        corporacao.nome.toLowerCase().includes(searchValue.toLowerCase())
      );
    } else {
      if (this.resetCorporacao === 1 && this.form.get('corporacao').value.nome != this.corporacaoNome) {
        return this.corporacaoList.filter((corporacao) =>
          corporacao.nome.toLowerCase().includes(this.getDifference(searchValue.toString(), this.corporacaoNome))
        );
      }
      return this.corporacaoList.filter((corporacao) =>
        corporacao.nome.toLowerCase().includes(searchValue.toString().toLowerCase())
      );
    }
  }

  exportarDownload() {
    let data
    switch (this.tipoExtrato) {
      case '1': //Extrato Personalizavel
        this.formAgencia.controls.de.setValue(moment(this.formAgencia.get("de").value).format('DDMMYYYY'))
        this.formAgencia.controls.ate.setValue(moment(this.formAgencia.get("ate").value).format('DDMMYYYY'))
        data = this.formAgencia.value
        break;
      case '2': //Extrato entre e dentro da agência
        this.formInterno.controls.de.setValue(moment(this.formInterno.get("de").value).format('DDMMYYYY'))
        this.formInterno.controls.ate.setValue(moment(this.formInterno.get("ate").value).format('DDMMYYYY'))
        data = this.formInterno.value;
        break;
      case '3': //Extrato Integral
        this.formIntegral.controls.de.setValue(moment(this.formIntegral.get("de").value).format('DDMMYYYY'))
        this.formIntegral.controls.ate.setValue(moment(this.formIntegral.get("ate").value).format('DDMMYYYY'))
        data = this.formIntegral.value
        break;
      case '4':
        this.formRelatorio.controls.de.setValue(moment(this.formRelatorio.get("de").value).format('DDMMYYYY'))
        data = this.formRelatorio.value
        break;
      case '5':
        this.formPlanilhas.controls.de.setValue(moment(this.formPlanilhas.get("de").value).format('DDMMYYYY'))
        this.formPlanilhas.controls.ate.setValue(moment(this.formPlanilhas.get("ate").value).format('DDMMYYYY'))
        data = this.formPlanilhas.value
        data.email = this.usuarioLogado.email
    }

    if (this.tipoExtrato === '4') {
      this.exportarPlanilha.exportarRelatorio(
        this.formRelatorio.get('banco').value, this.formRelatorio.get('de').value, "documento", this.ehColaborador === true ? this.usuarioLogado.email : null
      ).pipe(untilDestroyed(this))
        .subscribe(
          (response) => {
            Swal.fire('Sucesso', response, 'success').then(() => this.dialogRef.close());
          }, error => {
            Swal.fire('Erro', error.error, 'error').then(() => this.dialogRef.close());
            console.error(error);
          }
        );
    } else if(this.tipoExtrato === '5'){
      this.exportarPlanilha.exportarMovimentacoesPorConta(data)
        .pipe(untilDestroyed(this))
        .subscribe(
          (response) => {
            Swal.fire('Sucesso', response, 'success').then(() => this.dialogRef.close());
          }, error => {
            Swal.fire('Erro', error.error, 'error').then(() => this.dialogRef.close());
            console.error(error);
          }
        )
    } else if(this.tipoExtrato === '6'){
      this.exportarPlanilha.relatorioSimplificado()
        .pipe(untilDestroyed(this))
        .subscribe(
          (response) => {
            Swal.fire('Sucesso', response, 'success').then(() => this.dialogRef.close());
          }, error => {
            Swal.fire('Erro', error.error, 'error').then(() => this.dialogRef.close());
            console.error(error);
          }
        )
    } else {
      if (this.ehColaborador === true) data.emailColaborador = this.usuarioLogado.email
      this.exportarPlanilha.exportarPlanilha(data)
        .pipe(untilDestroyed(this))
        .subscribe(
          (response) => {
            Swal.fire('Sucesso', response, 'success').then(() => this.dialogRef.close());
          }, error => {
            Swal.fire('Erro', error.error, 'error').then(() => this.dialogRef.close());
            console.error(error);
          }
        );
    }
  }

  fecha(): void {
    this.dialogRef.close();
  }

  tudo(group) {
    if (group == 'formCorrentista') {
      if (this.formCorrentista.controls.tudo.value == true) {
        // this.formCorrentista.controls.antecipacoes.setValue(true)
        // this.formCorrentista.controls.boletos.setValue(true)
        // this.formCorrentista.controls.cartao.setValue(true)
        // this.formCorrentista.controls.teds.setValue(true)
        // this.formCorrentista.controls.manutencao.setValue(true)
        // this.formCorrentista.controls.pagamentos.setValue(true)
      } else {
        // this.formCorrentista.controls.antecipacoes.setValue(false)
        // this.formCorrentista.controls.boletos.setValue(false)
        // this.formCorrentista.controls.teds.setValue(false)
        // this.formCorrentista.controls.cartao.setValue(false)
        // this.formCorrentista.controls.manutencao.setValue(false)
        // this.formCorrentista.controls.pagamentos.setValue(false)
      }
    } else {
      if (this.formAgencia.controls.tudo.value == true) {
        this.formAgencia.controls.antecipacoes.setValue(true)
        this.formAgencia.controls.boletos.setValue(true)
        this.formAgencia.controls.cartao.setValue(true)
        this.formAgencia.controls.teds.setValue(true)
        this.formAgencia.controls.manutencao.setValue(true)
        this.formAgencia.controls.pagamentos.setValue(true)
      } else {
        this.formAgencia.controls.antecipacoes.setValue(false)
        this.formAgencia.controls.boletos.setValue(false)
        this.formAgencia.controls.teds.setValue(false)
        this.formAgencia.controls.cartao.setValue(false)
        this.formAgencia.controls.manutencao.setValue(false)
        this.formAgencia.controls.pagamentos.setValue(false)
      }
    }
  }

  calculaNroConta(empresa: any) {
    if (empresa.tipoConta != null) {
      var contaInst = empresa.numeroConta.substring(0, empresa.numeroConta.length - 1) + '-' + empresa.numeroConta.substring(empresa.numeroConta.length - 1, empresa.numeroConta.length);
      return contaInst;
    }
  }

  exibirTooltip(empresa: any) {
    if (empresa.tipoConta != null) {
      let bloqueada: string = empresa.moderacaoEmpresa == "bloqueada" ? " BLOQUEADA" : "";
      switch (empresa.tipoConta) {
        case `contaDigitalS`: {
          return 'Conta Digital S - ' + this.calculaNroConta(empresa) + bloqueada
        }
        case `contaCorrenteDigital`: {
          return 'Conta Corrente Digital - ' + this.calculaNroConta(empresa) + bloqueada
        }
        case `contaVinculadaDigital`: {
          return 'Conta Vinculada Digital- ' + this.calculaNroConta(empresa) + bloqueada
        }
        case `contaEscrowDigital`: {
          return 'Conta Escrow Digital - ' + this.calculaNroConta(empresa) + bloqueada
        }
        case `contaBloqueioJudicial`: {
          return 'Conta Bloqueio Judicial - ' + this.calculaNroConta(empresa) + bloqueada
        }
        case `contaSalarioDigital`: {
          return 'Conta Salario Digital - ' + this.calculaNroConta(empresa) + bloqueada
        }
        case `contaServicoDigital`: {
          return 'Conta Servico Digital - ' + this.calculaNroConta(empresa) + bloqueada
        }
        case `contaInvestidorDigital`: {
          return 'Conta Investidor Digital - ' + this.calculaNroConta(empresa) + bloqueada
        }
        case `contaDigital`: {
          return 'Conta Digital - ' + this.calculaNroConta(empresa) + bloqueada
        }
      }
    }
  }

  isNotPreenchido() {
    if (this.tipoExtrato == null) {
      return true;
    }
    if (this.tipoExtrato === '1') {
      if ((this.formAgencia.controls.tudo.value == false
        && this.formAgencia.controls.antecipacoes.value == false
        && this.formAgencia.controls.boletos.value == false
        && this.formAgencia.controls.cartao.value == false
        && this.formAgencia.controls.teds.value == false
        && this.formAgencia.controls.manutencao.value == false
        && this.formAgencia.controls.pagamentos.value == false)
        || this.formAgencia.controls.de.value == ''
        || this.formAgencia.controls.ate.value == '') {
        return true;
      }
    } else if (this.tipoExtrato === '2') {
      if (this.formInterno.controls.de.value == ''
        || this.formInterno.controls.ate.value == '') {
        return true;
      }
    } else if (this.tipoExtrato === '3') {
      if (this.formIntegral.controls.de.value == ''
        || this.formIntegral.controls.ate.value == '') {
        return true;
      }
    } else if (this.tipoExtrato === '4') {
      if (this.formRelatorio.controls.de.value == ''
        || this.formRelatorio.controls.banco.value == '') {
        return true;
      }
    } else if (this.tipoExtrato === '5') {
      if (this.formPlanilhas.controls.de.value == ''
        || this.formPlanilhas.controls.ate.value == '') {
        return true;
      }
    }
  }
}