import { ErrorHandler, Injectable, Injector } from '@angular/core';
import swal, { SweetAlertOptions } from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { RootEndpointService } from '../root-endpoint.service';
import { environment } from '@project/src/environments/environment.default';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandler extends ErrorHandler {

  private rootService: RootEndpointService;
  private router: Router;
  private env: typeof environment; // Certifique-se de que environment seja importado corretamente

  objetoTeams: {
    id: string,
    erro: any,
    rota: string,
    data: Date
  } = { // Inicialize objetoTeams como um objeto vazio
      id: '',
      erro: '',
      rota: '',
      data: new Date()
    };

  constructor(
    private injector: Injector,
  ) {
    super();

    // Inicialize rootService, router e env
    setTimeout(() => {
      this.rootService = this.injector.get(RootEndpointService);
      this.router = this.injector.get(Router);
      this.env = environment;
    });
  }

  generateRandomId() {
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let id = '';
    const idLength = 4;

    for (let i = 0; i < idLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      id += characters.charAt(randomIndex);
    }

    const banco = this.env.codBanco;
    const agencia = this.env.codAgencia;

    // Remova os zeros à esquerda usando uma expressão regular
    const agenciaSemZeros = agencia.replace(/^0+/, '');

    return id + banco + agenciaSemZeros;

  }

  public handleError(error: any) {
    if (error && error.error && (error.error.path == "/me" || error.error.path == "/login")) {
      return;
    }

    super.handleError(error);
    let html = '';
    const randomId = this.generateRandomId();

    if (error instanceof Error) {
      const chunkFailedMessage = /Loading chunk [\d]+ failed/;
      if (chunkFailedMessage.test(error.message)) {
        return window.location.reload();
      }

      html = error.message;
    } else {
      html = JSON.stringify(error);
    }

    this.objetoTeams.id = randomId;
    this.objetoTeams.erro = error.message + error.stack;
    this.objetoTeams.rota = this.router.url;
    this.objetoTeams.data = new Date();

    this.rootService.salvarErrosTeams(this.objetoTeams).subscribe();

    const acessoNegado401 = "\"status\":401";
    const acessoNegado403 = "\"status\":403";
    if (error && error.message && (error.message.includes(acessoNegado401) || error.message.includes(acessoNegado403))) {
      return;
    }

    let param;
    if (environment.production) {
      param = {
        title: 'Ops!',
        text: `Tente novamente mais tarde.\nID: ${randomId}`,
        icon: 'error'
      } as SweetAlertOptions;
    } else {
      param = {
        title: 'Ops!',
        text: error.message,
        icon: 'error'
      } as SweetAlertOptions;
    }

    if (error instanceof HttpErrorResponse) {
      param.html = error.error;
    } 

    return swal.fire(param);
  }
}
