<app-localizacao [usuarioLogado]="usuarioLogado" [tipo]="tipoLocalizacao"
    *ngIf="tipoLocalizacaoLiberado?.ativo"></app-localizacao>
<h2 mat-dialog-title class="titulo-modal flex-space-between">
    <div class="flex-align-center" style="width: 30%; justify-content: start">
        <div id="avatar-icon">
            <i class="fas fa-building fa" style="font-size: 0.8em !important;"></i>
        </div>
        <div style="line-height:1.5vh" id="empresa-logada"
            [matTooltip]="empresaLogada.pessoa.razaoSocial ||  empresaLogada.pessoa.nome">
            <p style="margin: 0 !important;">{{ empresaLogada.pessoa.razaoSocial || empresaLogada.pessoa.nome }}</p>
            <p style="margin: 0 !important;">{{ stringFormat.adicionarPontosDocumento(empresaLogada.pessoa.documento) }}
            </p>
        </div>
    </div>
    COBRANÇAS
    <div class="flex-align-center" style="width: 30%; justify-content: end;">
        <p style="margin: 0 !important; padding-right: 4px" id="fechar" (click)="fecha()">FECHAR</p>
        <i style="margin-right: 10px;" (click)="fecha()" class="material-icons btn-fechar">close</i>
    </div>
</h2>


<div mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
    <div class="row">
        <div class="col-9 horizontal-stepper">
            <mat-horizontal-stepper class="top-stp" [linear]=true (selectionChange)="onStepChange($event)"
                #elementstepper1>
                <mat-step [editable]="true" state="done">
                    <app-stepper1 [form]="form.get('formStepper1')" [data]="dataDialog.idCobranca"></app-stepper1>
                </mat-step>
                <mat-step [editable]="true" state="done">
                    <app-stepper2 [form]="form.get('formStepper2')" [component]="this"
                        [tipoCobranca]="form.get('formStepper1.tipoCobranca').value"
                        [isPix]="form.get('formStepper1.isPix').value">
                    </app-stepper2>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
        <div class="col-3 resumo" style="padding-right: 25px;">

            <p class="super-title">RESUMO DA COBRANÇA</p>

            <p *ngIf="form.get('formStepper1.isPix').value">
                Gerar QR Code para pagamento.
            </p>

            <p *ngIf="form.get('formStepper1.isPix').value">
                O email e o SMS de notificação não opcionais no caso de QR Code de cobrança única.
            </p>

            <p *ngIf="!form.get('formStepper1.isPix').value">{{ form.get('formStepper1.tipoCobranca').value == 1 ?
                'Cobrança Avulsa' :
                form.get('formStepper1.tipoCobranca').value == 2 ? 'Assinatura' : 'Cobrança Parcelada' }}
            </p>
            <p *ngIf="!form.get('formStepper1.isPix').value">{{ nomeOuRazaoSocial(form.get('formStepper1.pessoa').value)
                || '' }}</p>
            <p *ngIf="!form.get('formStepper1.isPix').value">{{
                stringFormat.adicionarPontosDocumento((form.get('formStepper1.pessoa').value).pessoa?.documento) ||
                ''}}</p>
            <!-- <p>{{ form.get('formStepper1.emailNotificacao').value.email || '' }}</p> -->
            <!-- <p>{{ form.get('formStepper1.notificacaoSms').value || '' }}</p> -->

            <div
                *ngIf="form.get('formStepper1.isPix').value && form.get('formStepper2.metodoPagamento').value == 'pixDinamico' ">
                <span>QR Code dinâmico</span>
            </div>

            <div
                *ngIf="form.get('formStepper1.isPix').value && form.get('formStepper2.metodoPagamento').value == 'pixEstatico' ">
                <span>QR Code Estático</span>
            </div>


            <div *ngIf="form.get('formStepper1.isPix').value" style="margin-top: 15px;">
                <p>
                    <span class="valor-nominal">
                        Chave pix:
                    </span>
                    <span> {{ form.get('formStepper2.chavePixCobrador').value }}</span>
                </p>
            </div>

            <div *ngIf="form.get('formStepper1.isPix').value" style="margin-top: 8px;">
                <p>
                    <span class="valor-nominal">
                        Valor: {{ (form.get('formStepper2.valorPix').value | currency: 'BRL': 'R$') }} </span>
                </p>
            </div>

            <div *ngIf="form.get('formStepper1.isPix').value">
                <p style="font-size: 10px;">Dúvidas?<a href={{urlAjuda}} target="_blank"> Visite nosso
                        FAQ</a></p>
            </div>

            <div *ngIf="form.get('formStepper1').valid && form.get('formStepper1.isPix').value == false">
                <p class="menor-title">Informações básicas</p>
                <p>{{ form.get('formStepper2.mensagem').value || '' }}</p>
                <p *ngIf="form.get('formStepper1.tipoCobranca').value == 1" class="valor-nominal">
                    {{ (form.get('formStepper2.valorNominal').value | currency: 'BRL': 'R$') || '' }}</p>
                <p
                    *ngIf="form.get('formStepper1.tipoCobranca').value == 2 && form.get('formStepper2.valorNominal').value ">
                    {{ 'Uma assinatura ' + form.get('formStepper2.intervaloAssinatura').value + ' no valor de ' }} <span
                        class="valor-nominal">
                        {{ (form.get('formStepper2.valorNominal').value | currency: 'BRL': 'R$') || '' }} </span>
                </p>
                <p
                    *ngIf="form.get('formStepper1.tipoCobranca').value == 3 && form.get('formStepper2.valorNominal').value && !this.dataDialog?.idCobranca">
                    <span class="valor-nominal">
                        {{ (form.get('formStepper2.valorNominal').value | currency: 'BRL': 'R$') }} </span>

                    <span>{{ ' (' + form.get('formStepper2.numeroParcelas').value + ' parcelas de ' +
                        (+(this.form.get('formStepper2.valorNominal').value/this.form.get('formStepper2.numeroParcelas').value).toFixed(2)
                        | currency: 'BRL': 'R$') + ')' }}</span>
                </p>

                <p
                    *ngIf="form.get('formStepper1.tipoCobranca').value == 3 && form.get('formStepper2.valorNominal').value && this.dataDialog?.idCobranca">
                    <span class="valor-nominal">
                        {{ (form.get('formStepper2.valorNominal').value | currency: 'BRL': 'R$') }} </span>

                    <span>{{ '(Você editará somente a parcela que estava visualizando)' }}</span>
                </p>

                <!-- TODO: COLOCAR POR AQUI OS CUSTOS E TAXAS DO CARTÃO DE CRÉDITO -->
                <!-- Custos e taxas desta cobrança

                    Valor bruto da cobrança		          R$ 20,00
                    Custo da cobrança (R$ 0,69 + 3,99%)	  <fazer a conta aqui: -R$ X,XX>
                    Taxa da antecipação	0,07% ao dia	  <fazer a conta aqui: -R$ X,XX>

                    Valor líquido creditado na sua conta      <fazer a conta aqui: R$ X,XX> 
                -->
                <div *ngIf="form.get('formStepper2.metodoPagamento').value === 'cartaoCredito'">
                    <p>Custo da cobrança ({{ (precos.precoPagamentoCartao | currency: 'BRL': 'R$') }} +
                        {{ precos.porcentagemMesPagamentoCartao }}%): {{ getCustoCobrancaCartao() | currency: 'BRL':
                        'R$' }}</p>
                    <!-- <p>Taxa da antecipação 0,07% ao dia: {{ getTaxaAntecipacaoCartao() }}</p> -->
                </div>

                <p *ngIf="form.get('formStepper2').valid">
                    {{ 'Valor líquido creditado na sua conta: ' + getValorLiquido() }}</p>
                <p
                    *ngIf="(form.get('formStepper2.metodoPagamento').value === 'boleto' || form.get('formStepper2.metodoPagamento').value === 'cartaoCredito') || (form.get('formStepper2.metodoPagamento').value === 'boletoPix')">
                    {{ form.get('formStepper2.metodoPagamento').value === 'boleto' ? 'Boleto Bancário' :
                    form.get('formStepper2.metodoPagamento').value === 'cartaoCredito' ? 'Cartão de Crédito' :
                    form.get('formStepper2.metodoPagamento').value === 'boletoPix' ? 'Boleto Pix':'' }}
                </p>

                <p
                    *ngIf="(form.get('formStepper2.metodoPagamento').value === 'boleto' || form.get('formStepper2.metodoPagamento').value === 'boletoPix') && form.get('formStepper1.tipoCobranca').value != 2">
                    {{ (form.get('formStepper1.tipoCobranca').value == 1 ? 'Vencimento em ' : 'Vencimento da primeira
                    parcela: ') + (form.get('formStepper2.dataVencimento').value | date:'dd/MM/yyyy') }}
                </p>
                <p
                    *ngIf="form.get('formStepper2.metodoPagamento').value === 'boleto' && form.get('formStepper1.tipoCobranca').value == 2">
                    {{ 'Início da assinatura: ' + (form.get('formStepper2.dataVencimento').value | date:'dd/MM/yyyy') }}
                </p>
                <p *ngIf="form.get('formStepper1.tipoCobranca').value == 2 && form.get('formStepper2.fimAssinatura')">
                    {{ 'Fim da assinatura: ' + (this.form.get('formStepper2.fimAssinatura').value | date:'dd/MM/yyyy')
                    }}
                </p>
            </div>
            <p class="menor-title" *ngIf="form.get('formStepper2.aplicarMulta')?.value">Opções de vencimento</p>
            <p *ngIf="form.get('formStepper2.quantidadeDiasBaixa').value > 0">
                A cobrança será cancelada <b>automaticamente</b> em {{ ' ' +
                form.get('formStepper2.quantidadeDiasBaixa').value }} dia(s)
                <b>{{form.get('formStepper2.quantidadeDiasBaixa').value > 1 ? 'úteis':'útil'}}</b> após a data de
                vencimento.
                <br>
                {{ 'Data do cancelamento: ' + (dataBaixa | date:'dd/MM/yyyy')}}
            </p>
            <p *ngIf="form?.get('formStepper2.aplicarMulta')?.value">
                {{ 'Com multa de ' + form?.get('formStepper2.multa')?.value?.toString()?.replace('%','')+ '%' +
                ' do valor e juros de ' + form?.get('formStepper2.juros')?.value?.toString()?.replace('%','') + '% ao
                mês.'}}
            </p>
            <div *ngIf="form.get('formStepper2.aplicarDesconto').value">
                <p class="menor-title">Opções de desconto</p>
                <div *ngFor="let desconto of form.get('formStepper2.descontos').controls">
                    <p *ngIf="desconto.get('dataLimiteDesconto').value && desconto.get('valorDesconto').value">
                        {{ 'Desconto de ' + (desconto.get('valorDesconto').value | currency: 'BRL': 'R$') + ' até o dia'
                        + (desconto.get('dataLimiteDesconto').value | date:'dd/MM/yyyy') + ' (' +
                        diasDescontoAntesDoVencimento(desconto.get('dataLimiteDesconto').value) + ' antes do
                        vencimento).'}}
                    </p>
                </div>
            </div>
            <p class="menor-title" *ngIf="form.get('formStepper2.incluirNumeroDocumento')?.value">Número do documento
            </p>
            <p *ngIf="form?.get('formStepper2.numeroDocumento')?.value">
                {{form?.get('formStepper2.numeroDocumento')?.value?.toString()}}
            </p>
            <p *ngIf="configuracoesSistema?.cobrancaAtivada === false" style="margin-top: 20px; color: red;">Emissão de
                cobranças indisponível temporariamente. Tente novamente mais tarde.</p>
            <br>
            <div *ngIf="urlAjuda">
                <p style="font-size: 10px;">Dúvidas?<a href={{urlAjuda}} target="_blank">Visite nosso
                        FAQ</a></p>
            </div>
        </div>
    </div>
</div> <!-- fim_dialog-content -->
<div mat-dialog-actions class="footer-modal">
    <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div class="row espacamento">
        <div class="col-12 col-md-3">
            <button *ngIf="elementstepper1.selectedIndex != 0" type="button" (click)="elementstepper1.previous()"
                class="btn btn-block btn-secundario corPrimaria bordaCorPrimaria" style="background-color: white;"
                mat-raised-button>
                Passo Anterior
            </button>
        </div>

        <div class="col-12 col-md-5">
            <mat-horizontal-stepper class="bottom-stp" labelPosition="bottom" [linear]=true #elementstepper2>
                <mat-step [editable]="true" state="done">
                    <ng-template matStepLabel>Quem cobrar</ng-template>
                </mat-step>
                <mat-step [editable]="true" state="done">
                    <ng-template matStepLabel>Informações da cobrança</ng-template>
                </mat-step>

                <ng-template matStepperIcon="edit">
                    <i class="fa fa-check"></i>
                </ng-template>
                <ng-template matStepperIcon="number">
                    <i class="fa fa-circle"></i>
                </ng-template>
                <ng-template matStepperIcon="error">
                    <i class="fa fa-times-circle"></i>
                </ng-template>
            </mat-horizontal-stepper>
        </div>

        <div class="col-12 col-md-3">
            <button *ngIf="elementstepper1.selectedIndex != 1 && form.get('formStepper1.contatoOuPix').value == 1"
                type="button" (click)="next()" class="btn btn-block btn-primario fundoCorPrimaria"
                style=" border-color: #ee9900;"
                [disabled]="!form.get('formStepper1').valid && form.get('formStepper1.tipoCobranca').enabled"
                mat-raised-button>
                Próximo Passo
            </button>
            <button *ngIf="elementstepper1.selectedIndex != 1 && form.get('formStepper1.contatoOuPix').value == 2"
                type="button" (click)="next()" class="btn btn-block btn-primario fundoCorPrimaria"
                [disabled]="form.get('formStepper1.temChavePix').value == false" style=" border-color: #ee9900;"
                mat-raised-button>
                Próximo Passo
            </button>
            <button *ngIf="elementstepper1.selectedIndex == 1 && form.get('formStepper1.contatoOuPix').value == 2"
                [disabled]="processando || form.get('formStepper2.chavePixCobrador').value == '' || form.get('formStepper2.metodoPagamento').value === 'boleto' || form.get('formStepper2.metodoPagamento').value === 'pixDinamico' && form.get('formStepper2.valorPix').value == 0"
                (click)="estaticoOuDinamico()" class="btn btn-block btn-primario fundoCorPrimaria"
                style=" border-color: #ee9900;" mat-raised-button>
                Concluir
            </button>
            <button *ngIf="elementstepper1.selectedIndex == 1 && form.get('formStepper1.contatoOuPix').value == 1"
                [disabled]="processando" (click)="verificaPraFinalizar()"
                class="btn btn-block btn-primario fundoCorPrimaria" style=" border-color: #ee9900;" mat-raised-button>
                {{ this.dataDialog.idCobranca ? 'Atualizar' : 'Concluir' }}
            </button>
        </div>
    </div>
</div> <!-- fim_dialog-actions -->