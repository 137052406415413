<div>
    <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
        PERSONALIZAÇÃO DO SISTEMA <i (click)="fecha()" class="material-icons btn-fechar">close</i>
        <span id="fechar" (click)="fecha()">FECHAR</span>
    </h2>

    <div mat-dialog-content class="mat-dialog-content-responsive">
        <div class=" mudaCor input-size row" style="background-color: white; margin: 0px 10px; border-radius: 5px;padding: 25px 0px">
           <div class="cores" style="display: flex;padding: 25px 0px;">
                <div class="primaria">
                    <p>Cor Primária: </p>
                    <span class="corPrimaria" id="corPrimaria">  </span>
                    <p style="align-self: center;">{{codigoPrimaria}}</p>
                </div>
                <div class="secundaria">
                    <p >Cor Secundária: </p>
                    <span style="align-self: center;" class="corSecundaria" id="corSecundaria">  </span>
                    <p style="align-self: center;">{{codigoSecundaria}}</p>
                </div>
           </div>
            <div class="logo">
                <p style="align-self: center;">Logo Utilizado: </p>
                <img src="assets/img/icons/pluga-bank/logos/logo-colorido.png" alt="" style="width: 50%;">
            </div>

        </div>
    </div>

    <div mat-dialog-actions style="display: block;overflow: initial; margin: 0; padding: 8px 0 0;border-radius: 4px;">
        <span class="div-interna flex-end" style=" align-items: center; padding: 0px 20px;border-radius: 4px;
        margin: 0;">
            <button (click)="fecha()" class="btn btn-default next" >Voltar</button>
        </span>
    </div>
</div>

