import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import {
  AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { EmailDTO } from "@model/pessoa/email-dto.model";
import { EnderecoDTO } from "@model/pessoa/endereco-dto.model";
import { PessoaDTO } from "@model/pessoa/pessoa-dto.model";
import { TelefoneDTO } from "@model/pessoa/telefone-dto.model";
import { UsuarioDTO } from "@model/usuario/usuario-dto.model";
import { Environment } from "@project/src/environments/environment.default";
import { untilDestroyed } from "ngx-take-until-destroy";
import { AutenticacaoEndpointService } from "src/app/service/autenticacao-endpoint.service";
import { DadosEndpointService } from "src/app/service/dados-endpoint.service";
import { HomeEndpointService } from "src/app/service/home-endpoint.service";
import { IPessoaService, PessoaServiceBuilder } from "src/app/service/pessoa-endpoint.service";
import { UsuarioEndpointService } from "src/app/service/usuario-endpoint.service";
import { StringFormatService } from "src/app/service/util/stringFormater.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { CadastroPjDTO, CadastroUsuarioDTO } from "../../model/cadastro-dto.model";
import { EmpresaDTO } from "../../model/pessoa/empresa-dto.model";
import { RepresentanteEmpresaDTO } from "../../model/pessoa/representante-empresa-dto.model";
import { FalarComGerenteComponent } from "../falar-com-gerente/falar-com-gerente.component";
import { ValidacoesService } from "./../../service/validacoes.service";
import { Stepper2Component } from "./stepper2/stepper2.component";
import { CheckAuthoritiesService } from "../../service/util/checkAuthorities.service";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { InformacoesFinanceirasDTO } from "../../model/informacoes-financeiras-dto.model";
import { ConfiguracaoDTO } from "../../model/pessoa/configuracao-dto.model";

@Component({
  selector: "app-primeiros-passos",
  templateUrl: "./primeiros-passos.component.html",
  styleUrls: ["./primeiros-passos.component.less"],
})
export class PrimeirosPassosComponent implements OnInit, OnDestroy, AfterViewInit {
  public usuarioLogado: UsuarioDTO = {};
  public env: Environment = environment;
  public processando: boolean = true;
  public empresaLogada: EmpresaDTO;
  public dadosRecuperados: any;
  public form: FormGroup;

  private isDadoRecuperado: boolean = false;
  private service: IPessoaService;

  private permissaoCadastro: {
    permitirCadastroPessoaFisica: true,
    permitirCadastroPessoaJuridica: true
  };

  ehColaborador: boolean = false;

  @ViewChild("elementstepper1", { static: false }) elementstepper1;
  @ViewChild("elementstepper2", { static: false }) elementstepper2;
  @ViewChild(Stepper2Component, { static: false }) private stepper2: Stepper2Component;

  constructor(
    public dialogRef: MatDialogRef<PrimeirosPassosComponent>,
    public stringFormat: StringFormatService,
    private autenticacao: AutenticacaoEndpointService,
    private usuario: UsuarioEndpointService,
    private homeService: HomeEndpointService,
    private formBuilder: FormBuilder,
    private router: Router,
    private injector: Injector,
    private changeDetectorRef: ChangeDetectorRef,
    private dadosService: DadosEndpointService,
    private dialog: MatDialog,
    private validacoesService: ValidacoesService,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
  ) {
    dialogRef.disableClose = true;
    this.empresaLogada = this.autenticacao.regraEmpresa
      ? this.autenticacao.regraEmpresa.empresa
      : null;
    this.usuarioLogado = this.autenticacao.sessao
      ? this.autenticacao.sessao.principal.usuario
      : null;
    this.usuarioLogado.nome = this.usuarioLogado
      ? this.usuarioLogado.nome + " " + this.usuarioLogado.sobrenome
      : null;

    const userData = this.autenticacao.sessao;
    const checkAuthorities = new CheckAuthoritiesService(userData);
    this.ehColaborador = checkAuthorities.isColaborador;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      formStepper1: this.formBuilder.group({
        codemail: new FormControl("", [
          Validators.required,
          Validators.maxLength(7),
          Validators.minLength(6),
        ]),
        codcelular: new FormControl("", [
          Validators.required,
          Validators.maxLength(6),
        ]),
        perguntasSeguranca: new FormArray([])
      }),
      formStepper2: this.formBuilder.group({
        moderacao: new FormControl("aguardandoModeracao"),
        cpf: new FormControl("", [Validators.required]),
        dataNascimento: new FormControl("", [Validators.required]),
        sexo: new FormControl("", [Validators.required]),
        rg: new FormControl("", [Validators.required]),
        orgaoEmissor: new FormControl("", [Validators.required]),
        estadoCivil: new FormControl("", [Validators.required]),
        nomeMae: new FormControl("", [Validators.required, Validators.pattern(/^[A-Za-z\u00C0-\u00FF]+(?:\s[A-Za-z\u00C0-\u00FF]+)+$/)]),
        nomePai: new FormControl("", [Validators.required, Validators.pattern(/^[A-Za-z\u00C0-\u00FF]+(?:\s[A-Za-z\u00C0-\u00FF]+)+$/)]),
        pessoaPoliticamenteExposta: new FormControl(false, [Validators.required]),
        endereco: new FormArray([]),
      }),
      formStepper3: this.formBuilder.group({
        tipoConta: ["pj", [Validators.required]],
      }),
      formStepperInformacoesFinanceiras: this.formBuilder.group({
        rendaMensal: new FormControl(),
        aplicacoesFinanceiras: new FormControl(),
        outrosRendimentos: new FormControl(),
        bensMoveis: new FormControl(),
        bensImoveis: new FormControl(),
        ocupacao: new FormControl(),
        codigoConvenio: new FormControl(),
      }),
      formStepper4: this.formBuilder.group({
        geral: new FormGroup({}),
        enderecos: new FormArray([]),
        telefones: new FormArray([]),
        emails: new FormArray([]),
        representantes: new FormArray([]),
        emailNotificacao: new FormArray([]),
      })
    });
    this.form
      .get("formStepper2")
      .get("cpf")
      .setValidators([this.verificaCPF(this.form)]);
    this.buscarDado();
    this.getPermissionsRegistration();

  }

  ngAfterViewInit() {
    if (this.dataDialog.apenasCadastroPessoa === true || this.usuarioLogado.cpf) {
      this.elementstepper1.next();
      this.elementstepper1.next();
      this.elementstepper1.next();
    } else if (this.usuarioLogado.emailConfirmado === true) {
      this.elementstepper1.next();
      this.processando = false;
    }

    this.changeDetectorRef.detectChanges();
  }

  private verificaCPF(form: FormGroup) {
    return (control: AbstractControl) => {
      const cpf = form.get("formStepper2").get("cpf").value;

      if (!this.validacoesService.validaCPF(cpf)) {
        return { cpfinvalido: true } as ValidationErrors;
      } else {
        return null as ValidationErrors;
      }
    };
  }

  public changeUserInfo(user: UsuarioDTO) {
    this.usuarioLogado = user;
  }

  private salvarDado() {
    let dado: string = JSON.stringify(this.form.value);
    this.dadosService
      .postDados(dado, "onboard")
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.isDadoRecuperado = true;
          // this.buscarDado();
        },
        (error) => {
          Swal.fire({
            title: "Erro ao salvar dados",
            text: error.error,
            icon: "error",
          })
          console.error(error);
        }
      );
  }

  private atualizarDado() {
    if (this.isDadoRecuperado) {
      let dado: string = JSON.stringify(this.form.value);
      this.dadosService.patchDados(dado, "onboard").pipe(untilDestroyed(this)).subscribe((response) => {
      }, error => {
        Swal.fire({
          title: "Erro ao atualizar dados",
          text: error.error,
          icon: "error",
        })
        console.error(error);
      });
    }
  }

  private buscarDado() {
    this.dadosService
      .getDados("onboard")
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.isDadoRecuperado = true;
          this.dadosRecuperados = JSON.parse(response.dado);
          this.form.patchValue(this.dadosRecuperados);
          if (
            this.dadosRecuperados.formStepper4.geral.documento != "" ||
            this.dadosRecuperados.formStepper4.geral.nome != ""
          ) {
            // this.next();
          }
        },
        (error) => {
          console.error(error);
          this.salvarDado();
        }
      );
  }

  public onStepChange(event: any): void {
    this.elementstepper2.selectedIndex = event.selectedIndex;
  }

  public back() {
    if (
      this.elementstepper1.selectedIndex === 4 &&
      !this.dataDialog.apenasCadastroPessoa &&
      this.form.get("formStepper3.tipoConta").value === "pf"
    ) {
      this.elementstepper1.selectedIndex = 2;
    } else if (
      this.elementstepper1.selectedIndex === 3 &&
      !this.dataDialog.apenasCadastroPessoa &&
      this.form.get("formStepper3.tipoConta").value === "pj"
    ) {
      const emails = this.form.get("formStepper4").get("emails") as FormArray;
      emails.removeAt(0);
      const enderecos = this.form
        .get("formStepper4")
        .get("enderecos") as FormArray;
      enderecos.removeAt(0);
      const telefones = this.form
        .get("formStepper4")
        .get("telefones") as FormArray;
      telefones.removeAt(0);
      this.elementstepper1.previous();
    } else {
      this.elementstepper1.previous();
    }
  }

  public async next() {
    const currentStepIndex = this.elementstepper1.selectedIndex;

    if (currentStepIndex === 0) {
      this.handleStep1();
    } else if (currentStepIndex === 1) {
      this.handleStep2();
    } else if (currentStepIndex === 2) {
      this.elementstepper1.next();
    } else if (currentStepIndex === 3) {
      this.handleStep3();
    } else if (currentStepIndex === 4) {
      this.handleStep4();
    }
  }

  private async handleStep1() {
    if (this.form.get('formStepper1').valid) {
      let perguntas = [], respostas = []
      const formPerguntas = this.form.get('formStepper1.perguntasSeguranca') as FormArray
      formPerguntas.controls.forEach((pergunta: FormGroup) => {
        perguntas.push(pergunta.get('pergunta').value)
        respostas.push(pergunta.get('resposta').value)
      })
      const data = {
        perguntas, respostas, usuarioId: this.usuarioLogado.id
      }

      this.usuario
        .confirmaEmailCelular(
          this.form.get('formStepper1.codemail').value,
          this.form.get('formStepper1.codcelular').value
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          (response) => {
            this.usuario.cadastrarPerguntasSeguranca(data)
              .pipe(untilDestroyed(this))
              .subscribe(
                res => {
                  this.elementstepper1.next()
                },
                error => {
                  console.error(error);
                  Swal.fire('Erro', 'Erro ao cadastrar perguntas de segurança', 'error');
                }
              )
          },
          (error) => {
            console.error(error);
            Swal.fire('Atenção', 'Código de email e/ou celular inválido', 'warning');
          }
        );
    } else {
      this.form.get('formStepper1').markAllAsTouched();
      Swal.fire('Atenção', 'Preencha todos os campos obrigatórios para prosseguir', 'warning');
    }
  }

  private handleStep2() {
    if (this.form.get('formStepper2').valid) {
      const updateUserData = this.stepper2.build();
      if (!this.permissaoCadastro.permitirCadastroPessoaFisica) {
        this.elementstepper1.next();
        this.elementstepper1.next();
      } else if (!this.permissaoCadastro.permitirCadastroPessoaJuridica) {
        this.elementstepper1.next();
        this.elementstepper1.next();
        this.elementstepper1.next();
      } else if (this.permissaoCadastro.permitirCadastroPessoaJuridica && this.permissaoCadastro.permitirCadastroPessoaFisica) {
        this.elementstepper1.next();
      }
    } else {
      this.form.get('formStepper2').markAllAsTouched();
      Swal.fire('Atenção', 'Preencha todos os campos obrigatórios para prosseguir', 'warning');
    }
  }

  private handleStep3() {
    const tipoConta = this.form.get('formStepper3.tipoConta').value;
    const geral = this.form.get('formStepper4').get('geral') as FormGroup;

    if (tipoConta === 'pf') {
      if (geral.get('razaoSocial')) {
        geral.get('razaoSocial').clearValidators();
      }
    }

    if (tipoConta === 'pj') {
      this.elementstepper1.next();
    } else {
      this.onFinish();
    }
  }

  private handleStep4() {
    if (this.form.get('formStepper4').valid) {
      Swal.fire({
        html: `
        <style>
        .swal-cancel-button{
          background-color: white !important;
          color: #ff6400 !important;
          font-weight: bold !important;
          border: 1px solid #ff6400 !important;
        }
        .swal-button{
            background-color: #ff6400 !important;
        }
        </style>
        <p style="color: #ff6400; font-weigth: bold; font-size: 22px">Deseja mesmo concluir o cadastro?</p>
        <p style="font-size: 16px">Certifique-se de que todos os dados estão preenchidos corretamente antes de concluir seu cadastro.</p>
        `,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'CONFIRMAR',
        cancelButtonText: 'CANCELAR',
        reverseButtons: true,
        customClass: {
          cancelButton: 'swal-cancel-button',
          confirmButton: 'swal-button',
          icon: 'swal-icon',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.verificarRepresentantes();
          this.changeDetectorRef.detectChanges();
        }
      });
    } else {
      this.form.get('formStepper4').markAllAsTouched();
    }
  }

  onFinish() {
    this.processando = true;
    this.service = PessoaServiceBuilder.getInstance(this.injector, 'empresa');
    if (this.dataDialog.apenasCadastroPessoa === true) {
      if (this.form.get('formStepper3.tipoConta').value === 'pj') {
        return this.cadastrar(data => this.service.cadastrarPessoaJuridica(data).toPromise(), "/privado/empresa");
      } else {
        return this.cadastrar(data => this.service.cadastrarPessoaFisica(data).toPromise(), "/privado/empresa");
      }
    } else {
      return this.cadastrar(data => this.service.cadPrimeirosPassos(data).toPromise(), "/privado/principal");
    }
  }

  public async fecha(): Promise<void> {
    if (this.ehColaborador) {
      return this.dialogRef.close();
    }
    if (this.dataDialog.apenasCadastroPessoa) {
      if (this.dadosService && this.dadosService.data && this.dadosService.data.id) {
        if (this.dadosService.data.id != undefined) {
          const deleteDados = await this.dadosService.deleteDados(this.dadosService.data.id).toPromise();
        }
      }
      this.dialogRef.close();
    } else {
      this.autenticacao
        .sair()
        .pipe(untilDestroyed(this))
        .subscribe(
          (response) => {
            this.dialogRef.close();
            this.router.navigateByUrl("/acesso");
          },
          (error) => {
            console.error(error);
          }
        );
    }
  }

  public gerenteModal() {
    this.dialog.open(FalarComGerenteComponent, {
      width: "800px",
      panelClass: "fundo-empresa",
    });
  }

  private async verificarRepresentantes() {
    let check = false;
    for await (let representante of this.form.get("formStepper4.representantes").value) {
      if (representante.documento == this.form.get('formStepper2.cpf').value || representante.documento == this.usuarioLogado.cpf)
        check = true;
    }
    if (!check)
      Swal.fire("Alerta", 'Como o usuário não é representante legal, novos documentos serão requeridos no processo de documentação', 'info').then(() => this.onFinish());
    else
      this.onFinish();

  }

  public atualizar(element) {
    if (element.atualizar) {
      // this.next();
    }

    this.changeDetectorRef.detectChanges();
  }

  public atualizarForm(element) {
    if (element.atualizar) {
      this.atualizarDado();
    }
    if (this.elementstepper1.selectedIndex == 2) {
      if (element.tipoCadastro) {
        setTimeout(() => {
          this.next();
          this.processando = false;
        }, 500);
      } else {
        this.processando = false;
      }
    } else {
      this.processando = false;
    }
  }

  private async build() {
    let pessoa = {} as PessoaDTO;
    let usuario = {} as UsuarioDTO;
    let representante = [] as RepresentanteEmpresaDTO[];

    if (this.form.get("formStepper3.tipoConta").value === "pj") {
      let empresa = {} as CadastroPjDTO;
      empresa.configuracao = empresa.configuracao || {} as ConfiguracaoDTO;
      pessoa = this.buildPessoa();
      usuario = this.buildUsuario();
      representante = this.form.get('formStepper4.representantes').value as RepresentanteEmpresaDTO[];
      usuario.informacoesFinanceiras = this.buildInformacoesFinanceiras();
      empresa.codigoConvenio = this.buildCodigoConvenio();
      empresa.pessoa = pessoa;
      empresa.usuario = usuario;
      empresa.representantes = representante;
      empresa.tipoEmpresa = this.form.get("formStepper4.geral.tipoEmpresa").value;
      empresa.faturamento = this.form.get("formStepper4.geral.faturamento").value;
      empresa.configuracao.emailNotificacao = this.form.get("formStepper4.emailNotificacao").value.map(v => ({ email: v.email })); return empresa;
    } else {
      let empresa = {} as CadastroUsuarioDTO;
      empresa.configuracao = empresa.configuracao || {} as ConfiguracaoDTO;
      pessoa = this.buildPessoaPF();
      usuario = this.buildUsuario();
      usuario.informacoesFinanceiras = this.buildInformacoesFinanceiras();
      empresa.codigoConvenio = this.buildCodigoConvenio();
      empresa.pessoa = pessoa;
      empresa.usuario = usuario;
      empresa.configuracao.emailNotificacao = [{ email: this.usuarioLogado.email }];
      return empresa;
    }
  }

  private buildPessoa(): PessoaDTO {
    let form = this.form.get("formStepper4") as FormGroup;
    const pessoa = {} as PessoaDTO;
    pessoa.documento = form.get('geral.documento').value;
    pessoa.nome = form.get('geral.nome').value;
    pessoa.razaoSocial = form.get('geral.razaoSocial').value;
    const enderecos = pessoa.enderecos = [] as EnderecoDTO[];
    for (const item of (form.get('enderecos').value || [])) {
      enderecos.push(item);
    }
    const emails = pessoa.emails = [] as EmailDTO[];
    for (const item of (form.get('emails').value || [])) {
      emails.push(item);
    }
    const telefones = pessoa.telefones = [] as TelefoneDTO[];
    for (const item of (form.get('telefones').value || [])) {
      telefones.push(item);
    }
    telefones.forEach(element => {
      if (!element.ddd) {
        let numero = <string><unknown>element.numero;
        element.ddd = <number><unknown>numero.substring(0, 2);
        element.numero = <number><unknown>numero.substring(2, numero.length);
      } else if (element.numero && element.numero.toString().length > 10) {
        element.numero = Number(`${(element.numero || '   ')}`.substring(2));
      }
    });
    return pessoa;
  }

  private buildPessoaPF(): PessoaDTO {
    let form = this.form.get("formStepper2") as FormGroup;
    const pessoa = {} as PessoaDTO;
    pessoa.documento = this.usuarioLogado.cpf ? this.usuarioLogado.cpf : form.get('cpf').value;
    pessoa.nome = `${this.usuarioLogado.nome}`;
    pessoa.razaoSocial = null;
    const enderecos = pessoa.enderecos = [] as EnderecoDTO[];

    if (this.usuarioLogado.cpf) {
      enderecos.push(this.usuarioLogado.endereco)
    } else {
      for (const item of (form.get('endereco').value || [])) {
        enderecos.push(item);
      }
    }
    pessoa.emails = [] as EmailDTO[];
    pessoa.emails = [{ "principal": true, "email": this.usuarioLogado.email }];
    pessoa.telefones = [
      {
        "principal": true,
        "tipo": "celular",
        "ddd": this.usuarioLogado.celular.ddd,
        "numero": this.usuarioLogado.celular.numero,
        "ramal": 1,
        "pais": "+55"
      }
    ] as TelefoneDTO[];


    return pessoa;
  }

  public buildUsuario(): UsuarioDTO {
    let form = this.form.get("formStepper2") as FormGroup;
    let usuario = {} as UsuarioDTO;
    if (this.usuarioLogado.cpf) {
      usuario = this.usuarioLogado;
    } else {
      usuario = this.form.get("formStepper2").value;
      usuario.email = this.usuarioLogado.email;
      usuario.celular = this.usuarioLogado.celular;
    }
    if (usuario.endereco[0]) {
      usuario.endereco = usuario.endereco[0];
    }
    return usuario;
  }

  buildInformacoesFinanceiras() {
    let form = this.form.get("formStepperInformacoesFinanceiras") as FormGroup;
    let informacoesFinanceiras = {} as InformacoesFinanceirasDTO;
    informacoesFinanceiras.rendaMensal = form.get('rendaMensal').value;
    informacoesFinanceiras.aplicacoesFinanceiras = form.get('aplicacoesFinanceiras').value;
    informacoesFinanceiras.outrosRendimentos = form.get('outrosRendimentos').value;
    informacoesFinanceiras.bensMoveis = form.get('bensMoveis').value;
    informacoesFinanceiras.bensImoveis = form.get('bensImoveis').value;
    informacoesFinanceiras.ocupacao = form.get('ocupacao').value;
    return informacoesFinanceiras;
  }

  buildCodigoConvenio() {
    let form = this.form.get("formStepperInformacoesFinanceiras") as FormGroup;
    let codigoConvenio;
    codigoConvenio = form.get('codigoConvenio').value;

    return codigoConvenio;
  }

  private cadastrar(cb: (pessoaData: any) => Promise<any>, goTo: string) {
    this.processando = true;
    this.changeDetectorRef.detectChanges();
    return this.build().then(data => cb(data))
      .then(response => {
        if (this.dadosService && this.dadosService.data && this.dadosService.data.id != undefined) {
          return this.dadosService.deleteDados(this.dadosService.data.id).toPromise()
            .then(deleteDados => {
              return response;
            });
        }
        return response;
      })
      .then(response => {
        Swal.fire({
          title: "Processando...",
          allowOutsideClick: false,
          onBeforeOpen: () => Swal.showLoading(),
        });
        this.waitSaving(goTo, 0);
      })
      .catch(error => {
        Swal.fire('Erro', error.error, 'error')
        console.error(error);
        this.processando = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  private waitSaving(goTo, max) {
    this.usuario.getRegraCorporacaoLogada()
      .pipe(untilDestroyed(this))
      .toPromise()
      .then(
        (response) => {
          if (response) {
            this.loadInitialPage(goTo)
          }
        },
        (err) => {
          if (max == 10) {
            return Swal.fire("Ops!", err.error, "error")
          } else {
            this.waitSaving(goTo, max++)
          }
        });
    return;
  }

  private loadInitialPage(goTo) {
    Swal.fire("Sucesso", "Cadastro concluído com sucesso! Você será redirecionado para tela de login.", "success").then(() => {
      this.autenticacao
        .sair()
        .pipe(untilDestroyed(this))
        .subscribe(
          (response) => {
            this.dialogRef.close();
            this.router.navigateByUrl("/acesso");
          },
          (error) => {
            console.error(error);
          }
        );
    });
  }

  private getPermissionsRegistration(): void {
    this.homeService.getConfiguracoesSistema().pipe(untilDestroyed(this)).subscribe(
      (response) => {
        this.permissaoCadastro = response;
      }, error => {
        Swal.fire({
          title: "Erro ao buscar dados",
          text: error.error,
          icon: "error",
        })
        console.error(error);
      }
    );
  }

  ngOnDestroy(): void { }
}
